// src/theme.ts
export const theme = {
  colors: {
    textColor: '#262626 ',
    mainColor: '#14213D',
    hoverColor: '#2D4985',
    activeColor: '#FCA311',
    whiteColor: '#fff',
    blackColor: '#000',
    redColor: '#FF0000',
    grayColor: '#DEDEDE',
    grayHoverColor: '#A6A6A6',
    inputBG: '#fff',
    inputLine: '#DEDEDE',
  },
};
