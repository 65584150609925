import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 0px;
  gap: 10px;
  background-color: #fff;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px;
  }
`;

const CardContainer = styled.div`
  flex: 1;
  max-width: 100%;
  background-color: #aa9b8b;
  color: #ffffff;
  border-radius: 30px;
  padding: 5px;

  @media (max-width: 1024px) {
    max-width: 600px;
    width: 100%;
    margin: 0;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  border-radius: 28px;
  padding: 40px 20px;
  text-align: center;
  border: 1px solid #fff;

  @media (max-width: 1024px) {
    max-width: unset;
    padding: 30px 20px;
    width: 100%;
    margin: 0;
  }
`;

const CardTitle = styled.h3`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (max-width: 1024px) {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }
`;

const CardDescription = styled.p`
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-line; /* 줄바꿈 반영 */
`;

function ArtfleetFeaturesSection() {
  const features = [
    {
      title: '맞춤별 강의 추천',
      description:
        '원하는 주제나 관심사를 기반으로\n 강의를 선택할 수 있습니다.',
    },
    {
      title: '어디서든 학습 가능',
      description:
        '강의는 PC 및 모바일을 통해 언제 어디서나\n 학습할 수 있도록 제공됩니다.',
    },
    {
      title: '강사와 소통',
      description: `강의 수강 후 강사와 질문 및 피드백을\n 주고받을 수 있는 시스템이 마련되어 있습니다.`,
    },
  ];

  return (
    <Container>
      {features.map((feature, index) => (
        <CardContainer>
          <Card key={index}>
            <CardTitle>{feature.title}</CardTitle>
            <CardDescription>{feature.description}</CardDescription>
          </Card>
        </CardContainer>
      ))}
    </Container>
  );
}

export default ArtfleetFeaturesSection;
