/**
 * 전화번호를 3-4-4 형식으로 자동 포맷팅
 * @param value 입력된 전화번호 문자열
 * @returns 포맷팅된 전화번호 문자열
 */
export const formatPhoneNumber = (value: string): string => {
  // 숫자만 추출
  const numbers = value.replace(/\D/g, '');

  // 3-4-4 형식으로 변환
  if (numbers.length <= 3) {
    return numbers;
  } else if (numbers.length <= 7) {
    return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
  } else {
    return `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}-${numbers.slice(7, 11)}`;
  }
};
