import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../../components/admin/navbar/Layout';
import PortfolioFormField from '../../components/admin/portfolio/PortfolioFormField';
import SelectBox from '../../components/SelectBox';
import CategoryService, {
  CategoryData,
} from '../../api/service/user/CategoryService';
import DatePicker from '../../components/DatePicker';
import AdminPortfolioService from '../../api/service/admin/AdminPortfolioService';
import { useNavigate } from 'react-router-dom';
import { AdminPortfolioPageUrl } from '../../url/lodongUrl';

const Input = styled.input`
  padding: 5px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
`;
const Textarea = styled.textarea`
  padding: 5px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  height: 70px;
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ThumbnailPreview = styled.div`
  width: 200px;
  height: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
  }
`;

const ThumbnailWrapper2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const ThumbnailPreview2 = styled.div`
  width: 80%;
  min-height: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
  }
`;

const UploadButton = styled.label`
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  color: #000;
  background-color: #e1e1e1;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #444;
    color: #fff;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const SupportedFormats = styled.p`
  margin-top: 8px;
  font-size: 12px;
  color: #888;
`;

const SupportedFormats2 = styled.p`
  font-size: 12px;
  color: #888;
`;
const ButtonContainer = styled.div`
  padding: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const Button = styled.div`
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  &:hover {
    background-color: #444;
    color: #fff;
  }
`;

interface Category {
  label: string;
  value: string;
}

function AdminPortfolioAddPage() {
  const navigate = useNavigate();
  const [topLevelCategories, setTopLevelCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [formData, setFormData] = useState({
    topCategoryId: '', // 대분류 ID
    subCategoryId: '', // 중분류 ID
    companyName: '', // 회사 이름
    siteName: '', // 사이트 이름
    siteUrl: '', // 사이트 URL
    simpleDescription: '', // 간단한 설명
    tags: [] as string[], // 태그 (문자열 배열)
    portfolioImgUuid: '', // 콘텐츠
    openDate: '', // 오픈 날짜
    thumbnailUuid: '', // 썸네일 UUID
    companyId: '', // 회사 ID
  });

  const [selectedDate, setSelectedDate] = useState<string>(''); // 오픈 날짜 상태
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [detailPreviewUrl, setDetailPreviewUrl] = useState<string | null>(null);
  const [detailSelectedFile, setDetailSelectedFile] = useState<File | null>(
    null
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const img = new Image();
      const fileUrl = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width !== img.height) {
          alert('이미지는 1:1 비율이어야 합니다.');
          return;
        }

        // 비율이 1:1일 경우 미리보기 업데이트
        setPreviewUrl(fileUrl);
        setSelectedFile(file); // 선택된 파일을 상태로 저장
      };

      img.src = fileUrl;
    }
  };

  const handleDetailImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setDetailPreviewUrl(fileUrl); // 미리보기 업데이트
      setDetailSelectedFile(file); // 선택된 파일 상태 업데이트
    }
  };

  // 날짜 변경 핸들러
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    setSelectedDate(date); // 로컬 상태 업데이트
    setFormData((prev) => ({ ...prev, openDate: date })); // formData 업데이트
  };

  // 대분류 카테고리 가져오기
  const fetchTopLevelCategories = async () => {
    try {
      const categories: CategoryData[] = (
        await CategoryService.getRootCategories()
      ).data;

      const formattedCategories = categories.map((category) => ({
        label: category.mainCategory,
        value: category.id.toString(),
      }));

      setTopLevelCategories([
        { label: '대분류 선택', value: '' },
        ...formattedCategories,
      ]);
    } catch (error) {
      console.error('대분류 카테고리 가져오기 실패:', error);
    }
  };

  // 하위 카테고리 가져오기
  const fetchSubCategories = async (parentId: number) => {
    try {
      const categories: CategoryData[] = (
        await CategoryService.getSubCategories(parentId)
      ).data;

      const formattedCategories = categories.map((category) => ({
        label: category.subCategory || category.mainCategory,
        value: category.id.toString(),
      }));

      setSubCategories([
        { label: '중분류 선택', value: '' },
        ...formattedCategories,
      ]);
    } catch (error) {
      console.error('중분류 카테고리 가져오기 실패:', error);
    }
  };

  // 대분류 선택 변경 핸들러
  const handleTopCategoryChange = (selectedValue: string) => {
    setFormData((prev) => ({
      ...prev,
      topCategoryId: selectedValue,
      subCategoryId: '', // 대분류 변경 시 중분류 초기화
    }));

    // 선택된 대분류에 따라 하위 카테고리를 가져옵니다.
    if (selectedValue) {
      fetchSubCategories(parseInt(selectedValue, 10));
    } else {
      setSubCategories([]); // 대분류 선택 해제 시 하위 카테고리 초기화
    }
  };

  // 중분류 선택 변경 핸들러
  const handleSubCategoryChange = (selectedValue: string) => {
    setFormData((prev) => ({
      ...prev,
      subCategoryId: selectedValue, // 중분류 ID만 업데이트
    }));
  };

  useEffect(() => {
    // 컴포넌트가 마운트될 때 대분류 카테고리 가져오기
    fetchTopLevelCategories();
  }, []);

  const handleFormSubmit = async () => {
    // 유효성 검사: 필수 값 체크
    if (!formData.topCategoryId) {
      alert('대분류를 선택하세요.');
      return;
    }
    if (!formData.subCategoryId) {
      alert('중분류를 선택하세요.');
      return;
    }
    if (!formData.companyName.trim()) {
      alert('업체명을 입력하세요.');
      return;
    }
    if (!formData.siteName.trim()) {
      alert('사이트 이름을 입력하세요.');
      return;
    }
    if (!formData.simpleDescription.trim()) {
      alert('간단한 설명을 입력하세요.');
      return;
    }
    if (!formData.tags.length) {
      alert('태그를 입력하세요.');
      return;
    }
    if (!formData.openDate.trim()) {
      alert('오픈 날짜를 선택하세요.');
      return;
    }
    if (!selectedFile) {
      alert('썸네일 파일을 선택하세요.');
      return;
    }
    if (!detailSelectedFile) {
      alert('상세 이미지를 업로드하세요.');
      return;
    }

    try {
      // 썸네일 업로드
      const thumbnailResponse =
        await AdminPortfolioService.uploadThumbnail(selectedFile);

      const detailImageResponse =
        await AdminPortfolioService.uploadThumbnail(detailSelectedFile);
      console.log(thumbnailResponse);
      console.log(detailImageResponse);

      // formData에 thumbnailUuid 추가
      const finalData = {
        companyId: 'efba40b0-d213-0000-9766-000000000000',
        companyName: formData.companyName,
        siteName: formData.siteName,
        siteUrl: formData.siteUrl,
        openDate: formData.openDate,
        simpleDescription: formData.simpleDescription,
        thumbnailUuid: thumbnailResponse.uuidBaseImage,
        tags: formData.tags,
        portfolioImgUuid: detailImageResponse.uuidBaseImage,
        categoryId: formData.subCategoryId
          ? parseInt(formData.subCategoryId, 10) // subCategoryId가 존재하면 변환하여 사용
          : parseInt(formData.topCategoryId, 10), // 없으면 topCategoryId를 변환하여 사용
      };

      console.log('Final Data:', finalData);

      // 포트폴리오 등록
      await AdminPortfolioService.createPortfolio(finalData);
      alert('포트폴리오가 성공적으로 등록되었습니다.');
      navigate(AdminPortfolioPageUrl);
    } catch (error) {
      console.error('포트폴리오 등록 실패:', error);
      alert('포트폴리오 등록 중 오류가 발생했습니다.');
    }
  };

  useEffect(() => {
    fetchTopLevelCategories();
  }, []);

  return (
    <Layout>
      <PortfolioFormField label="업체명">
        <Input
          placeholder="업체명을 입력하세요"
          value={formData.companyName}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, companyName: e.target.value }))
          }
        />
      </PortfolioFormField>

      <PortfolioFormField label="카테고리">
        <SelectBox
          options={topLevelCategories}
          value={formData.topCategoryId}
          onChange={(value) => handleTopCategoryChange(value)} // e.target 제거
        />

        {formData.topCategoryId && subCategories.length > 1 && (
          <SelectBox
            options={subCategories}
            value={formData.subCategoryId}
            onChange={(value) => handleSubCategoryChange(value)} // e.target 제거
          />
        )}
      </PortfolioFormField>

      <PortfolioFormField label="사이트 이름">
        <Input
          placeholder="사이트 이름을 입력하세요"
          value={formData.siteName}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, siteName: e.target.value }))
          }
        />
      </PortfolioFormField>
      <PortfolioFormField label="사이트URL">
        <Input
          placeholder="사이트URL을 입력하세요"
          value={formData.siteUrl}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, siteUrl: e.target.value }))
          }
        />
      </PortfolioFormField>
      <PortfolioFormField label="태그">
        <Input
          placeholder="태그를 입력하세요 (#태그 형식)"
          value={formData.tags.join(' ')} // 태그 배열을 문자열로 표시
          onChange={(e) => {
            const value = e.target.value;
            const tags = value
              .split(' ') // 공백 기준으로 태그를 나눕니다.
              .map((tag) => (tag.startsWith('#') ? tag : `#${tag}`)) // `#` 없으면 자동 추가
              .filter((tag) => tag.trim() !== ''); // 빈 값 제거
            setFormData((prev) => ({ ...prev, tags }));
          }}
        />
      </PortfolioFormField>
      <PortfolioFormField label="간단 설명">
        <Textarea
          placeholder="간단 설명을 입력하세요"
          value={formData.simpleDescription}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              simpleDescription: e.target.value,
            }))
          }
        />
      </PortfolioFormField>
      <PortfolioFormField label="오픈일">
        <DatePicker
          value={selectedDate} // 선택된 날짜
          onChange={handleDateChange} // 날짜 변경 핸들러
          disableFuture={true} // 미래 날짜 비활성화
          required={true} // 필수 입력
        />
      </PortfolioFormField>
      <PortfolioFormField label="썸네일">
        <ThumbnailWrapper>
          <ThumbnailPreview>
            {previewUrl ? (
              <img src={previewUrl} alt="썸네일 미리보기" />
            ) : (
              <span style={{ fontSize: 14 }}>썸네일을 업로드 해주세요</span>
            )}
          </ThumbnailPreview>
          <div>
            <UploadButton htmlFor="thumbnailUpload">파일 선택</UploadButton>
            <HiddenInput
              id="thumbnailUpload"
              type="file"
              accept=".jpg, .jpeg, .png, .gif"
              onChange={handleFileChange}
            />
            <SupportedFormats>
              1:1 비율 JPG, PNG, GIF 파일을 업로드 할 수 있습니다.
            </SupportedFormats>
          </div>
        </ThumbnailWrapper>
      </PortfolioFormField>
      <PortfolioFormField label="상세이미지">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <UploadButton htmlFor="detailImageUpload">파일 선택</UploadButton>
          <HiddenInput
            id="detailImageUpload"
            type="file"
            accept=".jpg, .jpeg, .png, .gif"
            onChange={handleDetailImageChange}
          />
          <SupportedFormats2>
            JPG, PNG, GIF 파일을 업로드 할 수 있습니다.
          </SupportedFormats2>
        </div>
        <ThumbnailWrapper2>
          <ThumbnailPreview2>
            {detailPreviewUrl ? (
              <img src={detailPreviewUrl} alt="썸네일 미리보기" />
            ) : (
              <span style={{ fontSize: 14 }}>
                상세이미지를 업로드 해주세요.
              </span>
            )}
          </ThumbnailPreview2>
        </ThumbnailWrapper2>
      </PortfolioFormField>

      <ButtonContainer>
        <Button onClick={handleFormSubmit}>등록</Button>
      </ButtonContainer>
    </Layout>
  );
}

export default AdminPortfolioAddPage;
