import React from 'react';
import styled from 'styled-components';
import Homepag_star from '../../../assets/images/homepageProductionPage/Homepag_star.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Container = styled.div`
  padding: 80px 15%;
  max-width: 1920px;
  margin: 0 auto;
  background-color: #121212;
  color: #fff;
  @media (max-width: 1440px) {
    padding: 80px 10%;
  }
  @media (max-width: 1024px) {
    padding: 20px 5% 50px;
    background-image: none;
    margin-bottom: 64px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

const MContainer = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;
const Flex = styled.div`
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-column: span 2; /* 2열을 차지하도록 설정 */
  gap: 30px;

  @media (max-width: 1024px) {
    padding: 20px 0px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.h1`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 66.667% */
  @media (max-width: 1024px) {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 90.909% */
  }
`;
const TitleImg = styled.img`
  height: 30px;
  @media (max-width: 1024px) {
    height: 20px;
  }
`;

const Description = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px; /* 170% */
  @media (max-width: 1024px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
  }
`;

const Card = styled.div`
  background-color: #1c1c1c;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 280px;
  gap: 16px;
  align-items: flex-start;
`;

const Index = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 125% */
  border-bottom: 1px solid #fff;
`;

const CardTitle = styled.h1`
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 100% */
`;
const CardDescription = styled.p`
  word-break: keep-all;
  margin-top: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 185.714% */
`;

const benefits = [
  {
    title: 'SEO 검색엔진 최적화',
    description:
      'SEO 검색엔진 최적화\n검색결과에 홈페이지 내 컨텐츠가 등록되도록 SEO의 기초적 최적화를 서비스해 드립니다.',
  },
  {
    title: '원본 소스 및 사용권 제공',
    description:
      '원본 소스 및 사용권 제공\n홈페이지 사후관리를 위해 제작한 원본 PSD 및 홈페이지 제작 소스를 무상으로 제공해 드립니다.',
  },
  {
    title: '기업메일 무료 지원',
    description:
      '기업메일 무료 지원\n다음에서 제공하는 스마트워크를 통해 도메인 메일 계정을 만들어 드립니다.\n(master@도메인.com)',
  },
  {
    title: '1년간 하자보수 무료 제공',
    description:
      '1년간 하자보수 무료 제공\n홈페이지 구축 시 미처 발견하지 못한 오류와 잦은 프로그램 수정에 대한 수정을 1년간 무상으로 서비스해 드립니다.',
  },
  {
    title: '도메인 1년 무상 제공',
    description:
      '도메인 1년 무상 제공\ncom, co.kr, net, kr 등 원하는 도메인으로 검색등록 및 1년간 무료 서비스를 드립니다.',
  },
  {
    title: '관리자 기본 프로그램 제공',
    description:
      '관리자 기본 프로그램 제공\n홈페이지 관리를 위해 기본적으로 필요한 접속통계, 문의 관리, SMS 문자발송 등 필수 프로그램을 무료로 제공해 드립니다.',
  },
  {
    title: '웹 표준 & 크로스 브라우징',
    description:
      '웹 표준 & 크로스 브라우징\n주요 웹 브라우저(익스플로러, 크롬 등)과의 접속 시 호환성을 고려하여 크로스 브라우징을 기본적으로 제공합니다.',
  },
  {
    title: '유료 이미지 제공',
    description:
      '유료 이미지 제공\n저작권에 문제가 없는 홈페이지 제작을 위해 필요한 이미지를 무상으로 제공해 드립니다.',
  },
  {
    title: '온라인 마케팅 무료 컨설팅',
    description:
      '온라인 마케팅 무료 컨설팅\n저작권에 문제가 없는 홈페이지 제작 시에 적합한 마케팅 환경을 제공합니다.',
  },
  {
    title: '컨테츠관리 무료 제공',
    description:
      '컨텐츠관리 무료 제공\nCMS를 통해 담당자가 직접 관리하거나 컨텐츠를 자유롭게 변환할 수 있는 환경을 제공합니다.',
  },
];

const HomePageFreeServicesComponent = () => {
  return (
    <Container>
      <Grid>
        <Header>
          <TitleContainer>
            <Title>무료 서비스</Title>
            <TitleImg src={Homepag_star} alt="Homepag_star" />
          </TitleContainer>
          <Description>
            홈페이지를 제작하시는 고객님들께 공동의 몇 가지
            <br /> 서비스를 무료로 제공해 드리고 있습니다.
          </Description>
        </Header>
        {benefits.map((benefit, index) => (
          <Card key={index}>
            <Index>혜택 {index < 9 ? `0${index + 1}` : index + 1}</Index>
            <CardTitle>{benefit.title}</CardTitle>
            <CardDescription>{benefit.description}</CardDescription>
          </Card>
        ))}
      </Grid>
      <MContainer>
        <Header>
          <TitleContainer>
            <Title>무료 서비스</Title>
            <TitleImg src={Homepag_star} alt="Homepag_star" />
          </TitleContainer>
          <Description>
            홈페이지를 제작하시는 고객님들께 공동의 몇 가지
            <br /> 서비스를 무료로 제공해 드리고 있습니다.
          </Description>
        </Header>
        <Flex>
          <Swiper
            spaceBetween={20}
            slidesPerView={1.1} // 기본 값
            style={{ marginRight: '2px' }}
          >
            {benefits.map((benefit, index) => (
              <SwiperSlide key={index}>
                <Card key={index}>
                  <Index>혜택 {index < 9 ? `0${index + 1}` : index + 1}</Index>
                  <CardTitle>{benefit.title}</CardTitle>
                  <CardDescription>{benefit.description}</CardDescription>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>
      </MContainer>
    </Container>
  );
};

export default HomePageFreeServicesComponent;
