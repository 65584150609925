// IsPremiumSelector.tsx
import React, { useState, useEffect } from 'react';
import chevron_left from '../../../../assets/images/servicesPage/marketingServicePage/chevron_left.svg';

interface IsPremiumSelectorProps {
  setIsPremium: (isPremium: boolean) => void;
}

function MarketingIsPremiumSelector({ setIsPremium }: IsPremiumSelectorProps) {
  const [slideNumber, setSlideNumber] = useState(0);

  useEffect(() => {
    setIsPremium(slideNumber === 1);
  }, [setIsPremium, slideNumber]);

  return (
    <div
      style={{
        display: 'flex',
        padding: '16px',
        justifyContent: 'center',
      }}
    >
      <img
        src={chevron_left}
        alt=""
        style={{
          visibility: slideNumber === 0 ? 'hidden' : 'visible',
          cursor: 'pointer',
        }}
        onClick={() => setSlideNumber(slideNumber - 1)}
      />
      <div style={{ overflow: 'clip' }}>
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            transform: `translateX(-${slideNumber * 100}%)`,
            transition: 'transform 0.5s',
          }}
        >
          <div style={{ width: '100%', flexShrink: 0 }}>
            <p
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                paddingBottom: '16px',
              }}
            >
              BASIC SERVICE
            </p>
            <p>가장 기본이 되는 서비스</p>
          </div>
          <div style={{ width: '100%', flexShrink: 0 }}>
            <p
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                paddingBottom: '16px',
              }}
            >
              PREMIUM SERVICE
            </p>
            <p>조금 더 업그레이드 된 서비스</p>
          </div>
        </div>
      </div>
      <img
        src="/img/chevron_left.svg"
        alt=""
        style={{
          transform: 'scaleX(-1)',
          visibility: slideNumber === 1 ? 'hidden' : 'visible',
          cursor: 'pointer',
        }}
        onClick={() => setSlideNumber(slideNumber + 1)}
      />
    </div>
  );
}

export default MarketingIsPremiumSelector;
