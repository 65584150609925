import React from 'react';
import TopBar from '../../../components/user/topBar/TopBar';
import ServicesBanner from '../../../components/user/servicesPage/ServicesBanner';
import { useIsMobile } from '../../../hooks/useIsMobile';
import MarketingServiceEntry from '../../../components/user/servicesPage/Marketing/MarketingServiceEntry';
import { Swiper, SwiperSlide } from 'swiper/react';
import MarketingServiceDetails from '../../../components/user/servicesPage/Marketing/MarketingServiceDetails';
import styled from 'styled-components';
import content_planning from '../../../assets/images/servicesPage/marketingServicePage/content_planning.jpg';
import da_ad from '../../../assets/images/servicesPage/marketingServicePage/da_ad.jpg';
import instagram_shopping from '../../../assets/images/servicesPage/marketingServicePage/instagram_shopping.jpg';
import launching_promotion from '../../../assets/images/servicesPage/marketingServicePage/launching_promotion.jpg';
import FloatingButtonGroup from '../../../components/user/mainPage/FloatingButton';

const Content = styled.div`
  padding: 32px 15%;
  max-width: 1920px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    padding: 32px 10%;
  }
  @media (max-width: 1024px) {
    padding: 0 5%;
  }
`;

const Title = styled.p`
  font-size: 50px;
  font-weight: 700;
  padding-bottom: 32px;

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 24px;
  }
`;

const EntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

function MarketingServicesPage() {
  const isMobile = useIsMobile();
  const entries = [
    <MarketingServiceEntry
      imagePath={instagram_shopping}
      title="인스타그램 쇼핑몰 광고"
      description="인스타그램 쇼핑몰 광고는 브랜드와 제품을 시각적으로 매력적으로 소개하고, 소비자가 플랫폼 내에서 직접 구매로 이어질 수 있도록 돕는 디지털 마케팅 전략입니다."
      thirdText="고객 맞춤 타겟팅, 직관적인 구매 과정, 다양한 광고 형식, 성과 측정 가능"
    />,
    <MarketingServiceEntry
      imagePath={launching_promotion}
      title="런칭 프로모션 및 캠페인"
      description="새로운 제품, 서비스 또는 브랜드를 시장에 소개하고 소비자의 관심과 참여를 유도하기 위해 진행하는 마케팅 전략입니다. 성공적인 런칭 캠페인은 초기 인지도를 높이고, 시장에 강렬한 첫인상을 남기며, 판매 또는 전환을 촉진하는 데 중점을 둡니다"
      thirdText="제품/서비스 인지도 확산, 브랜드 신뢰 구축, 스토리텔링 중심 캠페인"
    />,
    <MarketingServiceEntry
      imagePath={content_planning}
      title="콘텐츠 기획"
      description="단순한 콘텐츠 제작을 넘어, 소비자와 브랜드 간의 신뢰를 구축하고, 효과적인 소통을 통해 비즈니스 목표를 달성하는 데 핵심적인 역할을 합니다. 체계적이고 창의적인 콘텐츠 기획은 브랜드의 장기적인 성공을 위한 중요한 전략입니다."
      thirdText="정보 제공형, 참여 유도형, 스토리텔링, 판매 중심 콘텐츠"
    />,
    <MarketingServiceEntry
      imagePath={da_ad}
      title="DA 광고"
      description="디지털 플랫폼에서 이미지, 동영상, 텍스트 등을 활용해 소비자에게 메시지를 전달하는 온라인 광고 방식입니다. 웹사이트, 모바일 앱, 소셜 미디어 등 다양한 디지털 공간에서 표시되며, 브랜드 인지도 상승과 고객 행동 유도를 목적으로 합니다."
      thirdText="배너, 동영상, 팝업 및 인터스틸, 리타겟팅 광고"
    />,
  ];

  return (
    <div>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <ServicesBanner />
      <Content>
        <Title>광고마케팅</Title>
        {isMobile && (
          <p
            style={{
              color: '#0000004D',
              textAlign: 'center',
              paddingBottom: '16px',
            }}
          >
            옆으로 넘겨주세요 --&gt;
          </p>
        )}
        {isMobile ? (
          <Swiper spaceBetween={32}>
            {entries.map((entry, index) => (
              <SwiperSlide key={index}>{entry}</SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <EntryContainer>{entries}</EntryContainer>
        )}
      </Content>
      <MarketingServiceDetails />
    </div>
  );
}

export default MarketingServicesPage;
