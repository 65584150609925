import React from 'react';
import styled from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';
import Icon1 from '../../../../assets/images/servicesPage/serverHostingServicesPage/HostingUsageStepsIcon1.svg'; // "상품 신청" 아이콘 경로
import Icon2 from '../../../../assets/images/servicesPage/serverHostingServicesPage/HostingUsageStepsIcon2.svg'; // "견적서/신청서 발송" 아이콘 경로
import Icon3 from '../../../../assets/images/servicesPage/serverHostingServicesPage/HostingUsageStepsIcon3.svg'; // "견적서/신청서 발송" 아이콘 경로
import Icon4 from '../../../../assets/images/servicesPage/serverHostingServicesPage/HostingUsageStepsIcon4.svg'; // "견적서/신청서 발송" 아이콘 경로
import Icon5 from '../../../../assets/images/servicesPage/serverHostingServicesPage/HostingUsageStepsIcon5.svg'; // "견적서/신청서 발송" 아이콘 경로

// 전체 컨테이너
const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 5px dashed #d8d1c9;
  border-radius: 30px;
  gap: 20px;
  margin: 120px 0px;
  @media (max-width: 1024px) {
    border-top: 5px dashed #d8d1c9;
    border-bottom: 5px dashed #d8d1c9;
    border-right: none;
    border-left: none;
    border-radius: 0px;
    margin: 80px 0px 20px;
  }
`;

// 제목 스타일
const Title = styled.h2`
  width: 167px;
  position: absolute;
  top: -15px;
  background-color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  @media (max-width: 1024px) {
    width: 125px;
    font-size: 22px;
    line-height: 22px; /* 68.182% */
  }
`;

// 절차 단계들을 묶는 컨테이너
const StepsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding: 40px 20px;

  @media (max-width: 1024px) {
    padding: 20px;
    overflow-x: auto; /* 수평 스크롤 활성화 */
    scroll-snap-type: x mandatory; /* 스크롤 스냅 활성화 */
    -webkit-overflow-scrolling: touch; /* 부드러운 스크롤 */

    /* 스크롤바 숨김 */
    &::-webkit-scrollbar {
      display: none; /* 웹킷 브라우저에서 스크롤바 숨김 */
    }

    -ms-overflow-style: none; /* IE, Edge에서 스크롤바 숨김 */
    scrollbar-width: none; /* Firefox에서 스크롤바 숨김 */
  }
`;

// 각 단계 카드
const StepCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  @media (max-width: 1024px) {
    min-width: 170px; /* 카드 크기를 설정하여 스크롤에서 볼 수 있도록 만듦 */
    scroll-snap-align: center; /* 스크롤 시 카드가 중앙에 정렬되도록 설정 */
  }
`;

// 아이콘 스타일
const Icon = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  @media (max-width: 1280px) {
    width: 80px;
    height: 80px;
  }
`;

// 단계 제목
const StepTitleContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const StepTitle = styled.h3`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 75% */
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 18px; /* 83.333% */
  }
`;

// 단계 설명
const StepDescription = styled.p`
  word-break: keep-all;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  color: #bababa;
  @media (max-width: 1024px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
`;

// 화살표
const Arrow = styled.div`
  display: flex;
  align-items: center; /* 화살표를 세로로 가운데 정렬 */
  justify-content: center; /* 가로 정렬도 가운데 */
  height: auto; /* 부모 컨테이너에 맞게 자동 크기 */
  color: #ccc;
  margin: auto; /* StepCard와 정렬을 맞추기 위해 여백 조정 */
  font-size: 24px;
`;

const HostingUsageSteps = () => {
  return (
    <Container>
      <Title>이용절차</Title>
      <StepsContainer>
        <StepCard>
          <Icon src={Icon1} alt="상품 신청" />
          <StepTitleContainer>
            <StepTitle>상품 신청</StepTitle>
            <StepDescription>
              원하는 상품을 선택하여
              <br /> 서비스 신청 페이지에서 신청
            </StepDescription>
          </StepTitleContainer>
        </StepCard>
        <Arrow>
          <IoIosArrowForward />
        </Arrow>
        <StepCard>
          <Icon src={Icon2} alt="견적서/신청서 발송" />
          <StepTitleContainer>
            <StepTitle>견적서/신청서 발송</StepTitle>
            <StepDescription>
              업체에서 사양협의 확인 후<br /> 견적서와 신청서 발송
            </StepDescription>
          </StepTitleContainer>
        </StepCard>
        <Arrow>
          <IoIosArrowForward />
        </Arrow>
        <StepCard>
          <Icon src={Icon3} alt="신청서 작성" />
          <StepTitleContainer>
            <StepTitle>신청서 작성</StepTitle>
            <StepDescription>작성한 신청서를 업체에 발송</StepDescription>
          </StepTitleContainer>
        </StepCard>
        <Arrow>
          <IoIosArrowForward />
        </Arrow>
        <StepCard>
          <Icon src={Icon4} alt="설치 진행" />
          <StepTitleContainer>
            <StepTitle>설치 진행</StepTitle>
            <StepDescription>신청서 확인 후 설치 진행</StepDescription>
          </StepTitleContainer>
        </StepCard>
        <Arrow>
          <IoIosArrowForward />
        </Arrow>
        <StepCard>
          <Icon src={Icon5} alt="서비스 이용" />
          <StepTitleContainer>
            <StepTitle>서비스 이용</StepTitle>
            <StepDescription>서비스 개통</StepDescription>
          </StepTitleContainer>
        </StepCard>
      </StepsContainer>
    </Container>
  );
};

export default HostingUsageSteps;
