import PictureBanner from '../../components/PictureBanner';
import TopBar from '../../components/user/topBar/TopBar';
import HomepageBanner from '../../assets/images/homepageProductionPage/HomepageBanner.png';
import React from 'react';
import FloatingButtonGroup from '../../components/user/mainPage/FloatingButton';
import HomePageStepsComponent from '../../components/user/homepageProductionPage/HomePageStepsComponent';
import styled from 'styled-components';
import HomePageFreeServicesComponent from '../../components/user/homepageProductionPage/HomePageFreeServicesComponent';
import HomePagePreparationSteps from '../../components/user/homepageProductionPage/HomePagePreparationSteps';

const Container = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 400px;
  padding: 0 15%;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 128px;

  @media (max-width: 1440px) {
    padding: 0 10%;
  }
  @media (max-width: 1024px) {
    padding: 0 5%;
    background-image: none;
    margin-bottom: 64px;
  }
`;

function HomepageProductionPage() {
  return (
    <div>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <PictureBanner
        title="홈페이지 제작"
        subtitle="로동의 오랜 경험과 노하우로 클라이언트가 만족하는 홈페이지를 제작해 드립니다."
        titleColor="white"
        imagePath={HomepageBanner}
      />
      <Container>
        <HomePageStepsComponent />
      </Container>
      <HomePageFreeServicesComponent />
      <Container>
        <HomePagePreparationSteps />
      </Container>
    </div>
  );
}

export default HomepageProductionPage;
