import React from 'react';
import styled from 'styled-components';

// 전체 컨테이너
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

// 카드 컨테이너
const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 80px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

// 개별 카드
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  width: 100%;
  padding: 40px 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;

// 텍스트 스타일
const Description = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 75% */
`;

const HostingServiceBox = () => {
  return (
    <Container>
      <CardContainer>
        <Card>
          <Description>망 분리 이미지 백업</Description>
        </Card>
        <Card>
          <Description>네트워크 격리 백업</Description>
        </Card>
        <Card>
          <Description>웹 방화벽</Description>
        </Card>
        <Card>
          <Description>SSL 보안서버</Description>
        </Card>
      </CardContainer>
    </Container>
  );
};

export default HostingServiceBox;
