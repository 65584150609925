interface ServiceDetailEntry {
  name?: string;
  on: 'all' | 'basic' | 'premium';
}

interface MobileServiceDetailsBodyProps {
  isPremium: boolean;
  serviceDetailEntries: ServiceDetailEntry[][];
}

function MarketingMobileServiceDetailsBody({
  isPremium,
  serviceDetailEntries,
}: MobileServiceDetailsBodyProps) {
  return (
    <div
      style={{
        padding: '24px 0',
        fontSize: '18px',
        lineHeight: 2,
      }}
    >
      {serviceDetailEntries.flat().map((entry, index) => {
        if (!entry.name) return null;
        return (
          <p
            key={index}
            style={{
              maxHeight:
                (isPremium && entry.on === 'basic') ||
                (!isPremium && entry.on === 'premium')
                  ? 0
                  : '100px',
              transition: 'max-height 1s',
              overflow: 'hidden',
            }}
          >
            {entry.name}
          </p>
        );
      })}
    </div>
  );
}

export default MarketingMobileServiceDetailsBody;
