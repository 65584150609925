import React from 'react';
import styled from 'styled-components';

const Entry = styled.div`
  background-color: black;
  color: white;
  padding: 20px;
  border-radius: 16px;
  text-align: center;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  p:first-child {
    font-size: 20px;
    font-weight: bold;
  }

  p:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: keep-all;
    font-size: 16px;
    line-height: 20px; /* 125% */
    height: 40px;
  }

  @media (max-width: 1024px) {
    p:last-child {
      word-break: keep-all;
      font-size: 14px;
      line-height: 20px; /* 125% */
    }
  }
`;

function DesignServiceEntry({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <Entry>
      <p>{title}</p>
      <p>{description}</p>
    </Entry>
  );
}

export default DesignServiceEntry;
