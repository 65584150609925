import React from 'react';
import styled from 'styled-components';
import HomePagePreparationSteps1 from '../../../assets/images/homepageProductionPage/HomePagePreparationSteps1.png';
import HomePagePreparationSteps2 from '../../../assets/images/homepageProductionPage/HomePagePreparationSteps2.png';
import HomePagePreparationSteps3 from '../../../assets/images/homepageProductionPage/HomePagePreparationSteps3.png';
import HomePagePreparationSteps4 from '../../../assets/images/homepageProductionPage/HomePagePreparationSteps4.png';
import HomePagePreparationSteps5 from '../../../assets/images/homepageProductionPage/HomePagePreparationSteps5.png';
import HomePagePreparationSteps6 from '../../../assets/images/homepageProductionPage/HomePagePreparationSteps6.png';

const Container = styled.div`
  padding-top: 80px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  h2 {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 66.667% */
  }

  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1280px) {
    grid-template-columns: 2fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  & > :nth-child(1),
  & > :nth-child(4) {
    padding-left: 0px;
  }
  & > :nth-child(3),
  & > :nth-child(6) {
    padding-right: 0px;
  }
  & > :nth-child(1),
  & > :nth-child(2),
  & > :nth-child(4),
  & > :nth-child(5) {
    border-right: 1px solid #ddd;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  padding: 0px 20px;
  margin-top: 50px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 150px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 100% */
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 162.5% */
    min-height: 52px;
  }
`;

const Number = styled.div`
  color: rgba(38, 38, 38, 0.1);
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px; /* 50% */
`;

const data = [
  {
    number: '01',
    title: '기초자료준비',
    description:
      '회사소개, 인사말, 약도, 사업자등록증 등 기본적인 정보가 담긴 내용을 준비해 주세요.',
    image: HomePagePreparationSteps1, // Replace with actual image URL
  },
  {
    number: '02',
    title: '벤치마킹 사이트 서치',
    description:
      '눈여겨 봤거나 좋아하는 홈페이지를 추천해주세요. 사이트를 선정해 주시면 고객님의 니즈를 쉽게 파악할 수 있습니다.',
    image: HomePagePreparationSteps2, // Replace with actual image URL
  },
  {
    number: '03',
    title: '도메인 선정',
    description:
      '기억하기 쉬운 도메인을 등록으로 제작물을 유연할 수 있습니다. 원하시는 도메인을 알려주세요.',
    image: HomePagePreparationSteps3, // Replace with actual image URL
  },
  {
    number: '04',
    title: '이미지 자료',
    description:
      '회사 및 사업 사진이나 로고를 준비해 주세요. 참고가 될만한 사진은 저작권 문제가 없도록 해 주세요.',
    image: HomePagePreparationSteps4, // Replace with actual image URL
  },
  {
    number: '05',
    title: '사이트맵 구성',
    description:
      '홈페이지의 전체적인 구조를 만들어 주세요. 큰 틀에 정해진 기준 정보를 제공해 주시면 사이트맵을 완성할 수 있습니다.',
    image: HomePagePreparationSteps5, // Replace with actual image URL
  },
  {
    number: '06',
    title: '컨텐츠 내용준비',
    description:
      '홈페이지의 상세 내용을 문서화하여 준비해 주세요. 텍스트와 이미지 자료를 각각 정리해 주세요.',
    image: HomePagePreparationSteps6, // Replace with actual image URL
  },
];

const HomePagePreparationSteps = () => {
  return (
    <Container>
      <Header>
        <h2>고객 준비 사항</h2>
        <p>
          홈페이지 제작에 필요한 자료들을 취합하여 준비해 주시면 고객이 원하시는
          결과물에 더 가까워집니다.
        </p>
      </Header>
      <Grid>
        {data.map((item, index) => (
          <Card key={index}>
            <Number>{item.number}</Number>
            <Content>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </Content>
            <ImageWrapper>
              <img src={item.image} alt={item.title} />
            </ImageWrapper>
          </Card>
        ))}
      </Grid>
    </Container>
  );
};

export default HomePagePreparationSteps;
