import React from 'react';
import styled from 'styled-components';
import { IoSearch } from 'react-icons/io5';

const SearchInput = styled.input`
  border-radius: 25px;
  border: 1px solid #26262633;
  background: white;
  padding: 10px 20px;
  font-size: 16px;
  width: calc(100% - 1rem);
  @media (max-width: 1024px) {
    padding: 7px 10px;
  }
`;

const SearchSelect = styled.select`
  border-radius: 25px;
  border: 1px solid #26262633;
  background: white;
  padding: 10px 20px;
  width: 120px;
  font-size: 16px;
  @media (max-width: 1024px) {
    padding: 7px 10px;
  }
`;

const SearchIcon = styled(IoSearch)`
  position: absolute;
  right: 25px;
  top: 45%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #a0a0a0;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const SearchBarContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 1rem;
  display: flex;
  justify-content: end;
  @media (max-width: 1024px) {
    justify-content: center;
    padding: 16px 0px;
  }
`;

const SearchBarStyle = styled.div`
  display: flex;
  gap: 1rem;
  width: 460px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

type SearchBarProps = {
  subCategories: { id: number | null; name: string }[];
  activeCategory: number | null;
  setActiveCategory: (categoryId: number | null) => void;
  searchTerm: string; // ✅ 검색어 상태 추가
  setSearchTerm: (term: string) => void; // ✅ 검색어 업데이트 함수 추가
};

const SearchBar: React.FC<SearchBarProps> = ({
  subCategories,
  activeCategory,
  setActiveCategory,
  searchTerm,
  setSearchTerm,
}) => {
  return (
    <SearchBarContainer>
      <SearchBarStyle>
        <SearchSelect
          value={activeCategory ?? ''}
          onChange={(e) =>
            setActiveCategory(e.target.value ? Number(e.target.value) : null)
          }
        >
          {subCategories.map((category) => (
            <option key={category.id ?? '전체'} value={category.id ?? ''}>
              {category.name}
            </option>
          ))}
        </SearchSelect>
        <div style={{ position: 'relative', flexGrow: 1 }}>
          <SearchInput
            placeholder="검색어를 입력하세요"
            value={searchTerm} // ✅ 검색어 입력값 적용
            onChange={(e) => setSearchTerm(e.target.value)} // ✅ 검색어 상태 업데이트
          />
          <SearchIcon />
        </div>
      </SearchBarStyle>
    </SearchBarContainer>
  );
};

export default SearchBar;
