import styled from 'styled-components';
import PictureBanner from '../../../components/PictureBanner';
import { useIsMobile } from '../../../hooks/useIsMobile';
import building_banner from '../../../assets/images/introductionPage/building_banner.png';
import FloatingButtonGroup from '../../../components/user/mainPage/FloatingButton';
import TopBar from '../../../components/user/topBar/TopBar';
import HistoryPageBg from '../../../assets/images/introductionPage/historyPageBg.svg';

const Img = styled.img`
  position: absolute;
  top: 800px;
  z-index: 0;

  @media (max-width: 1440px) {
    top: 700px;
    width: 400px;
  }
  @media (max-width: 1024px) {
    top: 470px;
    width: 250px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1920px;
  padding: 100px 15%;
  margin: 0 auto;
  color: #fff;

  @media (max-width: 1440px) {
    padding: 100px 10% 0;
  }
  @media (max-width: 1024px) {
    padding: 50px 5% 0;
  }
  @media (max-width: 568px) {
    padding: 30px 5% 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 100px;
  font-weight: 900;
  text-align: center;

  @media (max-width: 1440px) {
    font-size: 80px;
  }
  @media (max-width: 1024px) {
    font-size: 60px;
  }
  @media (max-width: 768px) {
    font-size: 40px;
  }
  @media (max-width: 568px) {
    font-size: 30px;
  }
`;

const SubTitle = styled.p`
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  word-break: keep-all;
  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media (max-width: 568px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const HistoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 200px 0px;
  font-size: 24px;
  gap: 40px;

  @media (max-width: 1440px) {
    padding: 100px 0px;
  }
  @media (max-width: 1024px) {
    padding: 80px 0px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 0px;
    gap: 20px;
    text-align: center;
  }
  @media (max-width: 568px) {
    padding: 30px 0px;
  }
`;

const Year = styled.h2`
  flex: 1;
  font-size: 200px;
  font-weight: 900;

  @media (max-width: 1440px) {
    font-size: 140px;
  }
  @media (max-width: 1024px) {
    font-size: 100px;
  }
  @media (max-width: 768px) {
    font-size: 60px;
  }
  @media (max-width: 568px) {
    font-size: 40px;
  }
`;

const Highlight = styled.span`
  color: #979797;
`;

const DescContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 768px) {
    align-items: flex-start;
    gap: 10px;
  }
`;

const DescItem = styled.p`
  word-break: keep-all;
  text-align: left;
  line-height: 24px;
  font-size: 20px;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const FirstDescItem = styled(DescItem)`
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
  margin-bottom: 10px;

  @media (max-width: 1440px) {
    font-size: 30px;
    line-height: 34px;
  }
  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const HistoryItem = ({ year, items }: { year: string; items: string[] }) => (
  <HistoryContainer>
    <Year>
      <Highlight>{year.substring(0, 2)}</Highlight>
      {year.substring(2)}
    </Year>
    <DescContainer>
      {items.map((item: string, index: number) =>
        index === 0 ? (
          <FirstDescItem key={index}>{item}</FirstDescItem>
        ) : (
          <DescItem key={index}>{item}</DescItem>
        )
      )}
    </DescContainer>
  </HistoryContainer>
);

function HistoryPage() {
  const isMobile = useIsMobile();

  return (
    <div style={{ position: 'relative', backgroundColor: '#262626' }}>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <PictureBanner
        title="COMPANY"
        subtitle={isMobile ? '' : '연혁'}
        titleColor="black"
        subtitleDecoration={!isMobile}
        imagePath={building_banner}
      />
      <Img src={HistoryPageBg} alt="HistoryPageBg" />
      <Container>
        <TitleContainer>
          <Title>LODONG’S VALUE</Title>
          <SubTitle>
            로동은 고객 성공과 가치를 창출하기 위해 혁신과 도전으로 더 밝은
            내일을 향해 나아가고 있습니다.
          </SubTitle>
        </TitleContainer>

        <HistoryItem
          year="2024"
          items={[
            '자동차 배터리 업사이클 기업 POEN 전기차 배터리 관리 시스템 구축',
            '게임 개발자 컨퍼런스 GGDC 홈페이지 오픈',
            '동영상 스트리밍 ARTFLEET 홈페이지 구축',
            '목포대 산학협력단 외 어플리케이션 제작',
            '삼성헬스앤라이프 쇼핑몰 구축',
          ]}
        />
      </Container>
    </div>
  );
}

export default HistoryPage;
