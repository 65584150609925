import styled from 'styled-components';
import globe from '../../../assets/images/globe.svg';
import { BrochurePageUrl } from '../../../url/lodongUrl';

const SideMenuContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: #dcdcdc;
  font-size: 14px;
`;

const SideMenuLink = styled.a`
  text-decoration: none;
  color: #dcdcdc;
  font-size: 14px;
`;

const GlobeIcon = styled.img`
  width: auto;
  height: auto;
`;

function SideMenus() {
  return (
    <SideMenuContainer>
      <SideMenuLink href={BrochurePageUrl}>회사소개서</SideMenuLink>
      |
      <GlobeIcon src={globe} alt="globe" />
      KOR
    </SideMenuContainer>
  );
}

export default SideMenus;
