interface PriceProps {
  originalPrice: number;
  discountedPrice: number;
}

function MarketingPrice({ originalPrice, discountedPrice }: PriceProps) {
  const originalPriceString = originalPrice.toLocaleString();
  const discountedPriceString = discountedPrice.toLocaleString();
  return (
    <div style={{ textAlign: 'center' }}>
      <p
        style={{
          textDecoration: 'line-through',
          fontSize: '20px',
          paddingBottom: '8px',
        }}
      >
        {originalPriceString}
      </p>
      <p style={{ fontSize: '24px' }}>{discountedPriceString} (VAT 제외)</p>
    </div>
  );
}

export default MarketingPrice;
