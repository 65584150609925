import axios, {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { AdminLoginPageUrl } from '../url/lodongUrl';

/**
 * 📌 Response Data 타입 정의
 */
interface ResponseData {
  resultMsg?: string; // 선택적 프로퍼티
  reason?: string; // 선택적 프로퍼티
  [key: string]: unknown; // 기타 프로퍼티 허용
}

/**
 * 📌 baseURL 설정
 */
const baseURL = (() => {
  const hostname = window.location.hostname.replace('admin.', '');
  if (['localhost', '127.0.0.1'].includes(hostname))
    return 'http://172.27.1.1:11223/';
  return `${window.location.protocol}//${hostname}${
    window.location.port ? ':' + window.location.port : ''
  }/`;
})();

const loginBaseURL = (() => {
  const hostname = window.location.hostname.replace('admin.', '');
  if (['localhost', '127.0.0.1'].includes(hostname))
    return 'http://172.27.1.1:11221/';
  return `${window.location.protocol}//${hostname}${
    window.location.port ? ':' + window.location.port : ''
  }/`;
})();

/**
 * 📌 Axios 인스턴스 생성
 */
const apiClient: AxiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

const loginApiClient: AxiosInstance = axios.create({
  baseURL: loginBaseURL,
  withCredentials: true,
});

console.log(baseURL);

/**
 * 📌 상태 코드 메시지
 */
const statusMessages: Record<number, string> = {
  200: '요청이 성공적으로 처리되었습니다.',
  201: '리소스가 성공적으로 생성되었습니다.',
  204: '요청이 성공적으로 처리되었으나 응답할 콘텐츠가 없습니다.',
  400: '잘못된 요청입니다.',
  401: '인증이 필요합니다.',
  403: '접근이 금지되었습니다.',
  404: '리소스를 찾을 수 없습니다.',
  500: '서버 오류가 발생했습니다.',
};

/**
 * 📌 401 및 403 에러 핸들링
 */
const handleUnauthorizedResponse = (
  status: number,
  resultMsg: string,
  reason: string
) => {
  const authority = localStorage.getItem('authority');

  const errorMessage =
    status === 401
      ? `${401} ${resultMsg} ${reason}`
      : `${403} ${resultMsg} ${reason}`;

  localStorage.clear();
  sessionStorage.clear();
  document.cookie.split(';').forEach((cookie) => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  });

  if (authority === 'admin') {
    window.location.href = AdminLoginPageUrl; // 관리자 로그인 페이지로 리다이렉트
  } else {
    window.location.href = AdminLoginPageUrl; // 사용자 로그인 페이지로 리다이렉트
  }

  return Promise.reject(new Error(errorMessage));
};

/**
 * 📌 공통 인터셉터 설정
 */
const applyInterceptors = (client: AxiosInstance) => {
  client.interceptors.request.use(
    (config: InternalAxiosRequestConfig<ResponseData>) => {
      const useToken = config.headers?.['useToken'] !== false;
      if (useToken) {
        const token = localStorage.getItem('accessToken');
        if (token) config.headers.Authorization = `Bearer ${token}`;
      }
      delete config.headers?.['useToken'];
      return config;
    },
    (error: AxiosError<ResponseData>) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response: AxiosResponse<ResponseData>) => {
      if (response.config.responseType === 'blob') return response;

      const message = statusMessages[response.status] || '요청이 성공했습니다.';
      return { ...response, data: { ...response.data, message } };
    },
    (error: AxiosError<ResponseData>) => {
      const { response } = error;

      if (response && [401, 403].includes(response.status)) {
        return handleUnauthorizedResponse(
          response.status,
          response.data?.resultMsg || '인증 오류',
          response.data?.reason || '인증이 필요합니다.'
        );
      }

      if (response && response.status === 500) {
        const resultMsg = response.data?.reason || '서버 오류가 발생했습니다.';
        return Promise.reject(new Error(resultMsg));
      }

      const status = response?.status ?? 0; // 상태가 없는 경우 기본값 0
      const message =
        statusMessages[status] ||
        `${status}: ${response?.data?.resultMsg || '알 수 없는 오류'}`;
      return Promise.reject(new Error(message));
    }
  );
};

applyInterceptors(apiClient);
applyInterceptors(loginApiClient);

export { apiClient, loginApiClient };
