import styled from 'styled-components';
import KeyTechnologyCard from './KeyTechnologyCard';
import DesignIcon from '../../../assets/images/mainPage/DesignIcon.svg';
import DesignIconHover from '../../../assets/images/mainPage/DesignIconHover.svg';
import HomepageIcon from '../../../assets/images/mainPage/HomepageIcon.svg';
import HomepageIconHover from '../../../assets/images/mainPage/HomepageIconHover.svg';
import LMS from '../../../assets/images/mainPage/LMS.svg';
import LMSHover from '../../../assets/images/mainPage/LMSHover.svg';
import SI from '../../../assets/images/mainPage/SI.svg';
import SIHover from '../../../assets/images/mainPage/SIHover.svg';
import Hosting from '../../../assets/images/mainPage/Hosting.svg';
import HostingHover from '../../../assets/images/mainPage/HostingHover.svg';
import Video from '../../../assets/images/mainPage/Video.svg';
import VideoHover from '../../../assets/images/mainPage/VideoHover.svg';
import DesignBG from '../../../assets/images/mainPage/DesignBG.jpg';
import HostingBG from '../../../assets/images/mainPage/HostingBG.jpg';
import HomepageBG from '../../../assets/images/mainPage/HomepageBG.jpg';
import SIBG from '../../../assets/images/mainPage/SIBG.png';
import VideoBG from '../../../assets/images/mainPage/VideoBG.jpg';
import LMSBG from '../../../assets/images/mainPage/LMSBG.jpg';
import {
  ArtfleetServicesPageUrl,
  DesignServicesPageUrl,
  HomepageProductionPageUrl,
  HrdloServicesPageUrl,
  ServerHostingServicesPageUrl,
} from '../../../url/lodongUrl';

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  padding: 0px 15%;
  max-width: 1920px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    padding: 0px 10%;
  }
  @media (max-width: 1024px) {
    padding: 0px 5%;
    height: auto;
  }
`;

const ScreenPadding = styled.div`
  height: auto;
  width: auto;
  margin-top: 120px;
  margin-bottom: 120px;
  @media (max-width: 1024px) {
    margin-top: 25px;
    margin-bottom: 50px;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  @media (max-width: 1024px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.48px;
  }
`;

const SubTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 1024px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const LodongSystemText = styled.p`
  color: #26262605;
  font-size: 120px;
  font-weight: 900;
  margin-bottom: -24px;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 32px;
    margin-bottom: -6px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

function KeyTechnology() {
  return (
    <Screen>
      <ScreenPadding>
        <Title>MAIN SERVICES</Title>
        <SubTitle>로동만의 핵심 서비스</SubTitle>
        <LodongSystemText>LODONG SYSTEM</LodongSystemText>
        <Grid>
          <KeyTechnologyCard
            backgroundImage={HomepageBG}
            title="홈페이지 개발"
            description={`홈페이지는 고객과 함께 만들어 가는 과정 요구사항을 반영하여 원하는 결과물을 제공`}
            iconPath={HomepageIcon}
            iconHoverPath={HomepageIconHover}
            link={HomepageProductionPageUrl}
          />
          <KeyTechnologyCard
            backgroundImage={SIBG}
            title="SI개발"
            description={`여러 소프트웨어와 하드웨어를 하나의 시스템으로 만드는 기술. 이들이 서로 잘 연결되고 원활하게 작동하도록 지원`}
            iconPath={SI}
            iconHoverPath={SIHover}
            link={DesignServicesPageUrl}
          />
          <KeyTechnologyCard
            backgroundImage={HostingBG}
            title="호스팅 서비스"
            description={`웹사이트 파일을 저장 및 인터넷 연결을 통해 사람들이 언제 어디서든 웹사이트에 원활한 접속을 지원`}
            iconPath={Hosting}
            iconHoverPath={HostingHover}
            link={ServerHostingServicesPageUrl}
          />
          <KeyTechnologyCard
            backgroundImage={VideoBG}
            title="비디오 스트리밍 서비스"
            description={`사용자 영상 콘텐츠를 쉽고 빠르게 관리 다양한 구독 옵션을 통해 차별화된 시청 경험`}
            iconPath={Video}
            iconHoverPath={VideoHover}
            link={ArtfleetServicesPageUrl}
          />
          <KeyTechnologyCard
            backgroundImage={DesignBG}
            title="디자인"
            description={`디자인은 브랜드의 첫인상을 결정 고객의 아이덴티티를 반영하여 감각적이고 사용자 친화적인 디자인을 제공`}
            iconPath={DesignIcon}
            iconHoverPath={DesignIconHover}
            link={DesignServicesPageUrl}
          />
          <KeyTechnologyCard
            backgroundImage={LMSBG}
            title="HRD & LMS"
            description={`교육 훈련 과정 및 학습자 관리의 온라인 플랫폼 다양한 교육 프로그램 정보를 제공`}
            iconPath={LMS}
            iconHoverPath={LMSHover}
            link={HrdloServicesPageUrl}
          />
        </Grid>
      </ScreenPadding>
    </Screen>
  );
}

export default KeyTechnology;
