// Entry.tsx
import React, { useState } from 'react';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import MarketingIsPremiumSelector from './MarketingIsPremiumSelector';
import MarketingMobileServiceDetailsBody from './MarketingMobileServiceDetailsBody';
import MarketingDesktopServiceDetailsBody from './MarketingDesktopServiceDetailsBody';
import MarketingPrice from './MarketingPrice';
import { marketingServiceDetailEntries } from './MarketingServiceDetailEntries';

function MarketingServiceDetails() {
  const [isPremium, setIsPremium] = useState(false);

  const isMobile = useIsMobile();

  return (
    <div
      style={{
        backgroundColor: 'black',
        color: 'white',
        marginTop: '32px',
        padding: isMobile ? '0 24px' : '64px 0',
      }}
    >
      <MarketingIsPremiumSelector setIsPremium={setIsPremium} />

      {isMobile ? (
        <>
          <div
            style={{
              height: '1px',
              backgroundColor: 'white',
              margin: '4px 0',
            }}
          />
          <MarketingMobileServiceDetailsBody
            isPremium={isPremium}
            serviceDetailEntries={marketingServiceDetailEntries}
          />
          <div
            style={{
              height: '1px',
              backgroundColor: 'white',
              margin: '4px 0',
            }}
          />
        </>
      ) : (
        <div
          style={{
            padding: '64px 15%',
          }}
        >
          <MarketingDesktopServiceDetailsBody
            isPremium={isPremium}
            serviceDetailEntries={marketingServiceDetailEntries}
          />
        </div>
      )}

      <div
        style={{
          padding: '24px 0',
        }}
      >
        <MarketingPrice
          originalPrice={isPremium ? 4000000 : 2000000}
          discountedPrice={isPremium ? 3500000 : 1950000}
        />
      </div>
    </div>
  );
}

export default MarketingServiceDetails;
