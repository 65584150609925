import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 100px 15%;
  max-width: 1920px;
  margin: 0 auto;
  color: #262626;

  @media (max-width: 1440px) {
    padding: 100px 10%;
  }
  @media (max-width: 1024px) {
    padding: 50px 5%;
    gap: 32px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 20px;
    margin-bottom: 12px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 80px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 36px;
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > h3 {
    word-break: keep-all;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
  }

  & > p {
    word-break: keep-all;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }
  @media (max-width: 1024px) {
    gap: 10px;
    & > h3 {
      font-size: 16px;
      line-height: 20px; /* 125% */
    }
    & > p {
      font-size: 13px;
      line-height: 20px; /* 153.846% */
    }
  }
`;

const Divider = styled.div`
  width: 1px;
  background-color: #262626;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const HrdloAfterService = () => {
  return (
    <Container>
      <SectionTitle>사후 관리</SectionTitle>
      <ContentContainer>
        <Column>
          <ContentBlock>
            <h3>정부기관 등록 지원</h3>
            <p>국시원 : 시험 응시부터 실습보고, 자격신청용 등단 전산 시스템</p>
            <p>시도 보고 : 해당 시도청에 보고해야 할 양식 관리와 발급</p>
          </ContentBlock>
          <ContentBlock>
            <h3>교육생 기록 및 문서관리</h3>
            <p>상담 관리 : 교육원의 교육생 상담 기능</p>
            <p>수료 관리 : 교육생의 수료여부 확인 및 수료증과 이수증 발급</p>
          </ContentBlock>
        </Column>
        <Divider />
        <Column>
          <ContentBlock>
            <h3>문제은행 및 평가시스템</h3>
            <p>
              평가와 설문 : 교육생의 사전 평가부터 재평가, 설문까지의 포괄적
              관리
            </p>
            <p>문제은행(CBT) : 자격증 취득을 위한 서비스</p>
          </ContentBlock>
          <ContentBlock>
            <h3>취업 연계</h3>
            <p>이수생과 고용주를 연결하여 고용창출까지의 서비스</p>
          </ContentBlock>
        </Column>
      </ContentContainer>
    </Container>
  );
};

export default HrdloAfterService;
