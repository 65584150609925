import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #fbfbfb;
  color: #262626;
  width: 100%;
  padding: 100px 15%;
  max-width: 1920px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 100px 10%;
  }
  @media (max-width: 1024px) {
    padding: 50px 5%;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 83.333% */

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 20px;
    line-height: 20px; /* 100% */
  }
`;

const StepCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap; /* 반응형 지원 */

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const StepCard = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1;
  height: 142px;
  padding: 24px;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    min-width: unset;
    width: 100%;
  }
`;

const StepTitle = styled.h3`
  word-break: keep-all;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 150% */
`;

const StepDescription = styled.p`
  word-break: keep-all;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-wrap; /* 줄바꿈 처리 */
`;

const HrdloStep = () => {
  const steps = [
    { title: 'STEP 1', description: '광고를 통한 교육원 모집' },
    {
      title: 'STEP 2',
      description: `맞춤 교육원 커리큘럼 기반으로\n 개개인 교육원의 개강과정 안내`,
    },
    {
      title: 'STEP 3',
      description: '교육원 개별 사이트를 제작하여 교육원 홍보',
    },
  ];

  return (
    <Container>
      <Title>교육생 모집 & 교육원 홍보</Title>
      <StepCardContainer>
        {steps.map((step, index) => (
          <StepCard key={index}>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </StepCard>
        ))}
      </StepCardContainer>
    </Container>
  );
};

export default HrdloStep;
