import TopBar from '../../../components/user/topBar/TopBar';
import ServicesBanner from '../../../components/user/servicesPage/ServicesBanner';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styled from 'styled-components';
import hrdloApp from '../../../assets/images/servicesPage/hrdloServicesPage/hrdlo-app.png';
import hrdloLogo from '../../../assets/images/servicesPage/hrdloServicesPage/hrdlo-logo.png';
import HrdloCard from '../../../components/user/servicesPage/Hrdlo/HrdloCard';
import HrdloStep from '../../../components/user/servicesPage/Hrdlo/HrdloStep';
import HrdloProcess from '../../../components/user/servicesPage/Hrdlo/HrdloProcess';
import HrdloAfterService from '../../../components/user/servicesPage/Hrdlo/HrdloAfterService';
import ServicesTitleComponent from '../../../components/user/servicesPage/ServicesTitleComponent';
import FloatingButtonGroup from '../../../components/user/mainPage/FloatingButton';

const Body = styled.div`
  display: flex;
  color: #262626;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 50px;
  padding: 0 15%;
  max-width: 1920px;
  margin: 100px auto 0px;
  @media (max-width: 1440px) {
    padding: 0 10%;
  }
  @media (max-width: 1024px) {
    margin-top: 32px;
    padding: 0 5%;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1024px) {
    gap: 20px;
  }
`;
const Text = styled.p`
  word-break: keep-all;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px; /* 50% */
  br {
    display: none;
  }
  @media (max-width: 1024px) {
    font-size: 22px;
    line-height: 30px;
    br {
      display: block;
    }
  }
`;

const TextRed = styled.span`
  color: #ff0000;
  word-break: keep-all;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px; /* 150% */
  }
`;

const Desc = styled.p`
  font-size: 18px;
  word-break: keep-all;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
  & > span {
    br {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    & > span {
      br {
        display: block;
      }
    }
  }
`;
const LogoImg = styled.img`
  width: auto;
  @media (max-width: 1024px) {
    width: 152px;
  }
`;
const AppImg = styled.img`
  width: auto;
  @media (max-width: 1024px) {
    width: 230px;
  }
`;

const SectionContainer = styled.div`
  padding-top: 5px;
  display: grid;
  width: 100%;
  margin: 50px 0px;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @media (max-width: 1024px) {
    display: none; /* 모바일에서는 숨김 */
  }
`;

const MobileSwiperContainer = styled.div`
  display: none;
  width: 100%;
  margin: 20px 0px 36px;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const MobileSwiperWrapper = styled.div`
  width: 100%; /* 필요에 따라 조정 */
`;

function HrdloServicesPage() {
  const cardData = [
    {
      title: '모집 & 홍보',
      items: ['교육원 맞춤 사이트 제작', '교육원 모집 및 홍보', '접수 관리'],
    },
    {
      title: '프로세스 자동화',
      items: [
        '정부기관 제출용 출력 및 시간표',
        '국시원및 시도보고 제출용 서류 출력',
        '문자, 카카오톡, 팩스 등 발송 기능',
        '콘텐츠 엑셀 업로드',
        '시간표 모듈 생성',
        '전자 출력',
      ],
    },
    {
      title: '교육 관리',
      items: [
        '회원, 훈련, 콘텐츠, 과정 생성',
        '교육기관',
        '훈련생 등록',
        '개강 정보, 생성',
        '시간표 저장',
      ],
    },
    {
      title: '사후 관리',
      items: ['취업 연계', '상담 관리', '수료 관리', '실습 관리'],
    },
  ];
  return (
    <div>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <ServicesBanner />
      <ServicesTitleComponent title="HRDLO" />
      <Body>
        <TextContainer>
          <Text>
            평생교육의 A부터 Z까지 통합한
            <br />
            <TextRed> ERP + LMS </TextRed>
            통합 관리 솔루션
          </Text>
          <Desc>
            <span>
              LMS(학습관리 시스템)을 더욱더 간편하게 회원등록과 <br />
              개강 컨텐츠를 업로드하고 <br />
              비콘(전자 출결 시스템)으로 출결관리를 하고
            </span>
            <br />
            입력된 데이터값으로 엑셀 출력을 할 수 있는 시스템
          </Desc>
        </TextContainer>
        <LogoImg src={hrdloLogo} alt="hrdloLogo" />
        <TextContainer>
          <TextRed>비콘(전자 출결 시스템)</TextRed>
          <Desc>
            <span>
              블루투스 연동 기능으로 기존의 수기 출결을 대체하여 <br /> 간편하게
              출결 관리를 할 수 있습니다.
            </span>
          </Desc>
        </TextContainer>
        <AppImg src={hrdloApp} alt="hrdloApp" />

        {/* Section for Desktop */}
        <SectionContainer>
          {cardData.map((card, index) => (
            <HrdloCard key={index} title={card.title} items={card.items} />
          ))}
        </SectionContainer>

        {/* Section for Mobile */}
        <MobileSwiperContainer>
          <MobileSwiperWrapper>
            <Swiper spaceBetween={20} slidesPerView={1}>
              {cardData.map((card, index) => (
                <SwiperSlide key={index}>
                  <HrdloCard title={card.title} items={card.items} />
                </SwiperSlide>
              ))}
            </Swiper>
          </MobileSwiperWrapper>
        </MobileSwiperContainer>
      </Body>
      <HrdloStep />
      <HrdloProcess />
      <HrdloAfterService />
    </div>
  );
}

export default HrdloServicesPage;
