import { ImageResponse } from '../api/service/UtilsInterface';
import React, { useState } from 'react';
import styled from 'styled-components';

// ✅ 스타일 컴포넌트 정의
const ImageContainer = styled.div`
  width: 100%; // 부모 요소의 너비에 맞게 설정
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;
  padding-bottom: 100%; // 1:1 비율 유지
  aspect-ratio: 1; // 브라우저에서 aspect-ratio 속성 지원 시 강제 1:1 비율
`;

// ✅ 스타일 컴포넌트 정의
const UploadedImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain; // 이미지가 컨테이너에 맞게 자르기
`;

interface ImagePreviewProps {
  image: ImageResponse;
  alt: string;
}

// ✅ ImagePreviewItem 컴포넌트 정의
const ImagePreview: React.FC<ImagePreviewProps> = ({ image, alt }) => {
  const [isOriginalLoaded, setIsOriginalLoaded] = useState<boolean>(false); // ✅ 원본 이미지 로드 상태 관리

  // 이미지 로드 완료시 컨테이너 높이 조정
  const handleImageLoad = () => {
    setIsOriginalLoaded(true);
  };

  return (
    <ImageContainer>
      <UploadedImage
        src={image.resizedUrl} // ✅ resizedUrl 먼저 보여줌
        alt={alt}
        style={{ opacity: isOriginalLoaded ? 0 : 1 }} // ✅ original이 로드되기 전만 보임
      />
      <UploadedImage
        src={image.originalUrl} // ✅ originalUrl을 나중에 보여줌
        alt={alt}
        onLoad={handleImageLoad} // ✅ originalUrl이 로드되면 상태 변경
        style={{ opacity: isOriginalLoaded ? 1 : 0 }}
      />
    </ImageContainer>
  );
};

export default ImagePreview;
