import { BasePaginationResponse, BaseResponse } from '../UtilsInterface';
import { apiClient } from '../../apiClient';
import API_ENDPOINTS from '../../../api/endpoints/apiEndpoints';

export interface InquireListData {
  uuidInquire: string;
  inquireName?: string | null;
  phoneNumber: string;
  senderEmail: string;
  content: string;
  expectedLaunchDate?: string | null;
  regDate: string;
  inquireCompany?: string | null;
  expectedPages?: string | null;
  budget?: string | null;
  projectType?: string | null;
  currentSite?: string | null;
  referenceSite?: string | null;
  title?: string | null;
}

export interface SimpleInquireRequest {
  inquireName: string;
  phoneNumber: string;
  senderEmail: string;
  content: string;
  expectedLaunchDate: string;
}

export interface DetailedInquireRequest {
  inquireName: string;
  phoneNumber: string;
  senderEmail: string;
  content: string;
  expectedLaunchDate: string;
  inquireCompany: string;
  expectedPages: string;
  budget: string;
  projectType: string;
  currentSite: string;
  referenceSite: string;
  title: string;
}

const InquireService = {
  // 간단문의 API
  addSimpleInquire: async (data: SimpleInquireRequest) => {
    try {
      const response = await apiClient.post(
        API_ENDPOINTS.post_simple_inquire(),
        data // POST 요청의 본문으로 데이터 전송
      );
      return response.data;
    } catch (error) {
      console.error('간단문의 추가 실패:', error);
      throw error;
    }
  },

  // 상세문의 API
  addDetailedInquire: async (data: DetailedInquireRequest) => {
    try {
      const response = await apiClient.post(
        API_ENDPOINTS.post_detailed_inquire(),
        data // POST 요청의 본문으로 데이터 전송
      );
      return response.data;
    } catch (error) {
      console.error('상세문의 추가 실패:', error);
      throw error;
    }
  },

  // 페이지 조회 API
  getInquireList: async (
    page: number,
    size: number
  ): Promise<BaseResponse<BasePaginationResponse<InquireListData>>> => {
    try {
      const response = await apiClient.get(
        API_ENDPOINTS.get_inquire_list(page, size)
      );
      return response.data as BaseResponse<
        BasePaginationResponse<InquireListData>
      >;
    } catch (error) {
      console.error('문의 리스트 조회 실패:', error);
      throw error;
    }
  },
};

export default InquireService;
