import React from 'react';
import styled, { useTheme } from 'styled-components';
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;

  p {
    border: none;
    cursor: pointer;
    font-size: 1rem;
    line-height: 24px;
    margin: 0 5px;
  }
`;

const IconContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.grayColor
      : props.theme.colors.mainColor};
  color: ${(props) => props.theme.colors.whiteColor};

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? props.theme.colors.grayColor
        : props.theme.colors.hoverColor};
  }
`;

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const theme = useTheme();
  const displayPage = currentPage + 1; // displayPage를 currentPage + 1로 설정하여 1부터 시작

  if (totalPages <= 0) {
    return null;
  }

  const handleFirstPage = () => {
    if (displayPage > 1) onPageChange(0);
  };
  const handlePrevPage = () => {
    if (displayPage > 1) onPageChange(currentPage - 1);
  };
  const handleNextPage = () => {
    if (displayPage < totalPages) onPageChange(currentPage + 1);
  };
  const handleLastPage = () => {
    if (displayPage < totalPages) onPageChange(totalPages - 1);
  };
  const handlePageClick = (page: number) => onPageChange(page - 1);

  const renderPageNumbers = () => {
    const pages = [];
    let startPage = Math.max(1, displayPage - 2);
    let endPage = Math.min(totalPages, displayPage + 2);

    if (endPage - startPage < 4) {
      if (displayPage < 3) {
        endPage = Math.min(5, totalPages);
      } else {
        startPage = Math.max(totalPages - 4, 1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <p
          key={i}
          onClick={() => handlePageClick(i)}
          style={{
            fontWeight: displayPage === i ? 'bold' : 'normal',
            color:
              displayPage === i
                ? theme.colors.mainColor
                : theme.colors.textColor,
          }}
        >
          {i}
        </p>
      );
    }
    return pages;
  };

  return (
    <PaginationContainer>
      <IconContainer onClick={handleFirstPage} disabled={displayPage === 1}>
        <MdOutlineKeyboardDoubleArrowLeft />
      </IconContainer>
      <IconContainer onClick={handlePrevPage} disabled={displayPage === 1}>
        <MdOutlineKeyboardArrowLeft />
      </IconContainer>
      {renderPageNumbers()}
      <IconContainer
        onClick={handleNextPage}
        disabled={displayPage === totalPages}
      >
        <MdOutlineKeyboardArrowRight />
      </IconContainer>
      <IconContainer
        onClick={handleLastPage}
        disabled={displayPage === totalPages}
      >
        <MdOutlineKeyboardDoubleArrowRight />
      </IconContainer>
    </PaginationContainer>
  );
};

export default Pagination;
