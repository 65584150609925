import {
  ArtfleetServicesPageUrl,
  DesignServicesPageUrl,
  DevelopmentServicesPageUrl,
  HrdloServicesPageUrl,
  MarketingServicesPageUrl,
  ServerHostingServicesPageUrl,
} from '../../../url/lodongUrl';

const ServicesPath = [
  {
    name: 'HRDLMS',
    path: `${HrdloServicesPageUrl}`,
  },
  {
    name: 'ARTFLEET',
    path: `${ArtfleetServicesPageUrl}`,
  },
  {
    name: '서버호스팅',
    path: `${ServerHostingServicesPageUrl}`,
  },
  {
    name: '개발',
    path: `${DevelopmentServicesPageUrl}`,
  },
  {
    name: '디자인',
    path: `${DesignServicesPageUrl}`,
  },
  {
    name: '광고마케팅',
    path: `${MarketingServicesPageUrl}`,
  },
];

export default ServicesPath;
