import styled from 'styled-components';
import checkedImg from '../../../assets/images/inquiryPage/checked.svg';
import unchecked from '../../../assets/images/inquiryPage/unchecked.svg';

const Container = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 36px;
`;
const Lable = styled.span`
  margin-left: 8px;
  font-size: 16px;
  color: #262626;
`;

// PersonalInfoUseAgreementCheck.tsx
function PersonalInfoUseAgreementCheck({
  checked,
  onChange,
}: {
  checked: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <Container>
      <img
        src={checked ? checkedImg : unchecked}
        alt="checked"
        onClick={() => onChange(!checked)}
      />
      <Lable>개인정보 수집동의</Lable>
    </Container>
  );
}

export default PersonalInfoUseAgreementCheck;
