import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ServicesPath from './ServicesPath';
import CustomDropdown from '../../../components/CustomDropdown';
import {
  ServerHostingServicesPageUrl,
  ServerHostingServicesPricePageUrl,
} from '../../../url/lodongUrl'; // 추가

const ServiceContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  padding: 0px 5% 32px 5%;
  @media (max-width: 468px) {
    width: 100%;
  }
`;

const Label = styled.p`
  width: 100px !important;
  font-weight: 700;
  margin-right: 10px;
  white-space: nowrap; /* 줄바꿈 방지 */
`;

function ServiceMobileTabSelector() {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState(location.pathname);

  useEffect(() => {
    // "서버 호스팅 가격 페이지 URL"도 활성화 상태로 간주
    if (location.pathname === ServerHostingServicesPricePageUrl) {
      setSelectedValue(`${ServerHostingServicesPageUrl}`); // 기본 서버 호스팅 경로로 설정
    } else {
      setSelectedValue(location.pathname); // URL 변경 시 드롭다운 값 업데이트
    }
  }, [location.pathname]);

  const handleSelectChange = (value: string) => {
    setSelectedValue(value); // 선택된 값 업데이트
    navigate(value); // 새로운 경로로 이동
  };

  const options = ServicesPath.map((service) => ({
    key: service.path,
    value: service.name,
    label: service.name,
  }));

  return (
    <ServiceContainer>
      <Label>서비스 선택</Label>
      <CustomDropdown
        options={options}
        selected={selectedValue} // 현재 선택된 경로
        onSelect={handleSelectChange} // 선택 변경 시 처리 함수
      />
    </ServiceContainer>
  );
}

export default ServiceMobileTabSelector;
