import styled from 'styled-components';
import chevron_down from '../../../assets/images/inquiryPage/chevron_down.svg';

const Container = styled.div`
  padding: 36px;
`;
const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  margin: auto;
`;
const Title = styled.span`
  color: #262626;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 83.333% */

  @media (max-width: 1024px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }
`;
const Img = styled.img`
  width: 30px;

  @media (max-width: 1024px) {
    width: 20px;
  }
`;

function InquiryTypeSelector({
  isSimple,
  onClick,
}: {
  isSimple: boolean;
  onClick: () => void;
}) {
  return (
    <Container>
      <Header onClick={onClick}>
        <Title>{isSimple ? '간단' : '상세'} 견적 문의</Title>
        <Img
          src={chevron_down}
          alt="more/less"
          style={{
            transform: isSimple ? 'none' : 'scaleY(-1)',
          }}
        ></Img>
      </Header>
    </Container>
  );
}

export default InquiryTypeSelector;
