import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ServicesPath from './ServicesPath';
import {
  ServerHostingServicesPageUrl,
  ServerHostingServicesPricePageUrl,
} from '../../../url/lodongUrl'; // 가격 페이지 URL 가져오기

const TabContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem 15%;
  max-width: 1920px;
  margin: 0 auto;
  flex-wrap: wrap;
  @media (max-width: 1440px) {
    padding: 1rem 10%;
  }
`;

interface TabButtonProps {
  $active: boolean;
}

const TabButton = styled.button<TabButtonProps>`
  padding: 8px 36px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  color: ${(props) => (props.$active ? 'white' : 'black')};
  background: ${(props) => (props.$active ? '#26262680' : 'none')};
  border: ${(props) => (props.$active ? 'none' : '1px solid #26262633')};
`;

function ServiceDesktopTabSelector() {
  const location = useLocation();

  return (
    <TabContainer>
      {ServicesPath.map((service) => (
        <Link key={service.path} to={service.path}>
          <TabButton
            $active={
              location.pathname === service.path || // 기본 경로와 일치
              (service.path === ServerHostingServicesPageUrl && // 서버 호스팅 탭
                location.pathname === ServerHostingServicesPricePageUrl) // 가격 페이지 경로도 포함
            }
          >
            {service.name}
          </TabButton>
        </Link>
      ))}
    </TabContainer>
  );
}

export default ServiceDesktopTabSelector;
