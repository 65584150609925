import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../../assets/images/logo.png';
import AuthService from '../../api/service/auth/AuthService';
import { AdminInquiryPageUrl } from '../../url/lodongUrl';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

const Logo = styled.img`
  max-width: 250px;
  width: 100%;
  margin-bottom: 20px;
`;

const Form = styled.form`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Input = styled.input`
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  color: #000;

  &:focus {
    outline: none;
    border-color: #ff0000;
  }
`;

const Button = styled.button`
  width: 100%;
  max-width: 300px;
  padding: 10px;
  background-color: #ff0000;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e60000;
  }
`;

const Footer = styled.div`
  word-break: keep-all;
  margin-top: 40px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 12px;
`;

const AdminLoginPage = () => {
  const [loginData, setLoginData] = useState({ identifier: '', password: '' }); // identifier로 변경
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // 기본 폼 제출 방지

    try {
      // AuthService를 통해 로그인 요청
      const response = await AuthService.login(loginData);
      console.log('로그인 성공:', response);

      const { accessToken, permissions, name, identifier } = response.data;

      console.log('로그인 응답 데이터:', response.data);

      // 토큰 및 기타 정보 로컬 스토리지에 저장
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
      }

      if (permissions) {
        localStorage.setItem('permissions', JSON.stringify(permissions)); // 배열로 저장
      }
      if (name) {
        localStorage.setItem('name', name);
      }
      if (identifier) {
        localStorage.setItem('identifier', identifier);
      }

      navigate(AdminInquiryPageUrl);
    } catch (err) {
      console.error('로그인 실패:', err);
      alert(err);
    }
  };

  return (
    <Container>
      <Logo src={logo} alt="Logo" />

      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="identifier"
          placeholder="아이디"
          value={loginData.identifier}
          onChange={handleChange}
        />
        <Input
          type="password"
          name="password"
          placeholder="비밀번호"
          value={loginData.password}
          onChange={handleChange}
        />
        <Button type="submit">관리자 로그인</Button>
      </Form>

      <Footer>copyright © LODONG Corp. All Rights Reserved.</Footer>
    </Container>
  );
};

export default AdminLoginPage;
