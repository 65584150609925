import React from 'react';
import styled from 'styled-components';
import DevelopmentServicePC from '../../../../assets/images/servicesPage/developmentServicesPage/DevelopmentServicePC.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;

const Wrapper = styled.div`
  display: none;
  @media (max-width: 1024px) {
    overflow-x: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
`;

const CircleContainer = styled.div<{ borderColor: string }>`
  border-radius: 50%;
  border: 8px dashed ${(props) => props.borderColor};
  padding: 20px;
  min-width: 300px;
  max-width: 500px;
  width: 90%;
  aspect-ratio: 1; /* 높이를 너비와 동일하게 유지 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  text-align: center;
  margin: 10px;
  box-sizing: border-box; /* padding 포함 크기 보장 */
`;

const Title = styled.h3`
  white-space: pre-line;
  word-break: keep-all;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
`;
const Line = styled.div`
  background-color: #daa1a1;
  width: 30px;
  height: 1px;
`;
const Description = styled.p`
  white-space: pre-line;
  word-break: keep-all;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
`;

const Img = styled.img`
  max-width: 1200px;
  width: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const CircleSection = ({
  title,
  description,
  borderColor,
}: {
  title: string;
  description: string;
  borderColor: string;
}) => (
  <CircleContainer borderColor={borderColor}>
    <Title>{title}</Title>
    <Line />
    <Description>{description}</Description>
  </CircleContainer>
);

const DevelopmentService = () => {
  const sections = [
    {
      title: '경험 많은 개발 전문가',
      description: `경력 5년 이상의 개발 전문가들이\n 프로젝트에 투입되어 높은 품질의 \n결과물을 보장합니다.`,
    },
    {
      title: '고객 맞춤형 솔루션 제공',
      description: `고객의 니즈에 맞춘 100% \n커스터마이징 개발로 기존 \n시스템과의 통합부터 전용 기능 \n개발까지 모두 지원합니다.`,
    },
    {
      title: `최신 기술 스택 활용 및\n 모든 언어 지원`,
      description: `React, Vue, Node.js, Kotlin, Swift,\n Spring Boot 등 최신 기술 스택을 활용하며\n모든 개발 언어를 지원하여 고객의 요구에\n최적화된 솔루션을 제공합니다.`,
    },
    {
      title: '신속한 대응과 유지보수',
      description: `프로젝트 완료 후에도 신속한 피드백과\n 유지보수를 통해 고객의 운영 중단을 \n최소화합니다.`,
    },
    {
      title: '프로세스 투명성 및 소통 강화',
      description: `고객과의 정기 미팅 및 진행 상황 공유를 \n통해 개발 과정의 모든 단계를 투명하게 \n관리합니다.`,
    },
  ];

  // 각 index에 따라 다른 색상을 정의
  const borderColors = [
    'rgba(218, 161, 161, 1)', // 첫 번째
    'rgba(218, 161, 161, 0.8)', // 첫 번째
    'rgba(218, 161, 161, 0.6)', // 첫 번째
    'rgba(218, 161, 161, 0.4)', // 첫 번째
    'rgba(218, 161, 161, 0.2)', // 첫 번째
  ];

  return (
    <Container>
      <Wrapper>
        <Swiper
          spaceBetween={20}
          slidesPerView={1.1} // 기본 값
        >
          {sections.map((section, index) => (
            <SwiperSlide key={index}>
              <CircleSection
                title={section.title}
                description={section.description}
                borderColor={borderColors[index % borderColors.length]} // 색상 순환
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Wrapper>

      <Img src={DevelopmentServicePC} alt="DevelopmentServicePC" />
    </Container>
  );
};

export default DevelopmentService;
