import React, { useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import FloatingButtonGroup from '../../components/user/mainPage/FloatingButton';
import TextShow from '../../components/user/mainPage/TextShow';
import KeyTechnology from '../../components/user/mainPage/KeyTechnology';
import { Introduction } from '../../components/user/mainPage/introduction';
import ContactUs from '../../components/user/mainPage/ContactUs';
import Business from '../../components/user/mainPage/Business';
import MainPortfolio from '../../components/user/mainPage/MainPortfolio';
import Footer from '../../components/Footer';
import TopBar from '../../components/user/topBar/TopBar';

// slideDown 애니메이션 keyframes 정의
const slideDownKeyframes = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
`;
const slideDownKeyframesM = keyframes`
  from {
    transform: translateY(-60px);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDownAnimation = css`
  animation: ${slideDownKeyframes} 0.3s ease-out forwards;
  @media (max-width: 1024px) {
    animation: ${slideDownKeyframesM} 0.3s ease-out forwards;
  }
`;

const SlideDownContainer = styled.div<{ slide: boolean }>`
  position: relative;
  background: rgb(32, 32, 32);
  overflow: hidden;
  ${(props) => props.slide && slideDownAnimation}

  @media (max-width: 1024px) {
    ${(props) => props.slide && slideDownKeyframesM}
  }
`;

// 새로운 고정 TopBar (슬라이드 후 화면 상단에 고정)
const FixedTopBar = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #202020;
`;

function MainPage() {
  const [isShowPlaying, setIsShowPlaying] = useState(true);
  const [slide, setSlide] = useState(false);
  // slide 애니메이션 종료 후에 true가 되면 새로운 TopBar를 렌더링
  const [slideEnded, setSlideEnded] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleShowEnd = () => {
    // TextShow 애니메이션이 끝나면 실행됨.
    setIsShowPlaying(false);
    // 슬라이드 애니메이션 시작
    setSlide(true);
    // 애니메이션 지속시간(300ms) 이후에 slideEnded를 true로 변경하여 새로운 TopBar 렌더링
    setTimeout(() => {
      setSlideEnded(true);
    }, 300);
  };

  return (
    <div ref={scrollRef}>
      <div
        style={{
          opacity: isShowPlaying ? 0 : 1,
          transition: 'opacity 0.3s ease-out',
        }}
      >
        <FloatingButtonGroup />
      </div>

      <SlideDownContainer slide={slide}>
        {/* 
          슬라이드 애니메이션 중에는 기존 TopBar를 보이게 하고, 
          slideEnded가 true가 되면 z-index를 -1로 내려 뒤로 보내기 
        */}
        <div style={{ zIndex: slideEnded ? -1 : 100 }}>
          <TopBar isShowPlaying={isShowPlaying} variant="dark" />
        </div>
        <div
          style={{
            height: '100dvh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextShow onShowEnd={handleShowEnd} />
        </div>
      </SlideDownContainer>

      {/* slideEnded가 true이면 새로운 고정 TopBar 렌더링 */}
      {slideEnded && (
        <FixedTopBar>
          <TopBar isShowPlaying={isShowPlaying} variant="dark" />
        </FixedTopBar>
      )}

      <div
        style={{
          maxHeight: isShowPlaying ? 0 : '10000dvh',
          overflow: 'clip',
          transition: 'all 1s',
        }}
      >
        <KeyTechnology />
        <Introduction />
        <MainPortfolio />
        <Business />
        <ContactUs />
        <div
          style={{
            opacity: isShowPlaying ? 0 : 1,
          }}
        >
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default MainPage;
