import AuthEndpoints from './auth/AuthEndpoints';
import CategoryEndpoints from './user/CategoryEndpoints';
import AdminPortfolioEndpoints from './admin/AdminPortfolioEndpoints';
import PortfolioEndpoints from './user/PortfolioEndpoints';
import InquireEndpoints from './user/InquireEndpoints';
// 다른 엔드포인트 파일들을 import

const API_ENDPOINTS = {
  ...AuthEndpoints,
  ...CategoryEndpoints,
  ...AdminPortfolioEndpoints,
  ...PortfolioEndpoints,
  ...InquireEndpoints,
};

export default API_ENDPOINTS;
