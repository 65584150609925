import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownButton = styled.div<{ $isOpen: boolean }>`
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-color: #aaa;
  }
`;

const DropdownList = styled.ul<{ $isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: ${({ $isOpen }) => ($isOpen ? '200px' : '0')};
  overflow-y: auto;
  padding: ${({ $isOpen }) => ($isOpen ? '10px 0' : '0')};
  margin: 0;
  border: ${({ $isOpen }) => ($isOpen ? '1px solid #ddd' : 'none')};
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  transition: all 0.3s ease;
  z-index: 10;
`;

const DropdownItem = styled.li`
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const IoIosArrowUpIcon = styled(IoIosArrowUp)`
  font-size: 24px;
  color: #8b8b8b;
`;

const IoIosArrowDownIcon = styled(IoIosArrowDown)`
  font-size: 24px;
  color: #8b8b8b;
`;

interface Option {
  key: string;
  value: string;
}

interface DropdownProps {
  options: Option[];
  selected?: string; // 선택되지 않은 경우 기본값 설정을 위해 선택적 필드로 변경
  onSelect: (value: string) => void;
}

const CustomDropdown: React.FC<DropdownProps> = ({
  options,
  selected,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentSelection, setCurrentSelection] = useState<string>(
    selected || options[0]?.key // 첫 번째 옵션의 키를 기본값으로 설정
  );

  useEffect(() => {
    if (selected) {
      setCurrentSelection(selected);
    }
  }, [selected]);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (value: string) => {
    setCurrentSelection(value);
    onSelect(value);
    setIsOpen(false);
  };

  const selectedOptionLabel =
    options.find((option) => option.key === currentSelection)?.value ||
    options[0]?.value; // 기본값은 첫 번째 옵션

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown} $isOpen={isOpen}>
        {selectedOptionLabel}
        {isOpen ? <IoIosArrowUpIcon /> : <IoIosArrowDownIcon />}
      </DropdownButton>
      <DropdownList $isOpen={isOpen}>
        {options.map((option) => (
          <DropdownItem
            key={option.key}
            onClick={() => handleSelect(option.key)}
          >
            {option.value}
          </DropdownItem>
        ))}
      </DropdownList>
    </DropdownContainer>
  );
};

export default CustomDropdown;
