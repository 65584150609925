import { useState } from 'react';
import styled from 'styled-components';
import Logo from './Logo';

import MobileMenuIcon from './MobileMenuIcon';
import MobileMenuDrawer from './MobileMenuDrawer';

const MobileContainer = styled.div<{ variant: 'light' | 'dark' }>`
  background-color: ${(props) =>
    props.variant === 'dark' ? 'rgb(32,32,32)' : 'white'};
  justify-content: center;
  height: 60px;
  display: flex;
  position: relative;
  overflow-x: hidden;
`;

function MobileTopBar({ variant = 'dark' }: { variant?: 'light' | 'dark' }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <MobileContainer variant={variant}>
      <Logo />

      <MobileMenuIcon toggleMenu={toggleMenu} />
      <MobileMenuDrawer isOpen={isMenuOpen} closeMenu={closeMenu} />
    </MobileContainer>
  );
}

export default MobileTopBar;
