import styled from 'styled-components';

const SubmenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 32px; /* 들여쓰기 효과 */
  font-family: Pretendard, sans-serif;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const SubmenuText = styled.span`
  font-weight: normal;
`;

function MobileTopBarSubmenu({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) {
  return (
    <SubmenuItem onClick={onClick}>
      <SubmenuText>{text}</SubmenuText>
    </SubmenuItem>
  );
}

export default MobileTopBarSubmenu;
