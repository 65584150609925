import React from 'react';
import styled from 'styled-components';
import DL360_GEN8 from '../../../../../assets/images/servicesPage/serverHostingServicesPage/serverHostingServicesPriceTab1/DL360_GEN8.png';
import DL380_GEN8 from '../../../../../assets/images/servicesPage/serverHostingServicesPage/serverHostingServicesPriceTab1/DL380_GEN8.png';
import DL360_GEN9 from '../../../../../assets/images/servicesPage/serverHostingServicesPage/serverHostingServicesPriceTab1/DL360_GEN9.png';
import DL380_GEN9 from '../../../../../assets/images/servicesPage/serverHostingServicesPage/serverHostingServicesPriceTab1/DL380_GEN9.png';
import DL360_GEN10 from '../../../../../assets/images/servicesPage/serverHostingServicesPage/serverHostingServicesPriceTab1/DL360_GEN10.png';
import DL380_GEN10 from '../../../../../assets/images/servicesPage/serverHostingServicesPage/serverHostingServicesPriceTab1/DL380_GEN10.png';
import Poweredge_R720xd from '../../../../../assets/images/servicesPage/serverHostingServicesPage/serverHostingServicesPriceTab1/Poweredge_R720xd.png';
import Poweredge_R730xd from '../../../../../assets/images/servicesPage/serverHostingServicesPage/serverHostingServicesPriceTab1/Poweredge_R730xd.png';

const Container = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 760px) {
    margin-top: 30px;
  }
`;
const Title = styled.h1`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 66.667% */
  @media (max-width: 760px) {
    font-size: 18px;
    line-height: 18px; /* 111.111% */
  }
`;
const SubTitle = styled.h1`
  word-break: keep-all;
  text-align: center;
  margin-top: 30px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
  @media (max-width: 760px) {
    margin-top: 10px;
    font-size: 13px;
    line-height: 13px; /* 153.846% */
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  padding: 70px 0px 40px;
  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 760px) {
    padding: 25px 0px 30px;
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled.div`
  border-radius: 15px;
  border: 5px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 25px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  flex: 1;
  @media (max-width: 760px) {
    padding: 20px;
    gap: 20px;
  }
`;
const Img = styled.img`
  width: 100%;
  height: 57px;
  max-width: 300px;
`;

const CardTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 83.333% */
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.05);
`;
const DescContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Desc = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
`;

const DescTitle = styled.h1`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;

const DescText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
`;

const Price = styled.p`
  color: #f00;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;

const Button = styled.button`
  margin: 20px 0px;
  width: 100%;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  &:hover {
    background-color: #1f1f1f;
  }

  @media (max-width: 760px) {
    margin: 10px 0px;
  }
`;

const AdditionalServicesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  max-width: 550px;
  border: 5px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 15px;
`;
const AdditionalServicesTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 83.333% */
  padding-bottom: 30px;
`;

const AdditionalTitleContainer = styled.div`
  width: 100%;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const AdditionalLine = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

const AdditionalLineTitle = styled.h1`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;
const AdditionalLineDesc = styled.p`
  color: #f00;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;
const VAT = styled.p`
  width: 100%;
  text-align: right;
  margin-top: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 85.714% */
`;

function ServerHostingServicesPriceTab1() {
  const servers = [
    {
      imgUrl: `${DL360_GEN8}`,
      name: 'DL360 GEN8',
      core: '8Core R16',
      ram: '32GB',
      storage: '1T SATA',
      price: '150,000원',
    },
    {
      imgUrl: `${DL380_GEN8}`,
      name: 'DL380 GEN8',
      core: '8Core R16',
      ram: '32GB',
      storage: '1T SATA',
      price: '170,000원',
    },
    {
      imgUrl: `${DL360_GEN9}`,
      name: 'DL360 GEN9',
      core: '8Core R16',
      ram: '32GB',
      storage: '1T SATA',
      price: '200,000원',
    },
    {
      imgUrl: `${DL380_GEN9}`,
      name: 'DL380 GEN9',
      core: '8Core R16',
      ram: '32GB',
      storage: '1T SATA',
      price: '220,000원',
    },
    {
      imgUrl: `${DL360_GEN10}`,
      name: 'DL360 GEN10',
      core: '8Core R16',
      ram: '32GB',
      storage: '1T SATA',
      price: '280,000원',
    },
    {
      imgUrl: `${DL380_GEN10}`,
      name: 'DL380 GEN10',
      core: '8Core R16',
      ram: '32GB',
      storage: '1T SATA',
      price: '300,000원',
    },
    {
      imgUrl: `${Poweredge_R720xd}`,
      name: 'Poweredge R720xd',
      core: '8Core R16',
      ram: '16GB',
      storage: '1T SATA',
      price: '160,000원',
    },
    {
      imgUrl: `${Poweredge_R730xd}`,
      name: 'Poweredge R730xd',
      core: '8Core R16',
      ram: '32GB',
      storage: '1T SATA',
      price: '220,000원',
    },
  ];

  const services = [
    { name: 'SATA(HDD) 500GB', price: '20,000원' },
    { name: 'SSD 500GB', price: '25,000원' },
    { name: 'RAM 8GB', price: '15,000원' },
    { name: '모니터링 서비스', price: '20,000원' },
    { name: '방화벽 서비스', price: '10,000원' },
    { name: '복구 서비스', price: '20,000원' },
  ];
  return (
    <Container>
      <Title>서버 호스팅</Title>
      <SubTitle>
        국내 프리미엄 회선과 해외 가속 회선으로 제공되는 서버 호스팅
      </SubTitle>
      <CardContainer>
        {servers.map((server, index) => (
          <Card key={index}>
            <Img src={server.imgUrl} alt={server.name} />
            <CardTitle>{server.name}</CardTitle>
            <Line />
            <DescContainer>
              <Desc>
                <DescTitle>Core: </DescTitle>
                <DescText>{server.core}</DescText>
              </Desc>
              <Desc>
                <DescTitle>RAM: </DescTitle>
                <DescText>{server.ram}</DescText>
              </Desc>
              <Desc>
                <DescTitle>Storage: </DescTitle>
                <DescText>{server.storage}</DescText>
              </Desc>
              <Desc>
                <DescTitle>이용료:</DescTitle>
                <Price>{server.price}</Price>
              </Desc>
            </DescContainer>
            <Button onClick={() => alert(`${server.name} 문의하기 클릭!`)}>
              문의하기
            </Button>
          </Card>
        ))}
      </CardContainer>

      <AdditionalServicesContainer>
        <AdditionalServicesTitle>추가 부가서비스 안내</AdditionalServicesTitle>
        <Line />
        <AdditionalTitleContainer>
          {services.map((service, index) => (
            <AdditionalLine key={index}>
              <AdditionalLineTitle>{service.name}</AdditionalLineTitle>
              <AdditionalLineDesc>{service.price}</AdditionalLineDesc>
            </AdditionalLine>
          ))}
        </AdditionalTitleContainer>
      </AdditionalServicesContainer>

      <VAT>*(VAT 별도)</VAT>
    </Container>
  );
}

export default ServerHostingServicesPriceTab1;
