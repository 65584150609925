import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 24px;
    margin-top: 0px;
  }
`;

const Title = styled.h1`
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

const ServicesTitleComponent = ({ title }: { title: string }) => {
  return (
    <Container>
      <Title>{title}</Title>
    </Container>
  );
};

export default ServicesTitleComponent;
