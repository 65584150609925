import React from 'react';
import styled from 'styled-components';
import plus from '../../../assets/images/mainPage/plus.svg';

interface KeyTechnologyCardProps {
  title: string;
  description: string;
  iconPath: string;
  iconHoverPath: string; // hover용 아이콘 이미지 경로 추가
  backgroundImage: string;
  link: string; // 추가된 링크 속성
}

const Title = styled.p`
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 12px;
  transition: color 0.3s;
`;

const Description = styled.p`
  min-height: 60px;
  font-size: 16px;
  white-space: pre-line;
  word-break: keep-all;
  line-height: 20px;
  transition: color 0.3s;
`;

const FooterText = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: small;
  transition: color 0.3s;
  text-decoration: none;
  color: inherit; /* 부모의 색상을 유지 */

  &:hover {
    text-decoration: underline;
  }

  span {
    margin-right: 8px;
  }
`;

const IconWrapper = styled.div`
  margin-left: auto;
  position: relative;
  width: 96px; /* 원하는 크기로 조정 */
  height: 96px; /* 원하는 크기로 조정 */
`;

const IconDefault = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
  opacity: 1;
`;

const IconHoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
  opacity: 0;
`;

const Card = styled.div<{ backgroundImage: string }>`
  border-radius: 10px;
  border: 1px solid #2626261a;
  padding: 24px;
  position: relative;
  background-color: #fff;
  color: #262626;
  overflow: hidden;
  transition:
    background 0.5s ease-in-out,
    color 0.3s ease-in-out;

  &:hover {
    background: url(${(props) => props.backgroundImage}) no-repeat center/cover;
    color: white;
  }

  /* 어둡게 처리되는 오버레이 추가 */
  &:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  /* 자식 요소들을 오버레이 위에 표시 */
  & > * {
    position: relative;
    z-index: 2;
  }

  &:hover ${Title}, &:hover ${Description}, &:hover ${FooterText} {
    color: white;
  }

  /* hover 시 기본 아이콘 숨기고, hover 아이콘 보이기 */
  &:hover ${IconDefault} {
    opacity: 0;
  }

  &:hover ${IconHoverImage} {
    opacity: 1;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const KeyTechnologyCard: React.FC<KeyTechnologyCardProps> = ({
  title,
  description,
  iconPath,
  iconHoverPath,
  backgroundImage,
  link,
}) => {
  return (
    <Card backgroundImage={backgroundImage}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Footer>
        <FooterText href={link} target="_blank" rel="noopener noreferrer">
          <span>자세히 보기</span>
          <img src={plus} alt="plus icon" />
        </FooterText>
        <IconWrapper>
          <IconDefault src={iconPath} alt={`${title} icon`} />
          <IconHoverImage src={iconHoverPath} alt={`${title} icon hover`} />
        </IconWrapper>
      </Footer>
    </Card>
  );
};

export default KeyTechnologyCard;
