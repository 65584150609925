import 'swiper/css';
import styled, { keyframes } from 'styled-components';

// 이미지 import
import stock_image from '../../../assets/images/mainPage/stock_image.jpg';
import Deep_Insight from '../../../assets/images/mainPage/business/Deep_Insight.png';
import GyeonggiProvincialOfficeOfEducation from '../../../assets/images/mainPage/business/GyeonggiProvincialOfficeOfEducation.png';
import HyeongjeCommunity from '../../../assets/images/mainPage/business/HyeongjeCommunity.png';
import HyodongAviationCompany from '../../../assets/images/mainPage/business/HyodongAviationCompany.png';
import Intel from '../../../assets/images/mainPage/business/Intel.png';
import KAIST from '../../../assets/images/mainPage/business/KAIST.png';
import KEFLAVIK from '../../../assets/images/mainPage/business/KEFLAVIK.png';
import KOSME from '../../../assets/images/mainPage/business/KOSME.png';
import KoreaElectricPowerCorporation from '../../../assets/images/mainPage/business/KoreaElectricPowerCorporation.png';
import MicroTechSystem from '../../../assets/images/mainPage/business/MicroTechSystem.png';
import MokpoNationalUniversity from '../../../assets/images/mainPage/business/MokpoNationalUniversity.png';
import NationalEcologicalInstitute from '../../../assets/images/mainPage/business/NationalEcologicalInstitute.png';
import Nongshim from '../../../assets/images/mainPage/business/Nongshim.png';
import RUSPORTS from '../../../assets/images/mainPage/business/RUSPORTS.png';
import SangmyungUniversity from '../../../assets/images/mainPage/business/SangmyungUniversity.png';
import SeoulInstitute from '../../../assets/images/mainPage/business/SeoulInstitute.png';
import YoonBongGilMemorialHall from '../../../assets/images/mainPage/business/YoonBongGilMemorialHall.png';
import extenb from '../../../assets/images/mainPage/business/extenb.png';

// 마퀴 애니메이션 정의
const marquee = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

// 스타일 컴포넌트 정의
const Container = styled.div`
  position: relative;
  height: 480px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    height: 200px;
  }
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: top;
  filter: brightness(0.2);
  z-index: -1;
`;

const TitleContainer = styled.div`
  padding: 0 24px;
  text-align: center;
`;

const Title = styled.p`
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
`;

const Subtitle = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  word-break: keep-all;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

const MarqueeContainer = styled.div`
  width: 100%;
  margin-top: 140px;
  overflow-x: hidden;
  @media (max-width: 1024px) {
    margin-top: 30px;
  }
`;

const Marquee = styled.div`
  animation: ${marquee} 20s linear infinite;
  gap: 48px;
  display: flex;
  width: fit-content;
  padding-right: 48px;
`;

const PartnerImage = styled.img`
  height: 100px;
  width: 200px;
  object-fit: contain;
  @media (max-width: 1024px) {
    width: 100px;
    height: auto;
  }
`;

export default function Business() {
  // 이미지 배열
  const partnerImages = [
    Deep_Insight,
    GyeonggiProvincialOfficeOfEducation,
    HyeongjeCommunity,
    HyodongAviationCompany,
    Intel,
    KAIST,
    KEFLAVIK,
    KOSME,
    KoreaElectricPowerCorporation,
    MicroTechSystem,
    MokpoNationalUniversity,
    NationalEcologicalInstitute,
    Nongshim,
    RUSPORTS,
    SangmyungUniversity,
    SeoulInstitute,
    YoonBongGilMemorialHall,
    extenb,
  ];

  return (
    <Container>
      <BackgroundImage src={stock_image} alt="stock" />
      <TitleContainer>
        <Title>BUSINESS PARTNER</Title>
        <Subtitle>
          수많은 경험을 바탕으로 신뢰를 드리는 로동이 되겠습니다.
        </Subtitle>
      </TitleContainer>
      <MarqueeContainer>
        <Marquee>
          {partnerImages.concat(partnerImages).map((image, index) => (
            <PartnerImage key={index} src={image} alt={`partner-${index}`} />
          ))}
        </Marquee>
      </MarqueeContainer>
    </Container>
  );
}
