import React from 'react';
import styled from 'styled-components';
import hrdlo_process from '../../../../assets/images/servicesPage/hrdloServicesPage/hrdlo_process_pc.png';
import hrdlo_process_m from '../../../../assets/images/servicesPage/hrdloServicesPage/hrdlo_process_m.png';

const Container = styled.div`
  background-color: #fff;
  color: #262626;
  width: 100%;
  padding: 100px 15%;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden; /* 넘치는 콘텐츠를 숨김 */
  @media (max-width: 1440px) {
    padding: 100px 10%;
  }
  @media (max-width: 1024px) {
    padding: 50px 5%;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 83.333% */

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 20px;
    line-height: 20px; /* 100% */
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%; /* 부모 컨테이너의 너비를 넘지 않도록 제한 */
  margin: 80px auto 0 auto; /* 수평 중앙 정렬 및 위쪽 여백 */
  height: auto;
  display: block; /* 이미지를 블록 요소로 설정하여 중앙 정렬 문제 방지 */
  content: url(${hrdlo_process});

  /* 모바일 환경에서만 보여줌 */
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 500px; /* 부모 컨테이너의 너비를 넘지 않도록 제한 */
    margin: 42px auto 0 auto; /* 수평 중앙 정렬 및 위쪽 여백 */
    content: url(${hrdlo_process_m});
  }
`;

const HrdloProcess = () => {
  return (
    <Container>
      <Title>HRDLO PROCESS</Title>
      <ResponsiveImage alt="HRDLO Process" />
    </Container>
  );
};

export default HrdloProcess;
