import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import Header from './Header';

const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
`;

const MainContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 300px); // Sidebar의 너비를 뺀 나머지 공간을 차지
  @media (max-width: 968px) {
    width: 100%; // 모바일에서는 전체 너비 사용
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  background-color: #ededed;
  padding: 20px;

  overflow-y: auto;
  @media (max-width: 968px) {
    padding: 10px;
  }
`;

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 968) {
        setIsSidebarOpen(true); // 데스크탑에서는 항상 사이드바를 열어둠
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // 초기 로드 시 화면 크기를 확인

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <LayoutContainer>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <MainContent>
        <Header toggleSidebar={toggleSidebar} />{' '}
        <ContentWrapper>{children}</ContentWrapper>
      </MainContent>
    </LayoutContainer>
  );
};

export default Layout;
