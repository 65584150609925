import { apiClient } from '../../../api/apiClient';
import API_ENDPOINTS from '../../../api/endpoints/apiEndpoints';
import { ImageResponse } from '../UtilsInterface';

interface PortfolioData {
  categoryId: number;
  companyName: string;
  siteName: string;
  siteUrl: string;
  simpleDescription: string;
  tags: string[];
  portfolioImgUuid: string;
  openDate: string;
  thumbnailUuid: string;
  companyId: string;
}

interface ImageData {
  uuidBaseImage: string;
  baseImageUrlVo: ImageResponse;
}

const AdminPortfolioService = {
  // 포트폴리오 등록
  createPortfolio: async (portfolioData: PortfolioData) => {
    const formData = new FormData();
    const requestDto = portfolioData;

    formData.append('requestDto', JSON.stringify(requestDto));

    try {
      // API 요청
      const response = await apiClient.post(
        API_ENDPOINTS.create_portfolio(),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // 명시적으로 Content-Type 설정
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error('포트폴리오 등록 실패:', error);
      throw error;
    }
  },

  // 포트폴리오 수정
  updatePortfolio: async (
    idPortfolio: number,
    portfolioData: PortfolioData
  ) => {
    const formData = new FormData();
    const portfolioUpdateDto = portfolioData;

    formData.append('portfolioUpdateDto', JSON.stringify(portfolioUpdateDto));

    try {
      const response = await apiClient.put(
        API_ENDPOINTS.update_portfolio(idPortfolio),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // 명시적으로 Content-Type 설정
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('포트폴리오 수정 실패:', error);
      throw error;
    }
  },

  // 포트폴리오 삭제
  deletePortfolio: async (idPortfolio: number) => {
    try {
      const response = await apiClient.delete(
        API_ENDPOINTS.delete_portfolio(idPortfolio)
      );
      return response.data;
    } catch (error) {
      console.error('포트폴리오 삭제 실패:', error);
      throw error;
    }
  },

  // 이미지 업로드
  uploadThumbnail: async (file: File): Promise<ImageData> => {
    try {
      const formData = new FormData();
      formData.append('image', file);

      const response = await apiClient.post(
        API_ENDPOINTS.upload_thumbnail(),
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      console.log(response.data.data);
      return response.data.data as ImageData;
    } catch (error) {
      console.error('썸네일 업로드 실패:', error);
      throw error;
    }
  },

  // 다중 이미지 삭제
  deleteImages: async (imageIds: string[]) => {
    try {
      const response = await apiClient.request({
        method: 'DELETE',
        url: API_ENDPOINTS.delete_images(),
        data: imageIds, // 배열 자체를 본문으로 전송
      });
      return response.data;
    } catch (error) {
      console.error('다중 이미지 삭제 실패:', error);
      throw error;
    }
  },
};

export default AdminPortfolioService;
