import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  width: 100%;
  height: 372px;
  border-radius: 30px;
  border: 5px solid #f1f1f1;
  background: #fff;
  @media (max-width: 1024px) {
    height: 274px;
  }
`;

const CardTitle = styled.h3`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px 24px 24px 0px;
  background: #f1f1f1;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  @media (max-width: 1024px) {
    border-radius: 10px 10px 10px 0px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px; /* 75% */
  }
`;

const CardContent = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 31px 10px;
  li {
    word-break: keep-all;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    font-style: normal;
    gap: 15px;
    font-weight: 500;
    line-height: 14px;
    padding: 20px 10px;
  }
`;

interface CardProps {
  title: string;
  items: string[];
}

const HrdloCard: React.FC<CardProps> = ({ title, items }) => (
  <CardContainer>
    <CardTitle>{title}</CardTitle>
    <CardContent>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </CardContent>
  </CardContainer>
);

export default HrdloCard;
