import styled from 'styled-components';
import logo from '../assets/images/lodong_logo.png';
import { InquiryPageUrl } from '../url/lodongUrl';

const Container = styled.div`
  width: 100%;
  border-top: 1px solid #fff;
  background-color: #262626;
`;

const Box = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0px auto;
  padding: 50px 15%;
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 1440px) {
    padding: 50px 10%;
  }
  @media (max-width: 1024px) {
    padding: 20px 5%;
  }
`;

const TextDiv = styled.div`
  gap: 36px;
  display: flex;
  flex-direction: column;
  height: auto;

  @media (max-width: 1024px) {
    font-size: smaller;
    padding: 10px 0px;
  }
`;

const FlexDirectionColumnOnMobile = styled.div`
  display: flex;
  gap: 34px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 8px;
  }
`;

// 링크 스타일을 지정한 컴포넌트
const LinkSpan = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const NotOnMobile = styled.img`
  width: 100px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

function Footer() {
  return (
    <Container>
      <Box>
        <NotOnMobile src={logo} alt="lodong" />
        <TextDiv>
          <FlexDirectionColumnOnMobile>
            <span>이용약관</span>
            <span>개인정보처리방침</span>
            {/* 여기를 링크로 변경 */}
            <LinkSpan href={InquiryPageUrl}>문의하기</LinkSpan>
          </FlexDirectionColumnOnMobile>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <FlexDirectionColumnOnMobile>
              <p>
                경기도 안양시 만안구 덕천로 152번길 25 아이에스비즈타워 B동
                1506호
              </p>
              <p>대표 : 조이준</p>
              <p>사업자 등록번호 : 487-88-02557</p>
            </FlexDirectionColumnOnMobile>
            <FlexDirectionColumnOnMobile>
              <p>TEL : 010-6575-1161 / 031-444-1162</p>
              <p>FAX : 031-460-8553</p>
              <p>Email : cyj@lodong.co.kr</p>
            </FlexDirectionColumnOnMobile>
          </div>
          <p>©LODONG Corp. All rights reserved.</p>
        </TextDiv>
      </Box>
    </Container>
  );
}

export default Footer;
