import {
  BasePaginationResponse,
  BaseResponse,
  ImageResponse,
} from '../UtilsInterface';
import { apiClient } from '../../apiClient';
import API_ENDPOINTS from '../../../api/endpoints/apiEndpoints';

// 포트폴리오 리스트 조회 요청 타입 정의
interface PortfolioListParams {
  page?: number;
  size?: number;
  mainCategoryId?: number;
  subCategoryId?: number;
  searchString?: string;
}

// 포트폴리오 데이터 타입 정의
export interface PortfolioListData {
  uuidBaseImage: string;
  baseImageUrlVo: ImageResponse;
  idPortfolio: string;
  categoryId: number;
  companyName: string;
  simpleDescription: string;
  tags: string[];
  siteName: string;
  siteUrl: string;
  regDate: string;
  updateDate: string | null;
}

export interface PortfolioCategory {
  categoryId: number;
  subCategoryId: number;
}

export interface PortfolioData {
  uuidPortfolio: string;
  companyName: string;
  siteName: string;
  siteUrl: string;
  description: string;
  openDate: string;
  regDate: string;
  updateDate: string | null;
  category: PortfolioCategory;
  thumbnailUuid: string;
  thumbnailImage: ImageResponse;
  tags: string[];
  detailedImageUuid: string;
  detailedImage: ImageResponse; // If detailedImage can be null
}

const PortfolioService = {
  // 포트폴리오 리스트 조회
  getPortfolioList: async (
    params: PortfolioListParams
  ): Promise<BaseResponse<BasePaginationResponse<PortfolioListData>>> => {
    try {
      const response = await apiClient.get(API_ENDPOINTS.get_portfolio_list(), {
        params: {
          page: params.page || 0,
          size: params.size || 30,
          mainCategoryId: params.mainCategoryId,
          subCategoryId: params.subCategoryId,
          searchString: params.searchString,
        },
      });
      return response.data as Promise<
        BaseResponse<BasePaginationResponse<PortfolioListData>>
      >;
    } catch (error) {
      console.error('포트폴리오 리스트 조회 실패:', error);
      throw error;
    }
  },

  // 포트폴리오 상세 조회
  getPortfolioDetails: async (
    idPortfolio: string
  ): Promise<BaseResponse<PortfolioData>> => {
    try {
      const response = await apiClient.get(
        API_ENDPOINTS.get_portfolio_details(idPortfolio)
      );
      return response.data as BaseResponse<PortfolioData>;
    } catch (error) {
      console.error('포트폴리오 상세 조회 실패:', error);
      throw error;
    }
  },

  // 태그별 포트폴리오 조회
  getPortfolioByTags: async (
    tags: string[]
  ): Promise<BaseResponse<PortfolioListData[]>> => {
    try {
      const response = await apiClient.post(
        API_ENDPOINTS.get_portfolio_by_tags(),
        { tags }
      );
      return response.data as BaseResponse<PortfolioListData[]>;
    } catch (error) {
      console.error('태그별 포트폴리오 조회 실패:', error);
      throw error;
    }
  },
};

export default PortfolioService;
