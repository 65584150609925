import styled from 'styled-components';
import text_decoration from '../assets/images/text_decoration.svg';

const PictureBannerContainer = styled.div<{ $imagePath: string }>`
  background-image: url(${(props) => props.$imagePath});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: #fff;
  height: 400px;
  gap: 50px;
  @media (max-width: 1024px) {
    height: 250px;
    padding: 20px;
    gap: 0px;
  }
  @media (max-width: 768px) {
    height: 150px;
    padding: 20px;
    gap: 0px;
  }
`;

const Title = styled.h1<{ $titleColor: string }>`
  font-weight: bold;
  color: ${(props) => props.$titleColor};
  margin-bottom: 1rem;
  margin-top: 0;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px; /* 84.375% */
  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px; /* 166.667% */
  }
`;

const SubtitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubtitleDecoration = styled.img`
  transform: translateY(-1rem);
  height: 1rem;
  width: auto;
  margin-right: 0.5rem;
`;

const SubtitleText = styled.div<{ $titleColor: string }>`
  font-size: 24px;
  color: ${(props) => props.$titleColor};
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
  text-align: center;

  @media (max-width: 1024px) {
    word-break: keep-all;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px; /* 166.667% */
  }
`;

function PictureBanner({
  title,
  titleColor = 'white',
  subtitle,
  subtitleDecoration = false,
  imagePath,
}: {
  title: string;
  titleColor?: string;
  subtitle: React.ReactNode;
  subtitleDecoration?: boolean;
  imagePath: string;
}) {
  console.log('titleColor:', titleColor); // 디버그용
  return (
    <PictureBannerContainer $imagePath={imagePath}>
      <Title $titleColor={titleColor}>{title}</Title>
      <SubtitleContainer>
        {subtitleDecoration && (
          <SubtitleDecoration src={text_decoration} alt="" />
        )}
        <SubtitleText $titleColor={titleColor}>{subtitle}</SubtitleText>
      </SubtitleContainer>
    </PictureBannerContainer>
  );
}

export default PictureBanner;
