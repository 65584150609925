import SelectBox from '../../components/SelectBox';
import PortfolioService, {
  PortfolioListData,
} from '../../api/service/user/PortfolioService';
import Layout from '../../components/admin/navbar/Layout';
import React, { useEffect, useState } from 'react';
import CategoryService from '../../api/service/user/CategoryService';
import styled from 'styled-components';
import ImagePreview from '../../components/ImagePreview';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import Pagination from '../../components/Pagination';
import { useNavigate } from 'react-router-dom';
import {
  AdminPortfolioAddPageUrl,
  AdminPortfolioUpdatePageUrl,
} from '../../url/lodongUrl';

const Header = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

const SelectBoxContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Label = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

const Button = styled.button`
  font-size: 16px;
  padding: 10px;
  background-color: #000;
  border: none;
  color: #fff;
  border-radius: 10px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4개의 열 생성 */
  gap: 20px;
  margin-top: 20px;
  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const GridItem = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px); /* 살짝 들어올림 */
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const OverlayTitle = styled.h2`
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px; /* 87.5% */
`;

const OverlayDescription = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;

const TagsBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 3px;

  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
  }
`;
const Line = styled.div`
  width: 100%;
  background-color: #fff;
  height: 1px;
`;
const Icon = styled(IoArrowForwardCircleOutline)`
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

// 새로운 효과 스타일 추가
const SlideInOverlay = styled.div`
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #262626;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  transition: all 0.2s ease-in-out;

  ${GridItem}:hover & {
    bottom: 0; /* 호버 시 텍스트가 위로 슬라이드 */
  }
`;

function AdminPortfolioPage() {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const [portfolioList, setPortfolioList] = useState<PortfolioListData[]>([]);
  const [topLevelCategories, setTopLevelCategories] = useState<
    { label: string; value: string }[]
  >([]);
  const [subCategories, setSubCategories] = useState<
    { label: string; value: string }[]
  >([]);
  const [formData, setFormData] = useState({
    topCategoryId: '', // 대분류 ID
    subCategoryId: '', // 중분류 ID
  });

  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수

  // 대분류 카테고리 가져오기
  const fetchTopLevelCategories = async () => {
    try {
      const response = await CategoryService.getRootCategories();
      const formattedCategories = response.data.map((category) => ({
        label: category.mainCategory,
        value: category.id.toString(),
      }));
      setTopLevelCategories([
        { label: '대분류 선택', value: '' },
        ...formattedCategories,
      ]);
    } catch (error) {
      console.error('대분류 카테고리 조회 실패:', error);
    }
  };

  // 중분류 카테고리 가져오기
  const fetchSubCategories = async (parentId: number) => {
    try {
      const response = await CategoryService.getSubCategories(parentId);
      const formattedCategories = response.data.map((category) => ({
        label: category.subCategory || category.mainCategory,
        value: category.id.toString(),
      }));
      setSubCategories([
        { label: '중분류 선택', value: '' },
        ...formattedCategories,
      ]);
    } catch (error) {
      console.error('중분류 카테고리 조회 실패:', error);
    }
  };

  // 대분류 선택 변경 핸들러
  const handleTopCategoryChange = async (selectedValue: string) => {
    setFormData((prev) => ({
      ...prev,
      topCategoryId: selectedValue,
      subCategoryId: '', // 대분류 변경 시 중분류 초기화
    }));

    // 선택된 대분류에 따라 하위 카테고리를 가져옵니다.
    if (selectedValue) {
      await fetchSubCategories(parseInt(selectedValue, 10));
    } else {
      setSubCategories([]); // 대분류 선택 해제 시 중분류 초기화
    }

    // 포트폴리오 리스트 업데이트
    fetchPortfolioList({
      mainCategoryId: parseInt(selectedValue, 10),
      subCategoryId: undefined, // 중분류 선택 초기화
    });
  };

  // 중분류 선택 변경 핸들러
  const handleSubCategoryChange = (selectedValue: string) => {
    setFormData((prev) => ({
      ...prev,
      subCategoryId: selectedValue,
    }));

    // 포트폴리오 리스트 업데이트
    fetchPortfolioList({
      mainCategoryId: parseInt(formData.topCategoryId, 10),
      subCategoryId: parseInt(selectedValue, 10),
    });
  };

  const fetchPortfolioList = async (params?: {
    mainCategoryId?: number;
    subCategoryId?: number;
    page?: number;
    size?: number;
    searchString?: string;
  }) => {
    try {
      const requestParams = {
        page: params?.page || currentPage,
        size: 12,
        ...(params?.mainCategoryId && {
          mainCategoryId: params.mainCategoryId,
        }),
        ...(params?.subCategoryId && { subCategoryId: params.subCategoryId }),
        ...(params?.searchString && { searchString: params.searchString }), // searchString이 존재하면 추가
      };

      const response = await PortfolioService.getPortfolioList(requestParams);
      console.log('포트폴리오 리스트:', response);
      setPortfolioList(response.data.content); // 포트폴리오 리스트 상태 업데이트
      setTotalPages(response.data.totalPages); // 전체 페이지 수 업데이트
    } catch (error) {
      console.error('포트폴리오 리스트 조회 실패:', error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchPortfolioList({
      mainCategoryId: parseInt(formData.topCategoryId, 10),
      subCategoryId: parseInt(formData.subCategoryId, 10),
      page: page,
    });
  };

  // "추가하기" 버튼 클릭 핸들러
  const handleAddButtonClick = () => {
    navigate(AdminPortfolioAddPageUrl); // 이동할 경로 설정
  };

  const handleIconClick = (portfolioId: string) => {
    navigate(AdminPortfolioUpdatePageUrl, { state: { id: portfolioId } });
  };

  // 컴포넌트가 마운트될 때 대분류 카테고리 가져오기
  useEffect(() => {
    fetchPortfolioList(); // 초기 호출: 파라미터 없이 기본값으로 호출
    fetchTopLevelCategories();
  }, []);

  return (
    <Layout>
      <Header>
        <SelectBoxContainer>
          <Label>카테고리 선택</Label>
          <SelectBox
            options={topLevelCategories}
            value={formData.topCategoryId}
            onChange={(value) => handleTopCategoryChange(value)} // 대분류 선택 핸들러
          />
          {formData.topCategoryId && subCategories.length > 1 && (
            <SelectBox
              options={subCategories}
              value={formData.subCategoryId}
              onChange={(value) => handleSubCategoryChange(value)} // 중분류 선택 핸들러
            />
          )}
        </SelectBoxContainer>
        <Button onClick={handleAddButtonClick}>추가하기</Button>
      </Header>

      <GridContainer>
        {portfolioList.length === 0 && <>포트폴리오가 없습니다.</>}
        {portfolioList.map((portfolio) => (
          <GridItem key={portfolio.idPortfolio}>
            <ImageWrapper>
              <ImagePreview
                image={portfolio.baseImageUrlVo}
                alt={portfolio.siteName}
              />
              <SlideInOverlay>
                <OverlayTitle>{portfolio.siteName}</OverlayTitle>
                <OverlayDescription>
                  {portfolio.simpleDescription}
                </OverlayDescription>
                <Line />
                <TagsBox>
                  {portfolio.tags.map((tag, index) => (
                    <span key={index}>{tag}</span>
                  ))}
                </TagsBox>
                <Icon onClick={() => handleIconClick(portfolio.idPortfolio)} />
              </SlideInOverlay>
            </ImageWrapper>
          </GridItem>
        ))}
      </GridContainer>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </Layout>
  );
}

export default AdminPortfolioPage;
