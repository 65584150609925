import React from 'react';
import styled from 'styled-components';

const SubCategoryGroupStyle = styled.div`
  display: flex;
  border-top: 1px solid #262626;
  border-bottom: 1px solid #262626;
  padding: 15px 0;
  color: #262626cc;
  justify-content: space-around;

  span {
    cursor: pointer;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    &.active {
      color: red;
    }

    &:hover {
      font-weight: 700;
    }
  }
`;

type SubCategoryGroupProps = {
  categories: { id: number | null; name: string }[]; // ✅ ID & Name 구조
  activeCategory: number | null;
  setActiveCategory: (categoryId: number | null) => void;
};

const SubCategoryGroup: React.FC<SubCategoryGroupProps> = ({
  categories,
  activeCategory,
  setActiveCategory,
}) => {
  return (
    <SubCategoryGroupStyle>
      {categories.map((category) => (
        <span
          key={category.id ?? '전체'} // ✅ `null`이면 "전체" 키로 설정
          className={activeCategory === category.id ? 'active' : ''}
          onClick={() => setActiveCategory(category.id)} // ✅ ID 값을 저장
        >
          {category.name}
        </span>
      ))}
    </SubCategoryGroupStyle>
  );
};

export default SubCategoryGroup;
