import React, { useRef, ChangeEvent } from 'react';
import styled from 'styled-components';
import { FiCalendar } from 'react-icons/fi';

// 스타일 컴포넌트 정의
const DateInputWrapper = styled.div`
  position: relative;
  display: inline-block;
  @media (max-width: 568px) {
    width: 100%;
  }
`;

const DateInput = styled.input.attrs({ type: 'date' })`
  font-size: 14px;
  width: 150px;
  padding: 4px 36px 4px 8px;
  border: 1px solid ${(props) => props.theme.colors.grayColor};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.textColor};
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  @media (max-width: 568px) {
    width: 100%;
  }
`;

const CalendarIcon = styled(FiCalendar)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.grayColor};
  pointer-events: auto;
  font-size: 16px;
  cursor: pointer;
`;

// DatePicker 컴포넌트 Props 타입 정의
interface DatePickerProps {
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disableFuture?: boolean; // 미래 날짜 선택 비활성화 여부
  required?: boolean;
}

// DatePicker 컴포넌트 정의
const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  disableFuture = false,
  required = false,
}) => {
  const dateInputRef = useRef<HTMLInputElement>(null);

  // 오늘 날짜 계산
  const today = new Date().toISOString().split('T')[0]; // yyyy-MM-dd 형식

  const handleIconClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };

  return (
    <DateInputWrapper>
      <DateInput
        ref={dateInputRef}
        value={value}
        onChange={onChange}
        max={disableFuture ? today : undefined} // disableFuture가 true일 경우 오늘을 최대값으로 설정
        required={required}
      />
      <CalendarIcon onClick={handleIconClick} />
    </DateInputWrapper>
  );
};

export default DatePicker;
