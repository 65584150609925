import styled from 'styled-components';
import PictureBanner from '../../../components/PictureBanner';
import { useIsMobile } from '../../../hooks/useIsMobile';
import decoration from '../../../assets/images/decoration.svg';
import building_banner from '../../../assets/images/introductionPage/building_banner.png';
import FloatingButtonGroup from '../../../components/user/mainPage/FloatingButton';
import TopBar from '../../../components/user/topBar/TopBar';
const HeaderText = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.5rem;
  padding-left: 15%;
  max-width: 1920px;
  margin: 0 auto;
  @media (max-width: 1440px) {
    padding-left: 10%;
  }
  @media (max-width: 1024px) {
    padding-left: 5%;
    line-height: 1.2rem;
    font-size: 12px;
  }
`;
const HeaderTextContainer = styled.div`
  position: relative;
  height: 240px;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    height: 100px;
  }
`;
const ContentContainerBox = styled.div`
  width: 100%;
  background-color: #26262605;
`;
const ContentContainer = styled.div`
  padding: 64px 15%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  font-size: 18px;
  line-height: 2.5rem;
  gap: 0; /* 기본값 */
  flex-direction: row; /* 기본값 */

  @media (max-width: 1440px) {
    padding: 64px 10%;
  }
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    gap: 24px;
    padding: 24px 5%;
    font-size: 12px;
    line-height: 1.2rem;

    > * {
      width: auto;
      flex-grow: 1;
    }
  }
`;

const OnlyOnMobile = styled.br`
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`;
const LabelContainer = styled.div`
  gap: 20px;
  padding: 64px 15%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1440px) {
    padding: 64px 10%;
  }
  @media (max-width: 1024px) {
    padding: 24px 5%;
  }
`;

function CompanyIntroductionPage() {
  const isMobile = useIsMobile();

  return (
    <div>
      <FloatingButtonGroup />
      <TopBar variant="dark" />

      <PictureBanner
        title="COMPANY"
        subtitle={isMobile ? '' : '회사 소개'}
        titleColor="black"
        subtitleDecoration={!isMobile}
        imagePath={building_banner}
      />

      <HeaderTextContainer
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={decoration}
          style={{
            position: 'absolute',
            right: '20%',
            top: 0,
            height: '100%',
          }}
          alt="decoration"
        ></img>
        <HeaderText>
          로동은 2015년에 설립된 이래,&nbsp;
          <span
            style={{
              color: 'red',
            }}
          >
            소프트웨어 개발&nbsp;
          </span>
          및&nbsp;
          <span
            style={{
              color: 'red',
            }}
          >
            다양한 IT솔루션
          </span>
          을<br></br>
          제공하는 혁신적인 기업입니다.
        </HeaderText>
      </HeaderTextContainer>
      <ContentContainerBox>
        <ContentContainer>
          <p>
            저희는 소프트웨어 개발 대행을 통해 고객의 요구에 맞춘 맞춤형
            솔루션을 제공하며, 다양한 프로젝트에서 성공적인 성과를 이끌어 내고
            있습니다.
            <br></br>
            <OnlyOnMobile />
            주요 사업 분야로는 소프트웨어 개발 대행, Artfleet(동영상 스트리밍),
            CDN(컨텐츠 전송 네트워크), 서버 호스팅, 그리고 **HRD LMS(학습 관리
            시스템)**가 있습니다.<br></br>
            <OnlyOnMobile />각 사업 영역에서 저희는 탁월한 전문성을 바탕으로,
            고객에게 최적화된 솔루션을 제공하여 그들의 비즈니스를 지원하고
            있습니다.<br></br>
            <br></br>로동은 모든 프로젝트를 자사의 서비스를 개발한다는 자세로
            임하고 있습니다. 저희는 클라이언트의 요구를 철저히 분석하고 이해한
            후,
            <br></br>이를 바탕으로 기획부터 개발까지 세심하게 진행하여 최적의
            결과를 도출하는 것을 목표로 합니다.<br></br>
            <OnlyOnMobile />더 나아가, 로동은 호스팅 기술의 중요성을 깊이 체감한
            경험을 바탕으로, 24시간 안정적인 서비스 제공을 목표로 하고 있습니다.
            <br></br>이를 위해 리플리케이션, 로드 밸런싱, 파티셔닝, 샤딩 등
            대규모 서비스에 적합한 기술 지원을 강화하며, 안정적인 백업과 철저한
            서버 호스팅 관리를 통해 고객의 비즈니스가 중단 없이 항상 원활하게
            운영되고 있습니다.<br></br>
            <br></br>
            로동은 단순한 개발 서비스를 넘어, 완벽한 성과를 이루기 위해 최선을
            다하며, 실패를 허용하지 않는 철저한 준비로 항상 고객의 기대를
            뛰어넘는 결과를 창출하고 있습니다.<br></br>
            <OnlyOnMobile />
            또한, 저희는 함께 성장하는 가치를 중요시하며, 고객과 파트너 모두가
            상생할 수 있는 환경을 조성하는 것을 목표로 하고 있습니다.<br></br>
            주식회사 로동은 혁신과 성장을 통해 고객과 함께 더 나은 미래를 열어
            나가겠습니다.
          </p>
        </ContentContainer>
      </ContentContainerBox>
      {isMobile ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            padding: '64px 24px',
            paddingTop: '32px',
          }}
        >
          <LabelAndText label="회사명" text="주식회사 로동" />
          <LabelAndText label="대표이사" text="조이준" />
          <LabelAndText label="설립일" text="2015년 1월 1일" />
          <LabelAndText
            label="사업분야"
            text="동영상 스트리밍, 서버호스팅등 개발. 홈페이지 제작"
          />
          <LabelAndText
            label="주소"
            text="경기도 안양시 만안구 덕천로 152번길 25 아이에스비즈타워 B동 1506호"
          />
          <LabelAndText
            label="연락처"
            text={
              'TEL : 010-6575-1161 / 031-444-1162\nEmail : cyj@lodong.co.kr'
            }
          />
        </div>
      ) : (
        <LabelContainer>
          <div>
            <LabelAndText label="회사명" text="주식회사 로동" />
            <div style={{ height: '64px' }}></div>
            <LabelAndText label="설립일" text="2015년 1월 1일" />
          </div>
          <div
            style={{
              width: 0,
              borderLeft: '1px solid #2626260D',
            }}
          ></div>
          <div>
            <LabelAndText label="대표이사" text="조이준" />
            <div style={{ height: '64px' }}></div>
            <LabelAndText
              label="사업분야"
              text="동영상 스트리밍, 서버호스팅등 개발. 홈페이지 제작"
            />
          </div>
          <div
            style={{
              width: 0,
              borderLeft: '1px solid #2626260D',
            }}
          ></div>
          <div>
            <LabelAndText
              label="주소"
              text="경기도 안양시 만안구 덕천로 152번길 25 아이에스비즈타워 B동 1506호"
            />
            <div style={{ height: '64px' }}></div>
            <LabelAndText
              label="연락처"
              text="TEL : 010-6575-1161 / 031-444-1162&nbsp;&nbsp;&nbsp;Email : cyj@lodong.co.kr"
            />
          </div>
        </LabelContainer>
      )}
    </div>
  );
}

function LabelAndText({ label, text }: { label: string; text: string }) {
  return (
    <div
      style={{
        whiteSpace: 'pre-line',
      }}
    >
      <p
        style={{
          color: '#2626264D',
          fontSize: '16px',
          marginBottom: '16px',
        }}
      >
        {label}
      </p>
      <p
        style={{
          color: '#262626',
          fontSize: '16px',
        }}
      >
        {text}
      </p>
    </div>
  );
}
export default CompanyIntroductionPage;
