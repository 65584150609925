import React from 'react';
import styled from 'styled-components';

const FieldLabel = styled.span`
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 1024px) {
    font-size: 12px;
    white-space: nowrap;
  }
`;

const FieldContainer = styled.div`
  flex: 1;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  display: flex;
  padding: 12px 8px;
  align-items: center;
`;

const FieldInput = styled.input<{ $isDate: boolean }>`
  background: none;
  border: none;
  margin-left: 16px;
  flex-grow: ${({ $isDate }) => ($isDate ? 0 : 1)};

  &:focus {
    outline: none;
  }
`;

const FieldTextArea = styled.textarea`
  height: 200px;
  background: none;
  border: none;
  margin-left: 16px;
  flex-grow: 1;
  resize: none;

  &:focus {
    outline: none;
  }
`;

function InquiryField({
  fieldStyle = {},
  label,
  hint = '',
  multiline = false,
  type = 'text',
  value,
  onChange,
}: {
  fieldStyle?: React.CSSProperties;
  label: string;
  hint?: string;
  multiline?: boolean;
  type?: 'text' | 'date' | 'email' | 'number'; // 여기에 필요한 타입 추가
  value: string;
  onChange: (value: string) => void;
}) {
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(event.target.value);
  };

  return (
    <FieldContainer style={fieldStyle}>
      <FieldLabel>{label}</FieldLabel>
      {multiline ? (
        <FieldTextArea
          placeholder={hint}
          value={value}
          onChange={handleInputChange}
        ></FieldTextArea>
      ) : (
        <FieldInput
          type={type} // 입력 타입 지정
          placeholder={hint}
          value={value}
          onChange={handleInputChange}
          $isDate={type === 'date'} // $isDate 속성 전달
        ></FieldInput>
      )}
    </FieldContainer>
  );
}

export default InquiryField;
