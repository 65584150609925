// 엔드포인트 URL을 생성하는 함수의 타입을 정의합니다.
export interface CategoryEndpointsProps {
  get_all_categories: () => string; // 모든 카테고리 조회
  get_sub_categories: (parentId: number) => string; // 하위 카테고리 조회
  get_root_categories: () => string; // 1차 카테고리 조회
}

// 카테고리 관련 엔드포인트 정의
export const CategoryEndpoints: CategoryEndpointsProps = {
  get_all_categories: () => `api/lodong-new-web/auth/category`, // 모든 카테고리 조회
  get_sub_categories: (parentId: number) =>
    `api/lodong-new-web/auth/category/sub?parentId=${parentId}`, // 하위 카테고리 조회
  get_root_categories: () => `api/lodong-new-web/auth/category/root`, // 1차 카테고리 조회
};

export default CategoryEndpoints;
