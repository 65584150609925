// 엔드포인트 URL을 생성하는 함수의 타입을 정의합니다.
export interface AuthEndpointsProps {
  post_login: () => string;
  post_logout: () => string;
}

// 인증 관련 엔드포인트 정의
export const AuthEndpoints: AuthEndpointsProps = {
  post_login: () => `api/laver/auth/login`,
  post_logout: () => `api/laver/auth/logout`,
};

export default AuthEndpoints;
