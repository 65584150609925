import styled from 'styled-components';
import {
  CeoIntroductionPageUrl,
  CompanyIntroductionPageUrl,
  InquiryPageUrl,
  LocationPageUrl,
  PortfolioPageUrl,
  HrdloServicesPageUrl,
  HomepageProductionPageUrl,
  // HistoryPageUrl,
} from '../../../url/lodongUrl';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const MenuContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  position: relative;
  @media (max-width: 1440px) {
    gap: 10px;
  }
`;

const MenuItem = styled.a<{ variant: 'light' | 'dark' }>`
  color: ${(props) => (props.variant === 'dark' ? 'white' : 'black')};
  font-size: 16px;
  text-decoration: none;
  position: relative;
`;

const SubMenuContainer = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  border-radius: 10px;
  width: 120px;
  top: 100%;
  margin-top: 0px;
  left: -30px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  flex-direction: column;

  padding: 8px 12px;
  z-index: 100000000000;
`;

const SubMenuItem = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 14px;
  padding: 8px 0px;

  &:hover {
    font-weight: 700;
  }
`;

function DesktopMenus({ variant = 'dark' }: { variant?: 'light' | 'dark' }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <MenuContainer>
      <MenuItem href={PortfolioPageUrl} variant={variant}>
        포트폴리오
      </MenuItem>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ position: 'relative', padding: '10px 0px' }}
      >
        <MenuItem as="span" variant={variant}>
          회사 소개
        </MenuItem>
        <SubMenuContainer $isVisible={isHovered}>
          <SubMenuItem to={CompanyIntroductionPageUrl}>회사 소개</SubMenuItem>
          <SubMenuItem to={CeoIntroductionPageUrl}>CEO 인사말</SubMenuItem>
          {/* <SubMenuItem to={HistoryPageUrl}>연혁</SubMenuItem> */}
        </SubMenuContainer>
      </div>
      <MenuItem href={HomepageProductionPageUrl} variant={variant}>
        홈페이지 제작
      </MenuItem>
      <MenuItem href={HrdloServicesPageUrl} variant={variant}>
        서비스 소개
      </MenuItem>
      <MenuItem href={InquiryPageUrl} variant={variant}>
        문의하기
      </MenuItem>
      <MenuItem href={LocationPageUrl} variant={variant}>
        오시는 길
      </MenuItem>
    </MenuContainer>
  );
}

export default DesktopMenus;
