import TopBar from '../../../components/user/topBar/TopBar';
import ServicesBanner from '../../../components/user/servicesPage/ServicesBanner';
import ServicesTitleComponent from '../../../components/user/servicesPage/ServicesTitleComponent';
import DevelopmentServiceList from '../../../components/user/servicesPage/DevelopmentServices/DevelopmentServiceList';
import styled from 'styled-components';
import DevelopmentSolutionList from '../../../components/user/servicesPage/DevelopmentServices/DevelopmentSolutionList';
import DevelopmentService from '../../../components/user/servicesPage/DevelopmentServices/DevelopmentService';
import FloatingButtonGroup from '../../../components/user/mainPage/FloatingButton';

const Body = styled.div`
  display: flex;
  color: #262626;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 50px;
  padding: 0 15%;
  max-width: 1920px;
  margin: 100px auto 0;
  @media (max-width: 1440px) {
    padding: 0 10%;
  }
  @media (max-width: 1024px) {
    margin-top: 32px;
    padding: 0 5%;
  }
`;

function DevelopmentServicesPage() {
  return (
    <div>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <ServicesBanner />
      <ServicesTitleComponent title="개발" />
      <Body>
        <DevelopmentServiceList />
        <DevelopmentSolutionList />
        <DevelopmentService />
      </Body>
    </div>
  );
}

export default DevelopmentServicesPage;
