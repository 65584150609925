// 엔드포인트 URL을 생성하는 함수의 타입을 정의합니다.
export interface AdminPortfolioEndpointsProps {
  update_portfolio: (idPortfolio: number) => string; // 포트폴리오 수정
  delete_portfolio: (idPortfolio: number) => string; // 포트폴리오 삭제
  create_portfolio: () => string; // 포트폴리오 등록
  upload_thumbnail: () => string; // 썸네일 업로드
  delete_images: () => string; // 다중 이미지 삭제
}

// 포트폴리오 관련 엔드포인트 정의
export const AdminPortfolioEndpoints: AdminPortfolioEndpointsProps = {
  update_portfolio: (idPortfolio: number) =>
    `api/lodong-new-web/admin/portfolio/${idPortfolio}`, // 포트폴리오 수정
  delete_portfolio: (idPortfolio: number) =>
    `api/lodong-new-web/admin/portfolio/${idPortfolio}`, // 포트폴리오 삭제
  create_portfolio: () => `api/lodong-new-web/admin/portfolio`, // 포트폴리오 등록
  upload_thumbnail: () => `api/lodong-new-web/admin/portfolio/upload-thumbnail`, // 썸네일 업로드
  delete_images: () => `api/lodong-new-web/admin/portfolio/images`, // 다중 이미지 삭제
};

export default AdminPortfolioEndpoints;
