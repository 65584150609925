// 엔드포인트 URL을 생성하는 함수의 타입을 정의합니다.
interface InquireEndpointsProps {
  post_simple_inquire: () => string; // 간단문의 API
  post_detailed_inquire: () => string; // 상세문의 API
  get_inquire_list: (page: number, size: number) => string; // 페이지 조회 API
}

// 문의 관련 엔드포인트 정의
const InquireEndpoints: InquireEndpointsProps = {
  post_simple_inquire: () => `api/lodong-new-web/inquire/simple`, // 간단문의 API
  post_detailed_inquire: () => `api/lodong-new-web/inquire/detail`, // 상세문의 API
  get_inquire_list: (page: number, size: number) =>
    `api/lodong-new-web/inquire/list?page=${page}&size=${size}`, // 페이지 조회 API
};

export default InquireEndpoints;
