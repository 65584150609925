// Entry.tsx
import React from 'react';
import styled from 'styled-components';

const Title = styled.p`
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 71.429% */
  margin-bottom: 32px;
  padding-left: 20px;
  border-left: 2px solid #ff0000;

  @media (max-width: 1024px) {
    font-size: 24px;
    padding-left: 10px;
  }
`;

const Entry = styled.div`
  display: flex;
  gap: 64px;

  &:nth-child(odd) {
    flex-direction: row;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 1024px) {
    &:nth-child(odd),
    &:nth-child(even) {
      flex-direction: column; /* 모바일 환경에서도 일관된 flex 설정 */
    }
  }
`;

const Image = styled.img`
  box-shadow: 16px 12px 0 #eeeeee;
  object-fit: contain;
  width: 0;
  flex-grow: 1;

  @media (max-width: 1024px) {
    width: auto; /* 또는 원하는 값 */
    flex-grow: 0; /* 기본값은 0 */
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 0;
  flex-grow: 2.5;

  @media (max-width: 1024px) {
    width: auto; /* 또는 원하는 값 */
    flex-grow: 0; /* 기본값은 0 */
  }
`;

const Description = styled.p`
  color: #262626;
  font-size: 20px;
  word-break: keep-all;
  overflow-wrap: break-word; /* text-wrap 대체 */
  line-height: 1.5;
  padding-bottom: 32px;
`;

const ThirdText = styled.p`
  font-size: 18px;
  word-break: keep-all;
  overflow-wrap: break-word; /* text-wrap 대체 */
`;

interface EntryProps {
  imagePath: string;
  title: string;
  description: string;
  thirdText: string;
}

function MarketingServiceEntry({
  imagePath,
  title,
  description,
  thirdText,
}: EntryProps) {
  return (
    <Entry>
      <Image src={imagePath} alt="" />
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ThirdText>{thirdText}</ThirdText>
      </TextContainer>
    </Entry>
  );
}

export default MarketingServiceEntry;
