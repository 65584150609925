import React from 'react';
import styled from 'styled-components';
import Frame1U from '../../../../../assets/images/servicesPage/serverHostingServicesPage/serverHostingServicesPriceTab3/Frame1U.png';
import Rack from '../../../../../assets/images/servicesPage/serverHostingServicesPage/serverHostingServicesPriceTab3/Rack.png';

const Container = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 760px) {
    margin-top: 30px;
  }
`;
const Title = styled.h1`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 66.667% */
  @media (max-width: 760px) {
    font-size: 18px;
    line-height: 18px; /* 111.111% */
  }
`;
const SubTitle = styled.h1`
  word-break: keep-all;
  text-align: center;
  margin-top: 30px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
  @media (max-width: 760px) {
    margin-top: 10px;
    font-size: 13px;
    line-height: 13px; /* 153.846% */
  }
`;

const RackCardContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  padding: 70px 0px 40px;
  align-items: flex-start;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 70px 0px 40px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 0px 40px;
  }
`;

const ResponsiveImage = styled.img`
  width: 100%;
  max-width: 550px;

  /* 모바일 환경에서만 보여줌 */
  @media (max-width: 1440px) {
    max-width: 350px;
  }
  @media (max-width: 1024px) {
    max-width: 100%;
    content: url(${Frame1U});
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  @media (max-width: 760px) {
    gap: 10px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled.div`
  border-radius: 15px;
  border: 5px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 40px 25px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  @media (max-width: 900px) {
    padding: 20px;
    gap: 20px;
  }
`;
const CardTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const CardTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 83.333% */
`;

const CardSubTitle = styled.p`
  word-break: keep-all;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 111.111% */
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.05);
`;
const DescContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Desc = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
`;

const DescTitle = styled.h1`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;

const DescText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
`;

const Price = styled.p`
  color: #f00;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;

const Button = styled.button`
  width: 100%;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  &:hover {
    background-color: #1f1f1f;
  }

  @media (max-width: 900px) {
    margin: 10px 0px;
  }
`;

const VAT = styled.p`
  width: 100%;
  text-align: right;
  margin: 20px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 85.714% */
  word-break: keep-all;
  br {
    display: none;
  }
  @media (max-width: 760px) {
    text-align: right;
    margin: 0px 0px 20px;
    br {
      display: block;
    }
  }
`;

function ServerHostingServicesPriceTab3() {
  const rackPricing = [
    {
      name: '1U',
      description: '1U 1대',
      bandwidth: '10Mbps',
      power: '2.5A / 220V',
      price: '100,000원',
    },
    {
      name: '2U',
      description: '2U 1대',
      bandwidth: '10Mbps',
      power: '2.5A / 220V',
      price: '150,000원',
    },
    {
      name: '4U',
      description: '4U 1대',
      bandwidth: '10Mbps',
      power: '2.5A / 220V',
      price: '200,000원',
    },
    {
      name: '1/4 RACK',
      description: '1U 6대',
      bandwidth: '10Mbps',
      power: '2.5A / 220V',
      price: '300,000원',
    },
    {
      name: '1/2 RACK',
      description: '1U 12대',
      bandwidth: '10Mbps',
      power: '2.5A / 220V',
      price: '600,000원',
    },
    {
      name: 'FULL RACK',
      description: '1U 24대',
      bandwidth: '10Mbps',
      power: '2.5A / 220V',
      price: '1,200,000원',
    },
  ];

  return (
    <Container>
      <Title>코로케이션</Title>
      <SubTitle>
        국내 최고 시설의 IDC에 입주하여 안정적인 서비스를 제공
      </SubTitle>
      <RackCardContainer>
        <ResponsiveImage src={Rack} alt="Frame1U" />
        <CardContainer>
          {rackPricing.map((rack, index) => (
            <Card key={index}>
              <CardTitleContainer>
                <CardTitle>{rack.name}</CardTitle>
                <CardSubTitle>{rack.description}</CardSubTitle>
              </CardTitleContainer>
              <Line />
              <DescContainer>
                <Desc>
                  <DescTitle>계약 대역폭</DescTitle>
                  <DescText>{rack.bandwidth}</DescText>
                </Desc>
                <Desc>
                  <DescTitle>계약 전력</DescTitle>
                  <DescText>{rack.power}</DescText>
                </Desc>
                <Desc>
                  <DescTitle>이용료(월)</DescTitle>
                  <Price>{rack.price}</Price>
                </Desc>
              </DescContainer>
              <Button onClick={() => alert(`${rack.name} 문의하기 클릭!`)}>
                문의하기
              </Button>
            </Card>
          ))}
          <div></div>
          <VAT>
            *(VAT 별도) <br />
            대여폭과 전력은 추가비용이 발생할 수 있습니다.
          </VAT>
          <Card>
            <CardTitleContainer>
              <CardTitle>코로케이션 회선 추가</CardTitle>
            </CardTitleContainer>
            <Line />
            <DescContainer>
              <Desc>
                <DescTitle>10Mbps</DescTitle>
                <DescText>1G port</DescText>
              </Desc>
              <Desc>
                <DescTitle>100Mbps</DescTitle>
                <DescText>1G port</DescText>
              </Desc>
              <Desc>
                <DescTitle>1Gbps</DescTitle>
                <DescText>1G/10G port</DescText>
              </Desc>
              <Desc>
                <DescTitle>10Gbps</DescTitle>
                <DescText>10Mbps</DescText>
              </Desc>
              <Line />
              <CardTitleContainer>
                <CardSubTitle>*설치비, 월 이용료는 별도협의</CardSubTitle>
              </CardTitleContainer>
            </DescContainer>
          </Card>
        </CardContainer>
      </RackCardContainer>
    </Container>
  );
}

export default ServerHostingServicesPriceTab3;
