import TopBar from '../../../components/user/topBar/TopBar';
import ServicesBanner from '../../../components/user/servicesPage/ServicesBanner';
import React from 'react';
import DesignServiceEntry from '../../../components/user/servicesPage/DesignServiceEntry';
import styled from 'styled-components';
import { useIsMobile } from '../../../hooks/useIsMobile';

import DesignDecoration from '../../../assets/images/servicesPage/DesignDecoration.svg';
import lodong_logo from '../../../assets/images/lodong_logo.png';
import FloatingButtonGroup from '../../../components/user/mainPage/FloatingButton';

const Header = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 150px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  color: rgba(0, 0, 0, 0.02);
  margin-top: 10px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    font-size: 70px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;
const LogoImg = styled.img`
  width: 60%;
  margin: auto;
`;

const Content = styled.div`
  @media (min-width: 1024px) {
    background-image: url(${DesignDecoration}); /* 변수로 동적 경로 설정 */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding: 64px 10%;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 320px;

    > div {
      display: flex;
      gap: 64px;
      justify-content: space-between;
      align-items: center;

      > * {
        flex-grow: 1;
        width: 0;
      }
    }
  }
  @media (min-width: 1440px) {
    padding: 64px 15%;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 32px 5%;
    padding-top: 0;

    > div {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
`;

function DesignServicesPage() {
  const isMobile = useIsMobile();
  return (
    <div>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <ServicesBanner />
      <div>
        <Header>DESIGN</Header>
        <Content>
          <div>
            <DesignServiceEntry
              title="홈페이지"
              description="PC, 모바일, 태블릿등 사용자 화면에 맞춘 디자인"
            />
            <DesignServiceEntry
              title="어플리케이션"
              description="모바일, 데스크탑, 웹 다양한 운영체제"
            />
            <DesignServiceEntry
              title="상세페이지"
              description="카드형 레이아웃, 인포그래픽, 제품 상세"
            />
          </div>
          <div>
            <DesignServiceEntry
              title="로고"
              description="워드마크, 레터마크, 심볼, 조합, 엠블럼"
            />
            {!isMobile && (
              <center>
                <LogoImg
                  style={{
                    width: '60%',
                    margin: 'auto',
                  }}
                  src={lodong_logo}
                  alt="lodong_logo"
                />
              </center>
            )}
            <DesignServiceEntry
              title="쇼핑몰"
              description="관리자 페이지, 미니멀한 간결한 구성"
            />
          </div>
          <div>
            <DesignServiceEntry
              title="이벤트(프로모션)"
              description="온라인 홍보, 브랜딩, 캠페인, 쿠폰, 세일 등"
            />
            <DesignServiceEntry
              title="배너"
              description="웹 배너, 팝업, 광고 배너"
            />
            <DesignServiceEntry
              title="SNS"
              description="제품 홍보, 이벤트, 인스타 광고 등"
            />
          </div>
        </Content>
      </div>
    </div>
  );
}

export default DesignServicesPage;
