// NotFoundPage.js
import React from 'react';

const NotFoundPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <h1>404 Error</h1>
      <p>존재하지 않는 페이지입니다.</p>
    </div>
  );
};

export default NotFoundPage;
