import React from 'react';
import { MarketingServiceDetailEntry } from './MarketingServiceDetailEntries';

interface DesktopServiceDetailsBodyProps {
  isPremium: boolean;
  serviceDetailEntries: MarketingServiceDetailEntry[][];
}

function MarketingDesktopServiceDetailsBody({
  isPremium,
  serviceDetailEntries,
}: DesktopServiceDetailsBodyProps) {
  return (
    <div
      style={{
        display: 'flex',
        fontSize: '18px',
        lineHeight: 2,
      }}
    >
      {serviceDetailEntries.map((entryGroup, groupIndex) => (
        <div
          key={`group-${groupIndex}`}
          style={{
            flexGrow: 1,
            paddingLeft: '24px',
            borderLeft: '1px solid white',
          }}
        >
          {entryGroup.map(
            (entry, index) =>
              entry.name && (
                <p
                  key={`entry-${groupIndex}-${index}`}
                  style={{
                    maxHeight:
                      (isPremium && entry.on === 'basic') ||
                      (!isPremium && entry.on === 'premium')
                        ? 0
                        : '100px',
                    transition: 'max-height 1s',
                    overflow: 'hidden',
                  }}
                >
                  {entry.name}&nbsp;
                </p>
              )
          )}
        </div>
      ))}
    </div>
  );
}

export default MarketingDesktopServiceDetailsBody;
