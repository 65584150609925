import { useIsMobile } from '../../../hooks/useIsMobile';
import inner_text_circle from '../../../assets/images/inner_text_circle.png';
import outer_text_circle from '../../../assets/images/outer_text_circle.png';
import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const rotateCounter = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`;

const Rotate = styled.img`
  animation: ${rotate} 30s linear infinite;
`;
const RotateCounter = styled.img`
  animation: ${rotateCounter} 30s linear infinite;
`;

function TextShow({ onShowEnd }: { onShowEnd: () => void }) {
  const [stage, setStage] = useState(0);

  const [text1Index, setText1Index] = useState(0);
  const fullText1 = 'NOTHING IS IMPOSSIBLE';

  /**
   * stage 설명 :
   * 0: 텍스트 타이핑
   * 1: 2번째 텍스트 날아옴(1초)
   * 2: 작아짐
   * 3: 텍스트 원 나타남
   * 4: 배경 글씨 나타남
   */

  const typingFrequency = 65;
  const stage1Duration = 500;
  const stage2Duration = 500;
  const stage3Duration = 500;
  const stage4Duration = 500;

  useEffect(() => {
    for (let i = 0; i < fullText1.length + 1; i++) {
      setTimeout(() => {
        if (i < fullText1.length + 1) {
          setText1Index(i);
        }
      }, typingFrequency * i);
    }

    setTimeout(
      () => {
        setStage(1);
        setTimeout(() => {
          setStage(2);
        }, stage1Duration);
        setTimeout(() => {
          setStage(3);
        }, stage1Duration + stage2Duration);
        setTimeout(
          () => {
            setStage(4);
          },
          stage1Duration + stage2Duration + stage3Duration
        );
        setTimeout(
          () => {
            onShowEnd();
          },
          stage1Duration + stage2Duration + stage3Duration + stage4Duration
        );
      },
      typingFrequency * (fullText1.length + 1)
    );
  }, []);

  const isMobile = useIsMobile();

  return (
    <div
      style={{
        backgroundColor: 'rgb(32,32,32)',
        position: 'relative',
        left: '0',
        top: '0',
        width: '100%',
        height: stage >= 4 ? '100%' : '100dvh',
        flexGrow: 1,
        overflow: 'clip',
        zIndex: 1,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          opacity: stage >= 3 ? 1 : 0,
          transition: 'all 0.5s',
          position: 'absolute',
          zIndex: 1,
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Rotate
            style={{
              position: 'absolute',
              height: isMobile ? '70dvw' : '640px',
              width: isMobile ? '70dvw' : '640px',
            }}
            src={inner_text_circle}
            alt="inner_text_circle"
          ></Rotate>
          <RotateCounter
            style={{
              position: 'absolute',
              height: isMobile ? '90dvw' : '800px',
              width: isMobile ? '90dvw' : '800px',
            }}
            src={outer_text_circle}
            alt="outer_text_circle"
          ></RotateCounter>
        </div>
      </div>

      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          paddingLeft: isMobile && stage < 2 ? '16px' : 0,
          transform: 'translate(-50%, -50%)',
          transition: 'all 0.5s',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline',
          width: stage >= 2 ? '60%' : '100%',
          zIndex: 1,
        }}
      >
        <p
          style={{
            display: 'inline',
            position: 'relative',
            textWrap: isMobile ? 'wrap' : 'nowrap',
            color: 'red',
            fontSize:
              stage >= 2
                ? isMobile
                  ? '16px'
                  : '32px'
                : isMobile
                  ? '56px'
                  : '96px',
            fontWeight: '600',
            marginTop: stage >= 2 ? '0' : '56px',
            transition: 'all 0.5s',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: stage >= 2 ? 'center' : 'left',
          }}
        >
          {/*NOTHING IS IMPOSSIBLE*/}
          {fullText1.slice(0, text1Index)}
          <span style={{ color: 'transparent' }}>
            {fullText1.slice(text1Index, fullText1.length)}
          </span>
        </p>
        <p
          style={{
            display: 'inline',
            position: 'relative',
            textWrap: 'nowrap',
            color: 'white',
            fontSize: stage >= 2 ? (isMobile ? '16px' : '32px') : '96px',
            fontWeight: '600',
            marginBottom: stage >= 2 ? (isMobile ? '16px' : '72px') : 0,
            transition: 'all 0.5s',
            left: '50%',
            transform: 'translateX(-50%)',
            opacity: stage >= 2 ? 1 : 0,
          }}
        >
          KEEP GOING
        </p>
        <p
          style={{
            display: 'inline',
            position: 'relative',
            color: 'white',
            fontSize:
              stage >= 2
                ? isMobile
                  ? '16px'
                  : '32px'
                : isMobile
                  ? '56px'
                  : '128px',
            fontWeight: '600',
            marginBottom: stage >= 2 ? '16px' : '36px',
            transition: 'all 0.5s',
            opacity: stage >= 1 ? 1 : 0,
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: stage >= 2 ? 'center' : 'left',
          }}
        >
          실패 없는 개발, 로동.
        </p>
        <p
          style={{
            display: 'inline',
            position: 'relative',
            color: 'white',
            fontSize: isMobile ? '10px' : '16px',
            transition: 'all 0.5s',
            opacity: stage >= 2 ? 1 : 0,
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
          }}
        >
          FAILURE-FREE, EXPERT-DRIVEN, CUSTOMER-FOCUSED DEVELOPMENT
        </p>
      </div>

      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          paddingTop: '160px',
          display: 'flex',
          flexDirection: 'column',
          gap: '160px',
          opacity: stage >= 4 ? 0.6 : 0,
          transition: 'all 3s',
          zIndex: 0,
        }}
      >
        <div
          style={{
            color: 'black',
            fontSize: '80px',
            fontWeight: '600',
            width: 'fit-content',
            position: 'relative',
            left: '0',
            transform: 'translateX(-50%)',
            textWrap: 'nowrap',
          }}
        >
          DEVELOPING DIGITAL SOLUTION
        </div>
        <div
          style={{
            color: 'black',
            fontSize: '60px',
            fontWeight: '600',
            width: 'fit-content',
            alignSelf: 'end',
            transform: 'translateX(50%)',
            textWrap: 'nowrap',
          }}
        >
          DEVELOPING DIGITAL SOLUTION
        </div>
        <div
          style={{
            color: 'black',
            fontSize: '110px',
            fontWeight: '600',
            width: 'fit-content',
            alignSelf: 'center',
            textWrap: 'nowrap',
          }}
        >
          DEVELOPING DIGITAL SOLUTION
        </div>
      </div>
    </div>
  );
}

export default TextShow;
