import API_ENDPOINTS from '../../../api/endpoints/apiEndpoints';
import { LoginData, LoginResponse } from './AuthInterfaces';
import { BaseResponse } from '../UtilsInterface';
import { loginApiClient } from '../../../api/apiClient';

const AuthService = {
  // 일반 로그인 요청
  login: async (loginBody: LoginData): Promise<LoginResponse> => {
    try {
      const response = await loginApiClient.post(
        API_ENDPOINTS.post_login(),
        loginBody
      );

      return response.data as LoginResponse;
    } catch (error) {
      console.error('로그인 실패:', error);
      throw error;
    }
  },
  // 로그아웃 요청
  logout: async (): Promise<BaseResponse<string>> => {
    try {
      const response = await loginApiClient.post(API_ENDPOINTS.post_logout());

      localStorage.clear();
      sessionStorage.clear();
      document.cookie.split(';').forEach((cookie) => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie =
          name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
      });

      return response.data;
    } catch (error) {
      console.error('로그아웃 실패:', error);
      throw error;
    }
  },
};

export default AuthService;
