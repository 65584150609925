import styled from 'styled-components';
import Logo from './Logo';
import DesktopMenus from './DesktopMenus';
import SideMenus from './SideMenus';
import MobileMenuIcon from './MobileMenuIcon';
import MobileMenuDrawer from './MobileMenuDrawer';
import { useState } from 'react';

const TopBarContainer = styled.div<{ variant: 'light' | 'dark' }>`
  background-color: ${(props) =>
    props.variant === 'dark' ? 'rgb(32,32,32)' : 'white'};
  height: 100px;
  padding: 0 15%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1440px) {
    padding: 0 10%;
  }
`;

const RightContianer = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1440px) {
    gap: 10px;
    justify-content: flex-end;
  }
`;

function DesktopTopBar({ variant = 'dark' }: { variant?: 'light' | 'dark' }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  return (
    <TopBarContainer variant={variant}>
      <Logo />
      <RightContianer>
        <DesktopMenus variant={variant} />
        <SideMenus />
        <MobileMenuIcon toggleMenu={toggleMenu} />
      </RightContianer>
      <MobileMenuDrawer isOpen={isMenuOpen} closeMenu={closeMenu} />
    </TopBarContainer>
  );
}

export default DesktopTopBar;
