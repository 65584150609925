import PictureBanner from '../../../components/PictureBanner';

import { useIsMobile } from '../../../hooks/useIsMobile';
import styled from 'styled-components';
import building_banner from '../../../assets/images/introductionPage/building_banner.png';
import decoration from '../../../assets/images/decoration.svg';
import FloatingButtonGroup from '../../../components/user/mainPage/FloatingButton';
import TopBar from '../../../components/user/topBar/TopBar';

const HeaderTextContainer = styled.div`
  position: relative;
  height: 240px;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    height: 100px;
  }
`;

const HeaderText = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.5rem;
  padding-left: 15%;
  max-width: 1920px;
  margin: 0 auto;
  @media (max-width: 1440px) {
    padding-left: 10%;
  }
  @media (max-width: 1024px) {
    padding-left: 5%;
    line-height: 1.2rem;
    font-size: 12px;
  }
`;

const ContentContainerBox = styled.div`
  width: 100%;
  background-color: #26262605;
`;
const ContentContainer = styled.div`
  padding: 64px 15%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;

  font-size: 18px;
  line-height: 2.5rem;
  gap: 0; /* 기본값 */
  flex-direction: row; /* 기본값 */

  @media (max-width: 1440px) {
    padding: 64px 10%;
  }
  @media (max-width: 1024px) {
    height: auto;
    flex-direction: column-reverse;
    gap: 24px;
    padding: 24px 5%;
    font-size: 12px;
    line-height: 1.2rem;

    > * {
      width: auto;
      flex-grow: 1;
    }
  }
`;
const BoldContentText = styled.p`
  font-size: 20px;
  font-weight: bolder;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

function CEOIntroductionPage() {
  const isMobile = useIsMobile();

  return (
    <div>
      <FloatingButtonGroup />
      <TopBar variant="dark" />

      <PictureBanner
        title="COMPANY"
        subtitle={isMobile ? '' : 'CEO 인사말'}
        titleColor="#000"
        subtitleDecoration={!isMobile}
        imagePath={building_banner}
      />

      <HeaderTextContainer>
        <img
          src={decoration}
          style={{
            position: 'absolute',
            right: '20%',
            top: 0,
            height: '100%',
          }}
          alt="decoration"
        ></img>
        <HeaderText>
          로동은 남들보다 더 많이 일하고, 남들보다 더 창의적으로 생각하여
          <br></br>
          <span
            style={{
              color: 'red',
            }}
          >
            독보적인 경쟁력
          </span>
          과{' '}
          <span
            style={{
              color: 'red',
            }}
          >
            차별화된 가치
          </span>
          를 제공합니다.
        </HeaderText>
      </HeaderTextContainer>
      <ContentContainerBox>
        <ContentContainer>
          <div>
            <p
              style={{
                color: '#262626',
                wordBreak: 'keep-all',
                textWrap: 'balance',
              }}
            >
              존경하는 고객 여러분께,<br></br>
              주식회사 로동의 대표 조이준입니다.
              <br></br>
              <br></br>
              먼저, 저희 회사의 홈페이지를 방문해 주신 것에 깊은 감사의 말씀을
              드립니다.
              <br></br>
              <br></br>
              저희 로동은 남들보다 더 노력하고 창의적인 접근을 통해, 고객
              여러분께 독보적인 경쟁력과 차별화된 가치를 제공하는 것을 목표로
              하고 있습니다. 어떠한 도전에도 굴하지 않으며, 완벽한 성과를 이루기
              위해 모든 역량을 집중하고 있습니다. 철저한 준비와 창의적인
              해결책을 통해, 언제나 고객의 기대를 뛰어넘는 성과를 창출하기 위해
              최선을 다하고 있습니다.
              <br></br>
              <br></br>
              함께 성장하는 가치를 소중히 여기며, 고객과 파트너 모두가 상생할 수
              있는 환경을 조성하고 있습니다. 저희의 성공은 고객 여러분과 함께
              이루어지는 것이며, 이를 통해 더 나은 미래를 함께 만들어가기를
              바랍니다. 저희 주식회사 로동은 언제나 고객과 함께하며, 변화를
              주도하는 길을 함께 걸어갈 것입니다.<br></br>
              감사합니다.
            </p>
            <BoldContentText
              style={{
                marginTop: '48px',
              }}
            >
              (주)로동 대표이사 조이준
            </BoldContentText>
          </div>
        </ContentContainer>
      </ContentContainerBox>
    </div>
  );
}

export default CEOIntroductionPage;
