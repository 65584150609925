import React from 'react';
import styled from 'styled-components';
import homepage_ob from '../../../assets/images/homepageProductionPage/homepage-ob.svg';
import homepage_arrow from '../../../assets/images/homepageProductionPage/homepage_arrow.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import {
  MdCall,
  MdContactEmergency,
  MdCreditScore,
  MdAssignment,
  MdRemoveFromQueue,
  MdWidthNormal,
  MdRateReview,
  MdMemory,
  MdComputer,
} from 'react-icons/md';

const Container = styled.div`
  display: flex;
  gap: 32px;
  padding-top: 80px;
  @media (max-width: 1024px) {
    align-items: center;
    flex-direction: column;
    align-items: stretch; /* 자식 요소가 부모 높이를 채우도록 설정 */
  }
`;

const DescriptionSection = styled.div`
  position: relative;
  flex: 1;
  padding: 47px 30px 47px 30px;
  max-width: 374px;
  height: 490px;
  background: #f8f8f8;
  color: #262626;
  gap: 40px;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 80% */
  }

  p {
    color: rgba(38, 38, 38, 0.6);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 162.5% */
  }

  @media (max-width: 1600px) {
    padding: 30px 20px;
    max-width: 350px;
    height: 550px;
  }

  @media (max-width: 1024px) {
    padding: 30px 30px 207px 30px;
    max-width: 100%;
    height: 490px !important;
  }
`;

const OBimg = styled.img`
  width: 110px;
  position: absolute;
  bottom: 47px;
  right: 30px;
`;

const StepsSection = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3열 */
  grid-gap: 16px; /* 카드 간 간격 */
  @media (max-width: 1024px) {
    display: none;
  }
`;
const StepsSection2 = styled.div`
  display: none;
  @media (max-width: 1024px) {
    overflow-x: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
`;

const StepCard = styled.div`
  width: 100%;
  border-radius: 10px;
  text-align: center;

  p {
    font-size: 14px;
    color: #555;
  }
`;

const StepHead = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px 10px 0px 0px;
  padding: 15px 30px;
  width: 100%;
  height: 52px;
  background-color: rgba(38, 38, 38, 1);
  color: #fff;
  @media (max-width: 1600px) {
    padding: 15px;
  }
`;

const IconWrapper = styled.div`
  font-size: 18px;
`;

const Title = styled.h1`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 122.222% */
`;

const Description = styled.div`
  border: 1px solid #ddd;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px 0px 10px 10px;
  padding: 30px;
  width: 100%;
  background-color: #fff;
  color: #fff;
  @media (max-width: 1600px) {
    padding: 30px 15px;
  }
`;

const Num = styled.h1`
  font-family: 'Raleway', serif;
  color: rgba(38, 38, 38, 0.15);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 78.571% */
`;

const Desc = styled.p`
  text-align: left;
  word-break: keep-all;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 164.286% */
  white-space: pre-line; /* \n을 줄바꿈으로 처리 */
`;

const steps = [
  {
    title: '제작상담',
    description: `홈페이지 제작에 필요한\n 사항들을 협의합니다.`,
    icon: <MdCall />,
  },
  {
    title: '템플릿 선택',
    description: `원하는 사업이 있을 경우\n 템플릿을 선택합니다.`,
    icon: <MdContactEmergency />,
  },
  {
    title: '제작비용 결제',
    description: `제작비용에 대한 결제 후\n 메인시안 작업을 진행합니다.`,
    icon: <MdCreditScore />,
  },
  {
    title: '자료수집',
    description: `제작에 필요한 자료를\n 제공받아 분석합니다.`,
    icon: <MdAssignment />,
  },
  {
    title: '초안제작',
    description: '제공받은 자료를 바탕으로\n 메인시안 작업을 진행합니다.',
    icon: <MdRemoveFromQueue />,
  },
  {
    title: '페이지 셋팅',
    description: '전체 페이지를 결합 후\n 나머지 작업을 진행합니다.',
    icon: <MdWidthNormal />,
  },
  {
    title: '검수 및 수정',
    description: '오탈자 및 레이아웃 검수 후\n 수정작업을 진행합니다.',
    icon: <MdRateReview />,
  },
  {
    title: '개발',
    description: '홈페이지 운영을 위한\n 개발작업을 진행합니다.',
    icon: <MdMemory />,
  },
  {
    title: '홈페이지 오픈',
    description: '최종 점검 후\n 홈페이지를 정식 오픈합니다.',
    icon: <MdComputer />,
  },
];

const HomePageStepsComponent = () => {
  return (
    <Container>
      <DescriptionSection>
        <h2>
          하나의 홈페이지가
          <br /> 완성되기까지의 제작과정
        </h2>
        <p>
          홈페이지는 고객과 함께 만들어가는 작업입니다. 고객의 요청사항을 세밀히
          반영하여 원하는 홈페이지가 만들어지도록 이끌어 갑니다.
        </p>
        <img src={homepage_arrow} alt="homepage_arrow" />
        <OBimg src={homepage_ob} alt="homepage_ob" />
      </DescriptionSection>
      <StepsSection>
        {steps.map((step, index) => (
          <StepCard key={index}>
            <StepHead>
              <IconWrapper>{step.icon}</IconWrapper>
              <Title>{step.title}</Title>
            </StepHead>
            <Description>
              <Num>0{index + 1}</Num>
              <Desc>{step.description}</Desc>
            </Description>
          </StepCard>
        ))}
      </StepsSection>

      <StepsSection2>
        <Swiper
          spaceBetween={20}
          slidesPerView={1.1} // 기본 값
          style={{ marginRight: '2px' }}
        >
          {steps.map((step, index) => (
            <SwiperSlide key={index}>
              <StepCard key={index}>
                <StepHead>
                  <IconWrapper>{step.icon}</IconWrapper>
                  <Title>{step.title}</Title>
                </StepHead>
                <Description>
                  <Num>0{index + 1}</Num>
                  <Desc>{step.description}</Desc>
                </Description>
              </StepCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </StepsSection2>
    </Container>
  );
};

export default HomePageStepsComponent;
