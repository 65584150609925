import React from 'react';
import styled from 'styled-components';
import bghosting from '../../../../assets/images/servicesPage/serverHostingServicesPage/bghosting.svg';
import bghostingM from '../../../../assets/images/servicesPage/serverHostingServicesPage/bghostingM.svg';
import ServerHosting2 from '../../../../assets/images/servicesPage/serverHostingServicesPage/ServerHosting2.png';
import ServerHosting2M from '../../../../assets/images/servicesPage/serverHostingServicesPage/ServerHosting2M.png';

// 전체 컨테이너
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
  @media (max-width: 1024px) {
    gap: 35px;
  }
`;

// 제목 스타일
const BGImg = styled.img`
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  @media (max-width: 1024px) {
    max-width: 600px;
    content: url(${bghostingM}); /* 모바일용 이미지 */
    width: 100%; /* 모바일에서 너비 변경 */
    top: 95%;
  }
`;

const HostingOption = styled.div`
  display: none;
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    text-align: start;
  }
`;
const Title = styled.h1`
  @media (max-width: 1024px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 122.222% */
  }
`;
const Desc = styled.h1`
  @media (max-width: 1024px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
`;

const Img = styled.img`
  width: 100%;
  z-index: 2;
  @media (max-width: 1024px) {
    margin-top: 50px;
    max-width: 600px;
    content: url(${ServerHosting2M}); /* 모바일용 이미지 */
    width: 100%; /* 모바일에서 너비 변경 */
  }
`;

const HostingOptions = () => {
  const options = [
    {
      title: '서버 호스팅',
      description:
        '물리적 또는 가상 서버를 임대하여 독립적인 제어권을 가지고 대규모 애플리케이션을 운영하고자 하는 경우 적합합니다.',
    },
    {
      title: '웹 호스팅',
      description:
        '간단한 웹사이트 운영이 필요한 소규모 사용자에게 적합하며, 비용이 저렴하고 기술 지식이 적어도 사용할 수 있습니다.',
    },
    {
      title: '코로케이션',
      description:
        '서버 하드웨어를 직접 소유하고, 이를 데이터 센터에 배치하여 인프라 비용을 절감하고 보안 및 제어를 유지하려는 기업에 적합합니다.',
    },
  ];
  return (
    <Container>
      <BGImg src={bghosting} alt="bghosting" />
      {options.map((option, index) => (
        <HostingOption key={index}>
          <Title>{option.title}</Title>
          <Desc>{option.description}</Desc>
        </HostingOption>
      ))}
      <Img src={ServerHosting2} alt="ServerHosting2" />
    </Container>
  );
};

export default HostingOptions;
