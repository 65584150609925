import React from 'react';
import styled from 'styled-components';
import PictureBanner from '../../components/PictureBanner';
import { useIsMobile } from '../../hooks/useIsMobile';
import InquiryMobileForm from '../../components/user/InquiryPage/InquiryMobileForm';
import InquiryDesktopForm from '../../components/user/InquiryPage/InquiryDesktopForm';
import inquiryDecoration from '../../assets/images/inquiryPage/inquiryDecoration.svg';
import coffee_banner from '../../assets/images/inquiryPage/coffee_banner.png';
import TopBar from '../../components/user/topBar/TopBar';
import FloatingButtonGroup from '../../components/user/mainPage/FloatingButton';

const BackgroundDecoration = styled.img`
  position: absolute;
  filter: brightness(0.3);
  z-index: 0;
  width: 100%;
  top: 120px;

  @media (max-width: 1024px) {
    top: 0px;
    bottom: 0;
  }
`;

const InfoContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px 15%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  text-align: center;

  > p:first-child {
    word-break: keep-all;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 71.429% */
  }

  > p:last-child {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
  }

  @media (max-width: 1440px) {
    padding: 48px 10%;
  }

  @media (max-width: 1024px) {
    padding: 24px 5%;
    gap: 14px;

    > p:first-child {
      font-size: 12px;
      line-height: 18px; /* 163.636% */
    }

    > p:last-child {
      font-size: 10px;
      line-height: 20px; /* 200% */
    }
  }
`;

const FormContainer = styled.div`
  padding: 0 15%;
  max-width: 1920px;
  margin: 0 auto;
  padding-bottom: 280px;
  position: relative;
  z-index: 1;

  @media (max-width: 1440px) {
    padding: 0 10%;
  }
  @media (max-width: 1024px) {
    padding: 0 5%;
    padding-bottom: 120px;
  }
`;

function InquiryPage() {
  const isMobile = useIsMobile();

  return (
    <div>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <PictureBanner
        title="견적문의"
        subtitle="로동만의 축적된 경험과 노하우로 고객감동을 위해 최적의 서비스를 제공합니다."
        titleColor="white"
        imagePath={coffee_banner}
      />
      <div
        style={{
          background: '#262626',
          position: 'relative',
        }}
      >
        <BackgroundDecoration src={inquiryDecoration} alt="" />
        <InfoContainer>
          <p>
            제작에 대한 문의를 남겨주시면 검토후 이메일 발송 후 담당자에게
            연락드리겠습니다
          </p>
          <p>유선문의 : 031-444-1162 / Email : cyj@lodong.co.kr</p>
        </InfoContainer>
        <FormContainer>
          {isMobile ? <InquiryMobileForm /> : <InquiryDesktopForm />}
        </FormContainer>
      </div>
    </div>
  );
}

export default InquiryPage;
