import { useEffect } from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { kakao } = window as any;

const Screen = styled.div`
  position: relative;
  height: 100vh;
  padding: 24px 0px;
  max-width: 1920px;
  margin: 0px auto;

  /* 모바일 (portrait) 디자인 */
  @media (max-width: 1024px) {
    height: auto;
  }
`;

const ContentContainer = styled.div`
  /* 데스크탑 (landscape) 디자인 */
  position: absolute;
  width: 100%;
  padding-left: 15%;
  right: 0;
  bottom: 50%;
  transform: translateY(calc(600px / 2));

  @media (max-width: 1440px) {
    padding-left: 10%;
  }

  /* 모바일 (portrait) 디자인 */
  @media (max-width: 1024px) {
    position: relative;
    padding-left: 0%;
    /* width는 그대로 100% 유지 */
    transform: none;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row; /* 데스크탑: 가로 배열 */

  @media (max-width: 1024px) {
    /* 모바일: 세로 배열 */
    flex-direction: column;
  }

  span {
    &:nth-child(1) {
      font-weight: bold;
      font-size: 2.5rem; /* 데스크탑 */
      text-align: center;

      @media (max-width: 1024px) {
        font-size: 24px; /* 모바일 */
      }
    }

    &:nth-child(3) {
      color: #26262605;
      font-weight: 900;
      text-align: center;
      font-size: 100px; /* 데스크탑 */
      margin-bottom: -24px;
      margin-right: 0px;

      @media (max-width: 1024px) {
        font-size: 50px; /* 모바일 */
      }
      @media (max-width: 360px) {
        font-size: 40px;
      }
    }
  }
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: row; /* 데스크탑: 가로 배열 */
  margin: 0;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    /* 모바일: 세로 역순 배열 */
    flex-direction: column-reverse;
    margin: 24px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* 데스크탑: */
  background-color: #262626;
  padding: 36px;
  padding-right: 144px;
  color: white;
  font-size: 0.9rem;

  @media (max-width: 1024px) {
    /* 모바일: */
    background-color: black;
    padding: 20px;
  }
`;

const MapContainer = styled.div`
  /* 데스크탑: */
  width: 70dvw;
  height: 600px;

  @media (max-width: 1024px) {
    /* 모바일: */
    width: 100%;
    height: 270px;
  }
`;

const Header = styled.p`
  margin-bottom: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Content = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */

  &:last-child {
    margin-bottom: 0;
  }
`;

export function ContactUs() {
  return (
    <Screen>
      <ContentContainer>
        <TitleContainer>
          <span>찾아 오시는 길</span>
          <div style={{ flexGrow: 1 }}></div>
          <span>CONTACT US</span>
        </TitleContainer>

        <ContactInfoContainer>
          <ContactInfo>
            <div>
              <Header>주소</Header>
              <Content>
                경기도 안양시 만안구 덕천로 152번길 25
                <br />
                아이에스BIZ타워 B동 1506호
              </Content>
            </div>
            <div>
              <Header>지하철</Header>
              <Content>1호선 명학역 2번 출구에서 도보 15분</Content>
            </div>
            <div>
              <Header>연락처</Header>
              <Content>
                TEL : 010-6575-1161 / 031-444-1162 <br />
                FAX : 031-460-8553 <br />
                EMAIL : cyj@lodong.co.kr
              </Content>
            </div>
            <div>
              <Header>버스</Header>
              <Content>
                범계역, 안양역 10번 아이에스비즈타워 하차 <br />
                명학역 5-3번 아이에스비즈타워 하차
              </Content>
            </div>
          </ContactInfo>
          {kakao ? <Map /> : null}
        </ContactInfoContainer>
      </ContentContainer>
    </Screen>
  );
}

export default ContactUs;

function Map() {
  const isMobile = useIsMobile();

  useEffect(() => {
    const mapDiv = document.getElementById('map');
    const options = {
      center: new kakao.maps.LatLng(37.392688, 126.937938),
      level: isMobile ? 6 : 3,
    };

    const map = new kakao.maps.Map(mapDiv, options);

    new kakao.maps.Marker({
      map: map,
      position: options.center,
    });
  }, [isMobile]);

  return <MapContainer id="map"></MapContainer>;
}
