import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import inquiry from '../../../assets/images/inquiry.svg';
import call from '../../../assets/images/call.svg';

const Button = styled.button`
  align-items: center;
  padding: 24px 12px;
  background-color: red;
  border: none;
  color: white;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 1024px) {
    padding: 18px 9px;
    font-size: 12px;
  }
`;

const ButtonGroup = styled.div`
  position: fixed;
  right: 0;
  top: 120px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 100;

  @media (max-width: 1024px) {
    gap: 4px;
  }
`;

export function FloatingButton({
  iconPath,
  text,
  onClick,
}: {
  iconPath: string;
  text: string;
  onClick: () => void;
}) {
  return (
    <Button onClick={onClick}>
      <img src={iconPath} alt={`${text} 아이콘`} />
      <p
        style={{
          marginTop: '16px',
          writingMode: 'vertical-rl',
          textOrientation: 'sideways',
        }}
      >
        {text}
      </p>
    </Button>
  );
}

function FloatingButtonGroup() {
  const navigate = useNavigate();
  return (
    <ButtonGroup>
      <FloatingButton
        iconPath={inquiry}
        text="문의하기"
        onClick={() => navigate('/inquiry')}
      />
      <FloatingButton
        iconPath={call}
        text="031)444-1162"
        onClick={() => {
          window.location.href = 'tel:031-444-1162';
        }}
      />
    </ButtonGroup>
  );
}

export default FloatingButtonGroup;
