import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  variant?: 'primary' | 'secondary' | 'white';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
}

const StyledButton = styled.button`
  background-color: transparent;
  padding: 10px 12px;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  color: #fff;
  font-size: 20px;
  line-height: 20px; /* 100% */

  &:hover {
    background-color: #fff;
    color: #000;
  }

  @media (max-width: 1024px) {
    padding: 10px;
  }
`;

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  disabled = false,
}) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
};

export default Button;
