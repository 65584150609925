import { useState } from 'react';
import styled from 'styled-components';

const MenuItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  font-weight: bold;
  cursor: pointer;

  /* 미디어 쿼리 추가 */
  @media (max-width: 1024px) {
    border-bottom: 1px solid #0000000d;

    &:first-child {
      border-top: 1px solid #0000000d;
    }
  }
`;

const MenuText = styled.span`
  flex-grow: 1;
`;

const SubmenuIndicator = styled.div<{ $isOpen: boolean }>`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: background-color 0.5s;
  background-color: ${(props) => (props.$isOpen ? 'black' : '#E7E7E7')};
`;

const SubmenuContainer = styled.div<{ $isOpen: boolean }>`
  overflow: hidden;
  max-height: ${(props) => (props.$isOpen ? '100vh' : '0')};
  transition: max-height 0.5s;
`;

function MobileTopBarMenu({
  text,
  onClick,
  children,
}: {
  text: string;
  onClick?: () => void;
  children?: React.ReactNode;
}) {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const handleClick = () => {
    if (children) {
      setIsSubmenuOpen(!isSubmenuOpen);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <div>
      <MenuItemContainer onClick={handleClick}>
        <MenuText>{text}</MenuText>
        <SubmenuIndicator $isOpen={isSubmenuOpen} />
      </MenuItemContainer>
      <SubmenuContainer $isOpen={isSubmenuOpen}>{children}</SubmenuContainer>
    </div>
  );
}

export default MobileTopBarMenu;
