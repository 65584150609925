import styled from 'styled-components';
import services_banner from '../../../assets/images/servicesPage/services_banner.png';
import { useIsMobile } from '../../../hooks/useIsMobile';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import ServiceDesktopTabSelector from './ServiceDesktopTabSelector';
import ServiceMobileTabSelector from './ServiceMobileTabSelector';

const Banner = styled.div`
  position: relative; /* 자식 요소의 absolute 위치 기준 */
  height: 300px;
  @media (max-width: 1024px) {
    height: 120px;
  }
`;

const BannerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BannerText = styled.p`
  position: absolute;
  top: 15%;
  color: 000;
  font-size: 48px;
  font-weight: bold;

  /* 화면이 1920px 이상일 때는 최대 너비 1920px 기준 오른쪽 여백 계산 */
  @media (min-width: 1920px) {
    right: calc((100% - 1920px) / 2 + 15%);
  }

  /* 화면이 1920px 이하일 때는 고정된 값 사용 */
  @media (max-width: 1920px) {
    right: 15%;
  }

  /* 추가로 화면이 1440px 이하일 때 */
  @media (max-width: 1440px) {
    right: 10%;
  }

  /* 모바일 (1024px 이하)에서는 폰트 크기와 위치 조정 */
  @media (max-width: 1024px) {
    font-size: 24px;
    right: 10%;
  }
`;

const Content = styled.div`
  margin-top: 64px;
  @media (max-width: 1024px) {
    margin-top: 32px;
  }
`;

const LoadedTopBar = styled.div`
  height: 130px;
  display: block;
  width: 100%;
  background-color: #fff;
  @media (max-width: 1024px) {
    height: 100px;
  }
`;

function ServicesBanner() {
  const [isLoaded, setIsLoaded] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    // 화면 크기 계산 후 로딩 완료
    setIsLoaded(true);
  }, [isMobile]);

  if (!isLoaded) {
    // 초기 로딩 중에도 배너는 표시합니다.
    return (
      <div>
        <Banner>
          <BannerImg src={services_banner} alt="banner" />
          <BannerText>서비스 소개</BannerText>
        </Banner>
        <LoadedTopBar />
      </div>
    );
  }
  return (
    <div>
      <Banner>
        <BannerImg src={services_banner} alt="banner" />
        <BannerText>서비스 소개</BannerText>
      </Banner>
      <Content>
        {isMobile ? (
          <ServiceMobileTabSelector />
        ) : (
          <ServiceDesktopTabSelector />
        )}
        <Outlet />
      </Content>
    </div>
  );
}

export default ServicesBanner;
