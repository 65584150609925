import React, { useState } from 'react';
import InquiryTypeSelector from './InquiryTypeSelector';
import PersonalInfoUseAgreementCheck from './PersonalInfoUseAgreementCheck';
import InquiryField from './InquiryField';
import styled from 'styled-components';
import InquireService, {
  SimpleInquireRequest,
  DetailedInquireRequest,
} from '../../../api/service/user/InquireService';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';

const Container = styled.div`
  background-color: #fff;
  padding: 0px 20px 32px 20px;
  border-radius: 16px;
`;

const FromContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`;
const SubmitButton = styled.button`
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 600;
  margin-top: 50px;
  display: flex;
  padding: 20px 70px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 30px;

  @media (max-width: 1024px) {
    display: inline-flex;
    padding: 6px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px;
  }
`;

const InquiryMobileForm = () => {
  const [isSimple, setIsSimple] = useState(true);
  const [checked, setChecked] = useState(false);
  const [formData, setFormData] = useState<
    SimpleInquireRequest & Partial<DetailedInquireRequest>
  >({
    inquireName: '',
    phoneNumber: '',
    senderEmail: '',
    content: '',
    expectedLaunchDate: '',
    inquireCompany: '',
    expectedPages: '',
    budget: '',
    projectType: '',
    currentSite: '',
    referenceSite: '',
    title: '',
  });

  const handleInputChange = (field: keyof typeof formData, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!checked) {
      alert('개인정보 제공에 동의해주세요.');
      return;
    }

    try {
      if (isSimple) {
        const {
          inquireName,
          phoneNumber,
          senderEmail,
          content,
          expectedLaunchDate,
        } = formData;

        if (
          !inquireName ||
          !phoneNumber ||
          !senderEmail ||
          !content ||
          !expectedLaunchDate
        ) {
          alert('모든 필수 입력란을 작성해주세요.');
          return;
        }

        const simpleRequest: SimpleInquireRequest = {
          inquireName,
          phoneNumber,
          senderEmail,
          content,
          expectedLaunchDate,
        };
        await InquireService.addSimpleInquire(simpleRequest);
        alert('간단문의를 성공적으로 전송했습니다.');
      } else {
        const detailedRequest: DetailedInquireRequest = {
          ...(formData as DetailedInquireRequest),
        };

        if (
          !detailedRequest.inquireName ||
          !detailedRequest.phoneNumber ||
          !detailedRequest.senderEmail ||
          !detailedRequest.content ||
          !detailedRequest.expectedLaunchDate ||
          !detailedRequest.inquireCompany ||
          !detailedRequest.title
        ) {
          alert('모든 필수 입력란을 작성해주세요.');
          return;
        }

        await InquireService.addDetailedInquire(detailedRequest);
        alert('상세문의를 성공적으로 전송했습니다.');
      }
    } catch (error) {
      alert('문의 전송 중 오류가 발생했습니다.');
      console.error(error);
    }
  };

  return (
    <>
      <Container>
        <InquiryTypeSelector
          isSimple={isSimple}
          onClick={() => setIsSimple(!isSimple)}
        />
        <FromContainer>
          {!isSimple && (
            <InquiryField
              label="회사명"
              value={formData.inquireCompany || ''}
              onChange={(value) => handleInputChange('inquireCompany', value)}
            />
          )}
          <InquiryField
            label="담당자 성함"
            value={formData.inquireName || ''}
            onChange={(value) => handleInputChange('inquireName', value)}
          />
          <InquiryField
            label="연락처"
            value={formData.phoneNumber || ''}
            onChange={(value) =>
              handleInputChange('phoneNumber', formatPhoneNumber(value))
            } // ✅ 자동으로 3-4-4 형식 변환
          />
          <InquiryField
            label="이메일"
            type="email"
            value={formData.senderEmail || ''}
            onChange={(value) => handleInputChange('senderEmail', value)}
          />
          {!isSimple && (
            <>
              <InquiryField
                label="예상 페이지수"
                value={formData.expectedPages || ''}
                onChange={(value) => handleInputChange('expectedPages', value)}
              />
              <InquiryField
                label="제작 예산"
                value={formData.budget || ''}
                onChange={(value) => handleInputChange('budget', value)}
              />
            </>
          )}
          <InquiryField
            label="예상 오픈일"
            type="date"
            value={formData.expectedLaunchDate || ''}
            onChange={(value) => handleInputChange('expectedLaunchDate', value)}
          />
          {!isSimple && (
            <>
              <InquiryField
                label="프로젝트 유형"
                value={formData.projectType || ''}
                onChange={(value) => handleInputChange('projectType', value)}
              />
              <InquiryField
                label="현재 사이트"
                value={formData.currentSite || ''}
                onChange={(value) => handleInputChange('currentSite', value)}
              />
              <InquiryField
                label="참고 사이트"
                value={formData.referenceSite || ''}
                onChange={(value) => handleInputChange('referenceSite', value)}
              />
              <InquiryField
                label="제목"
                value={formData.title || ''}
                onChange={(value) => handleInputChange('title', value)}
              />
            </>
          )}
          <InquiryField
            label="내용"
            value={formData.content || ''}
            onChange={(value) => handleInputChange('content', value)}
            fieldStyle={{
              minHeight: '200px',
            }}
            multiline={true}
          />
        </FromContainer>
        <PersonalInfoUseAgreementCheck
          checked={checked}
          onChange={setChecked}
        />
      </Container>
      <ButtonContainer>
        <SubmitButton onClick={handleSubmit}>문의하기</SubmitButton>
      </ButtonContainer>
    </>
  );
};

export default InquiryMobileForm;
