import React from 'react';
import styled from 'styled-components';
import DevelopmentArrow from '../../../../assets/images/servicesPage/developmentServicesPage/DevelopmentArrow.svg';

const Container = styled.div`
  width: 100%;
`;
const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    width: 35px;
  }
`;
const MContainer = styled.div`
  display: none;
  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
  }
`;
const PcContainer = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const Solution = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 50px 0px;
  background-color: #fff;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 20px 0px;
  }
`;
const SolutionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const SolutionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #000;
  color: #fff;
  gap: 15px;
  padding: 25px 0px 25px 50px;
  width: 100%;
  box-sizing: border-box;
`;

const SolutionTitle = styled.h3`
  width: 100%;
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 100% */
  color: #daa1a1;
`;

const Line = styled.h3`
  width: 100%;
  height: 2px;
  background-color: #fff;
`;

const Line2 = styled.h3`
  width: 100%;
  height: 1px;
  background-color: #daa1a1;
`;

const SolutionDescription = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 166.667% */
  color: #fff;
  white-space: pre-line;
`;

const DevelopmentSolutionList = () => {
  const solutions = [
    {
      title: 'ERP',
      description:
        '기업의 자원(재무, 인사, 물류 등)을 통합\n관리하여 운영 효율을 극대화',
    },
    {
      title: 'WMS',
      description: '창고 및 재고를 체계적으로 관리해\n물류 최적화 지원',
    },
    {
      title: 'MES',
      description: '제조 공정과 생산 현황을 실시간 관리하여\n생산성 향상',
    },
    {
      title: 'CRM',
      description: '고객 정보와 거래 기록을 분석해\n비즈니스 성과 극대화',
    },
    {
      title: 'LOT 플랫폼',
      description:
        '다양한 기기의 데이터를 실시간 연동해\n자동화된 운영 환경 제공',
    },
    {
      title: 'AI 및 빅데이터 솔루션',
      description: '데이터 분석과 AI 기술을 활용해\n비즈니스 인사이트 도출',
    },
  ];
  const solutions1 = [
    {
      title: 'ERP',
      description:
        '기업의 자원(재무, 인사, 물류 등)을 통합\n관리하여 운영 효율을 극대화',
    },
    {
      title: 'WMS',
      description: '창고 및 재고를 체계적으로 관리해\n물류 최적화 지원',
    },
    {
      title: 'MES',
      description: '제조 공정과 생산 현황을 실시간 관리하여\n생산성 향상',
    },
  ];
  const solutions2 = [
    {
      title: 'CRM',
      description: '고객 정보와 거래 기록을 분석해\n비즈니스 성과 극대화',
    },
    {
      title: 'LOT 플랫폼',
      description:
        '다양한 기기의 데이터를 실시간 연동해\n자동화된 운영 환경 제공',
    },
    {
      title: 'AI 및 빅데이터 솔루션',
      description: '데이터 분석과 AI 기술을 활용해\n비즈니스 인사이트 도출',
    },
  ];

  return (
    <Container>
      <ImgContainer>
        <img src={DevelopmentArrow} alt="DevelopmentArrow" />
      </ImgContainer>
      <PcContainer>
        <Solution>
          {solutions1.map((solution, index) => (
            <SolutionContainer key={index}>
              <SolutionTitle>{solution.title}</SolutionTitle>
              <SolutionItem>
                <Line />
                <SolutionDescription>
                  {solution.description}
                </SolutionDescription>
              </SolutionItem>
            </SolutionContainer>
          ))}
        </Solution>
        <Line2 color="#DAA1A1" />
        <Solution>
          {solutions2.map((solution, index) => (
            <SolutionContainer key={index}>
              <SolutionTitle>{solution.title}</SolutionTitle>
              <SolutionItem>
                <Line />
                <SolutionDescription>
                  {solution.description}
                </SolutionDescription>
              </SolutionItem>
            </SolutionContainer>
          ))}
        </Solution>
      </PcContainer>
      <MContainer>
        <Solution>
          {solutions.map((solution, index) => (
            <SolutionContainer key={index}>
              <SolutionTitle>{solution.title}</SolutionTitle>
              <SolutionItem>
                <Line />
                <SolutionDescription>
                  {solution.description}
                </SolutionDescription>
              </SolutionItem>
            </SolutionContainer>
          ))}
        </Solution>
      </MContainer>
    </Container>
  );
};

export default DevelopmentSolutionList;
