import React from 'react';
import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';

import AuthService from '../../../api/service/auth/AuthService';
import {
  AdminInquiryPageUrl,
  AdminLoginPageUrl,
  AdminPortfolioPageUrl,
} from '../../../url/lodongUrl';
import { MdMonitor } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import adminlogo from '../../../assets/images/adminlogo.png';

const SidebarContainer = styled.div<{ $isOpen: boolean }>`
  position: relative;
  width: 240px;
  background-color: #000;
  transition: transform 0.3s ease;
  z-index: 1000;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.1);

  @media (min-width: 969px) {
    transform: translateX(0);
  }
  @media (max-width: 968px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Overlay = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  @media (min-width: 969px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  margin-top: 50px;
  img {
    width: 80%;
  }
  @media (max-width: 969px) {
    img {
      width: 50%;
    }
  }
  @media (max-width: 569px) {
    img {
      width: 40%;
    }
  }
`;

const CloseButton = styled.button`
  display: none;
  @media (max-width: 968px) {
    display: block;
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
  }
`;

const SidebarBox = styled.div`
  padding: 50px 0px;
  width: 100%;
  gap: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (max-width: 968px) {
    padding: 20px 0px;
  }
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const MenuItemBase = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.whiteColor};

  span {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.active {
    background-color: #4c4c4c;
  }

  &:hover {
    background-color: #4c4c4c;
  }
`;

const Logout = styled.div`
  margin-top: 50px;
  color: ${(props) => props.theme.colors.whiteColor};
  &:hover {
    color: ${(props) => props.theme.colors.activeColor};
  }
`;
const SidebarLink = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const IconWrapper = styled.div`
  margin-right: 8px; /* 텍스트와 아이콘 사이 간격 */
  font-size: 20px;
  display: flex;
  align-items: center;
`;

const Sidebar: React.FC<{ isOpen: boolean; toggleSidebar: () => void }> = ({
  isOpen,
  toggleSidebar,
}) => {
  const navigate = useNavigate();

  const renderMenuItem = (to: string, text: string, Icon?: React.ReactNode) => (
    <MenuItemBase
      to={to}
      className={({ isActive }) => (isActive ? 'active' : '')}
    >
      {Icon && <IconWrapper>{Icon}</IconWrapper>}
      <span>{text}</span>
    </MenuItemBase>
  );

  // 로그아웃 클릭 이벤트
  const handleLogout = async () => {
    try {
      // 로그아웃 API 호출이 완료될 때까지 대기
      const response = await AuthService.logout();

      // API 호출이 성공적으로 완료된 후에만 스토리지 제거
      if (response) {
        sessionStorage.removeItem('authToken');
        localStorage.removeItem('authority');
        localStorage.removeItem('accessToken');

        console.log('로그아웃 성공');
        navigate(AdminLoginPageUrl);
      }
    } catch (error) {
      console.error('로그아웃 실패:', error);
      sessionStorage.removeItem('authToken');
      localStorage.removeItem('authority');
      localStorage.removeItem('accessToken');
      navigate(AdminLoginPageUrl);
    }
  };

  return (
    <>
      <Overlay $isOpen={isOpen} onClick={toggleSidebar} />
      <SidebarContainer $isOpen={isOpen}>
        <CloseButton onClick={toggleSidebar}>✕</CloseButton>
        <LogoContainer>
          <img src={adminlogo} alt="adminlogo" />
        </LogoContainer>
        <SidebarBox>
          <SidebarLink>
            <Menu>
              {renderMenuItem(AdminInquiryPageUrl, '견적문의', <FaEdit />)}
            </Menu>
            <Menu>
              {renderMenuItem(
                AdminPortfolioPageUrl,
                '포드폴리오',
                <MdMonitor />
              )}
            </Menu>
          </SidebarLink>
          <Logout onClick={handleLogout}>로그아웃</Logout>
        </SidebarBox>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
