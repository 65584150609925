import { useEffect, useMemo, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { useIsMobile } from '../../../hooks/useIsMobile';
import introduction_background from '../../../assets/images/mainPage/introduction_background.png';
import Button from '../../../components/Button';

/* ===============================
   Keyframes 정의
=============================== */

// 텍스트 컨테이너가 왼쪽에서 슬라이드되어 들어오는 애니메이션
const slideFromLeft = keyframes`
  0% {
    transform: translateX(calc(-100% - 100dvh));
  }
  25% {
    transform: translateX(0);
  }
`;

// 백그라운드의 페이드 인/아웃 애니메이션
const fadeInOut = keyframes`
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`;

// 소개 아이템들이 한 번에 위로 올라오는 애니메이션
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

/* ===============================
   Styled Components 정의
=============================== */

// OuterWrapper: 전체 뷰포트를 감싸는 최상위 컨테이너
const OuterWrapper = styled.div`
  height: 100dvh;
  view-timeline: --entire-view;
  position: relative;
  @media (max-width: 1024px) {
    height: auto;
  }
`;

// IntroductionContainer: 스티키 영역 (전체 화면)
const IntroductionContainer = styled.div`
  height: 100dvh;
  width: 100vw;
  view-timeline: --this-screen;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    height: auto;
    flex-direction: column;
  }
`;

// TextContainer: 좌측 텍스트와 버튼 영역 (slideFromLeft 애니메이션 적용)
const TextContainer = styled.div`
  color: white;
  font-size: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  z-index: 2;
  padding-left: 15%;
  animation: ${slideFromLeft} 0.1s ease-out forwards;

  > p:nth-child(3) {
    font-size: 3rem;
    margin-bottom: 128px;
  }

  @media (min-width: 1920px) {
    padding-left: calc((100% - 1920px) / 2 + 15%);
  }
  @media (max-width: 1440px) {
    padding-left: 10%;
  }
  @media (max-width: 1024px) {
    padding-left: 5%;
    padding-right: 16px;
    width: auto;
    height: auto;
    padding-top: 70px;
    position: relative;
    justify-content: flex-start;
    z-index: 2;
    flex-grow: 1;
    /* 모바일에서는 slide 애니메이션 제거 */
    animation: none;
  }
`;

// 배경 이미지
const Background = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;

  @supports (animation-timeline: --this-screen) {
    animation-timeline: --this-screen;
    animation-name: ${fadeInOut};
    animation-timing-function: ease-in;
  }
`;

// 소개 아이템 영역 오른쪽 여백 컨테이너
const IntroductionItemContainerContainer = styled.div`
  @media (min-width: 1920px) {
    padding-right: calc((100% - 1920px) / 2 + 15%);
  }
  @media (max-width: 1920px) {
    padding-right: 15%;
  }
  @media (max-width: 1440px) {
    padding-right: 10%;
  }
  @media (max-width: 1024px) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    padding-bottom: 20%;
  }
`;

// 소개 아이템들을 감싸는 컨테이너
const IntroductionItemContainer = styled.div`
  margin-top: 120px;
  height: calc(100% - 240px); /* 위, 아래 120px씩 */
  width: fit-content;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 24px;
  border-right: 2px solid red;
  z-index: 2;

  > div {
    width: 320px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    gap: 20px;
    margin: 20px 5%;
    height: fit-content;
    flex-direction: column;
    padding-right: 0;
    align-items: center;
    overflow: clip;

    > div {
      width: 100%;
    }
  }
`;

// 상단 영역 (번호, 제목)
const SubMenuTop = styled.div`
  display: flex;
  align-items: end;
  gap: 20px;
`;

// 번호
const Number = styled.div`
  color: rgba(255, 255, 255, 0.2);
  font-family: 'Yaldevi Colombo ExtraLight';
  font-size: 96px;
  line-height: 84px;
  font-style: normal;
  font-weight: 300;
`;

// 제목
const Text = styled.div`
  font-size: 32px;
  color: #fff;
  font-style: normal;
  font-weight: 700;
`;

// 하단 영역 (부제목, 내용)
const SubMenuBottom = styled.div`
  margin-top: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
`;

// 부제목
const SubText = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
`;

// 내용
const SubText2 = styled.div`
  white-space: pre-line;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
`;

// 소개 아이템 래퍼 (모든 아이템이 같은 fadeInUp 애니메이션으로 함께 등장)
const IntroductionItemWrapper = styled.div`
  width: 320px;
  animation: ${fadeInUp} 0.5s ease-out forwards;
`;

/* ===============================
   기타 텍스트, 제목 스타일
=============================== */

const Desc = styled.p`
  text-align: start;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 150% */

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 32px; /* 160% */
  }
`;

const Title = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 85px; /* 177.083% */
  @media (max-width: 1024px) {
    font-size: 32px;
    line-height: 85px; /* 265.625% */
  }
`;

/* ===============================
   Main Component: Introduction
=============================== */

export function Introduction() {
  const entireViewRef = useRef<HTMLDivElement>(null);
  const thisScreenRef = useRef<HTMLDivElement>(null);
  const fromLeftRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useRef(false);
  const isMobile = useIsMobile();

  const shouldUseAnimationFallback = useMemo(() => {
    return !CSS.supports('view-timeline', '--this-screen');
  }, []);

  useEffect(() => {
    const parent = document;
    if (!parent) return;

    const handleScroll = () => {
      if (!shouldUseAnimationFallback) return;
      if (
        !entireViewRef.current ||
        !thisScreenRef.current ||
        !fromLeftRef.current ||
        !containerRef.current
      )
        return;

      const top = entireViewRef.current.getBoundingClientRect().top;
      const scrollProgress = -top / window.innerHeight;

      if (isIntersecting.current) {
        if (top < window.innerHeight) {
          fromLeftRef.current.style.transform = `translateX(${Math.min(-top, 0)}px)`;
        }

        const third = 1 / 3;
        const thisScrollProgress =
          Math.min(Math.max(0, scrollProgress - 0.3), 0.7) * (1 / 0.7);
        if (isMobile) {
          containerRef.current.childNodes.forEach((child, index) => {
            if (child instanceof HTMLElement) {
              child.style.transform = `translateX(${(third * index * (3 / 2) - thisScrollProgress) * window.innerWidth * 3}px)`;
            }
          });
        } else {
          containerRef.current.childNodes.forEach((child, index) => {
            if (child instanceof HTMLElement) {
              child.style.transform = `translateY(${Math.max(
                0,
                (third * (index + 1) - thisScrollProgress) *
                  window.innerHeight *
                  2.5
              )}px)`;
            }
          });
        }
      }
    };

    parent.addEventListener('scroll', handleScroll);
    return () => {
      parent.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, shouldUseAnimationFallback]);

  useEffect(() => {
    const element = entireViewRef.current;
    if (!element) return;
    const intersectionObserver = new IntersectionObserver((entries) => {
      const entry = entries[0];
      isIntersecting.current = entry.isIntersecting;
    });
    intersectionObserver.observe(element);
    return () => {
      intersectionObserver.unobserve(element);
    };
  }, [shouldUseAnimationFallback]);

  return (
    <OuterWrapper ref={entireViewRef}>
      <IntroductionContainer ref={thisScreenRef}>
        <Background src={introduction_background} alt="background" />
        <TextContainer ref={fromLeftRef}>
          <Desc>
            실패 없는 개발, 소외 없는 사회 <br />
            어제보다 나은 오늘, 오늘보다 나은 세상
          </Desc>
          <Title>로동이 함께 걷습니다</Title>
          <Button>회사소개 바로가기</Button>
        </TextContainer>
        <IntroductionItemContainerContainer>
          <IntroductionItemContainer ref={containerRef}>
            <IntroductionItem
              number="01"
              title="신뢰"
              subtitle="고객만족과 믿음을 주는 기업"
              content={
                '로동은 고객의 신뢰를 위해 최우선으로 생각하며\n함께 성장하는 파트너로서의 가치를 지향합니다'
              }
            />
            <IntroductionItem
              number="02"
              title="기술"
              subtitle="혁신적이고 획기적인 기술력을 가진 기업"
              content={
                '최고의 기술력과 열정을 가지고 사용자들에게\n혁신적인 능력을 제공하려고 합니다'
              }
            />
            <IntroductionItem
              number="03"
              title="소통"
              subtitle="고객과의 소통을 최우선으로 하는 기업"
              content={
                '신뢰와 투명성을 바탕으로 국가경제의 발전과\n더 나은 사회적 가치를 위해 기여하겠습니다'
              }
            />
          </IntroductionItemContainer>
        </IntroductionItemContainerContainer>
      </IntroductionContainer>
    </OuterWrapper>
  );
}

function IntroductionItem({
  number,
  title,
  subtitle,
  content,
}: {
  number: string;
  title: string;
  subtitle: string;
  content: string;
}) {
  return (
    <IntroductionItemWrapper>
      <SubMenuTop>
        <Number>{number}</Number>
        <Text>{title}</Text>
      </SubMenuTop>
      <SubMenuBottom>
        <SubText>{subtitle}</SubText>
        <SubText2>{content}</SubText2>
      </SubMenuBottom>
    </IntroductionItemWrapper>
  );
}
