import TopBar from '../../../components/user/topBar/TopBar';
import ServicesBanner from '../../../components/user/servicesPage/ServicesBanner';
import React from 'react';
import ServicesTitleComponent from '../../../components/user/servicesPage/ServicesTitleComponent';
import styled from 'styled-components';
import { IoArrowForwardOutline } from 'react-icons/io5';
import ServerHosting1 from '../../../assets/images/servicesPage/serverHostingServicesPage/ServerHosting1.png';
import ServerHosting1M from '../../../assets/images/servicesPage/serverHostingServicesPage/ServerHosting1M.png';
import HostingUsageSteps from '../../../components/user/servicesPage/ServerHosting/HostingUsageSteps';
import HostingServiceNotice from '../../../components/user/servicesPage/ServerHosting/HostingServiceNotice';
import HostingOptions from '../../../components/user/servicesPage/ServerHosting/HostingOptions';
import HostingServiceBox from '../../../components/user/servicesPage/ServerHosting/HostingServiceBox';
import { useNavigate } from 'react-router-dom';
import { ServerHostingServicesPricePageUrl } from '../../../url/lodongUrl';
import FloatingButtonGroup from '../../../components/user/mainPage/FloatingButton';

const Body = styled.div`
  display: flex;
  color: #262626;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 50px;
  max-width: 1920px;
  margin: 40px auto 0px;
  padding: 0 15% 100px;
  @media (max-width: 1440px) {
    padding: 0 10% 100px;
  }
  @media (max-width: 1024px) {
    margin-top: 32px;
    padding: 0 5% 100px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 14px;
  background-color: #000;
  color: #fff;
  border: none;
  padding: 20px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 83.333% */
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #333;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    display: inline-flex;
    padding: 6px 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1024px) {
  }
`;

const Title = styled.h1`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 20px; /* 75% */
  }
`;

const Subtitle = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 166.667% */
  br {
    display: none;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    br {
      display: block;
    }
  }
`;

// 개별 카드

const Img1 = styled.img`
  width: 100%;
  @media (max-width: 1024px) {
    max-width: 600px;
    content: url(${ServerHosting1M}); /* 모바일용 이미지 */
    width: 100%; /* 모바일에서 너비 변경 */
  }
`;

function ServerHostingServicesPage() {
  const navigate = useNavigate();
  return (
    <div>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <ServicesBanner />
      <ServicesTitleComponent title="서버 호스팅" />
      <Body>
        <Button onClick={() => navigate(ServerHostingServicesPricePageUrl)}>
          가격표 보러 가기 <IoArrowForwardOutline />
        </Button>
        <Container>
          <Title>함께 쓰면 절약되는 서버관리대행</Title>
          <Subtitle>
            연중무휴 24시간 전문 엔지니어가 <br />
            상시 관리하는 서버 시스템
          </Subtitle>
        </Container>
        <Img1 src={ServerHosting1} alt="ServerHosting1" />
        <HostingServiceBox />
        <HostingOptions />
        <HostingUsageSteps />
        <HostingServiceNotice />
        <Button onClick={() => navigate(ServerHostingServicesPricePageUrl)}>
          가격표 보러 가기 <IoArrowForwardOutline />
        </Button>
      </Body>
    </div>
  );
}

export default ServerHostingServicesPage;
