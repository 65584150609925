import { BaseResponse } from '../UtilsInterface';
import { apiClient } from '../../apiClient';
import API_ENDPOINTS from '../../../api/endpoints/apiEndpoints';

export interface CategoryData {
  id: number;
  mainCategory: string;
  subCategory: string | null;
  description: string;
  parentId: number | null;
}

const CategoryService = {
  // 모든 카테고리 조회
  getAllCategories: async (): Promise<BaseResponse<CategoryData[]>> => {
    try {
      const response = await apiClient.get(API_ENDPOINTS.get_all_categories());
      return response.data as BaseResponse<CategoryData[]>;
    } catch (error) {
      console.error('모든 카테고리 조회 실패:', error);
      throw error;
    }
  },

  // 하위 카테고리 조회
  getSubCategories: async (
    parentId: number
  ): Promise<BaseResponse<CategoryData[]>> => {
    try {
      const response = await apiClient.get(
        API_ENDPOINTS.get_sub_categories(parentId)
      );
      return response.data as BaseResponse<CategoryData[]>;
    } catch (error) {
      console.error('하위 카테고리 조회 실패:', error);
      throw error;
    }
  },

  // 1차 카테고리 조회
  getRootCategories: async (): Promise<BaseResponse<CategoryData[]>> => {
    try {
      const response = await apiClient.get(API_ENDPOINTS.get_root_categories());
      return response.data as BaseResponse<CategoryData[]>;
    } catch (error) {
      console.error('1차 카테고리 조회 실패:', error);
      throw error;
    }
  },
};

export default CategoryService;
