import React from 'react';
import styled from 'styled-components';

const ServiceContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 100px;
  @media (max-width: 1024px) {
    gap: 50px;
    padding: 0px;
  }
`;

const ServiceItem = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const RigthContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 300px;
`;

const Title = styled.div`
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  @media (max-width: 1024px) {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 46.875% */
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Line = styled.h3`
  position: relative;
  width: 100%;
  background: rgba(38, 38, 38, 0.8);
  height: 3px;
`;

const ServiceTitle = styled.h3`
  position: absolute;
  color: rgba(0, 0, 0, 0.02);
  bottom: -20px;
  right: 0px;
  font-size: 80px;
  font-style: normal;
  font-weight: 900;
  line-height: 100px; /* 30% */
  @media (max-width: 1024px) {
    display: none;
  }
`;

const ServiceDescription = styled.p`
  text-align: left;
  padding: 20px 0px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 181.818% */
  white-space: pre-line;
  @media (max-width: 1024px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 177.778% */
  }
`;

const DevelopmentServiceList = () => {
  const services = [
    {
      title: 'APP',
      description:
        'Android, iOS 등 다양한 플랫폼에 대응하는 맞춤형 앱 개발\n최적의 사용자 경험(UX/UI) 설계 및 개발',
    },
    {
      title: 'WEB',
      description:
        '반응형 웹, 포털, 이커머스 등 다양한 웹 플랫폼 개발\n최신 기술 적용으로 직관적이고 빠른 웹 환경 제공',
    },
    {
      title: 'SOFTWARE',
      description: '다양한 비즈니스 요구를 충족하는 맞춤형 소프트웨어 제공',
    },
  ];

  return (
    <ServiceContainer>
      {services.map((service, index) => (
        <ServiceItem key={index}>
          <RigthContainer>
            <Title>{service.title}</Title>
          </RigthContainer>
          <LeftContainer>
            <Line>
              <ServiceTitle>{service.title}</ServiceTitle>
            </Line>
            <ServiceDescription>{service.description}</ServiceDescription>
          </LeftContainer>
        </ServiceItem>
      ))}
    </ServiceContainer>
  );
};

export default DevelopmentServiceList;
