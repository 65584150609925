import TopBar from '../../../components/user/topBar/TopBar';
import ServicesBanner from '../../../components/user/servicesPage/ServicesBanner';
import 'swiper/css';
import styled from 'styled-components';
import ServicesTitleComponent from '../../../components/user/servicesPage/ServicesTitleComponent';
import video from '../../../assets/images/servicesPage/artfleetServicesPage/video.svg';
import ArtfleetFeaturesSection from '../../../components/user/servicesPage/Artfleet/ArtfleetFeaturesSection';
import artfleetservice from '../../../assets/images/servicesPage/artfleetServicesPage/artfleetservice.png';
import artfleetservice2 from '../../../assets/images/servicesPage/artfleetServicesPage/artfleetservice2.png';
import artfleetservice3 from '../../../assets/images/servicesPage/artfleetServicesPage/artfleetservice3.png';
import artfleetserviceM from '../../../assets/images/servicesPage/artfleetServicesPage/artfleetserviceM.png';
import artfleetservice2M from '../../../assets/images/servicesPage/artfleetServicesPage/artfleetservice2M.png';
import artfleetservice3M from '../../../assets/images/servicesPage/artfleetServicesPage/artfleetservice3M.png';
import FloatingButtonGroup from '../../../components/user/mainPage/FloatingButton';

const Body = styled.div`
  display: flex;

  color: #262626;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
  gap: 50px;
  padding: 0 15% 100px;
  @media (max-width: 1024px) {
    margin-top: 22px;
    padding: 0 5% 70px;
    gap: 0px;
  }
`;
const SubContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 2; /* 이미지 위에 표시되도록 설정 */
  padding: 10px 0px;
  @media (max-width: 1024px) {
  }
`;

const SubTitle = styled.h1`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;

  line-height: 20px; /* 60% */
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 14px; /* 85.714% */
  }
`;

const Box = styled.div`
  position: absolute;
  top: 17px;
  left: 50%; /* 부모 컨테이너 기준으로 수평 중앙 */
  transform: translateX(-50%); /* 자신의 너비의 절반만큼 왼쪽으로 이동 */
  width: 399px;
  height: 14px;
  background-color: rgba(170, 155, 139, 0.15);

  @media (max-width: 1024px) {
    max-width: 280px;
    height: 9px;
  }
`;

const SubContainer2 = styled.div`
  width: 100%;
  position: relative;
  padding: 30px 0px;
  @media (max-width: 1024px) {
  }
`;

const VideoImg = styled.img`
  position: absolute;
  z-index: 1; /* 글자 뒤로 보이도록 설정 */
  height: auto; /* 필요에 따라 조정 */
  top: -100px; /* 수직 중앙 정렬 */
  left: 50%; /* 부모 컨테이너 기준으로 수평 중앙 */
  transform: translateX(-250%); /* 자신의 너비의 절반만큼 왼쪽으로 이동 */
  @media (max-width: 1024px) {
    display: none;
  }
`;

const DescBord = styled.h1`
  word-break: keep-all;
  text-align: center;
  padding: 10px 0px;
  position: relative; /* 글자를 이미지 위에 배치하기 위해 설정 */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 50% */
  z-index: 2; /* 이미지 위에 표시되도록 설정 */
  color: #262626; /* 글자 색상 */
  background: none; /* 배경 투명화 */
  br {
    display: none;
  }
  @media (max-width: 1024px) {
    padding: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    br {
      display: block;
    }
  }
`;

const DescColor = styled.span`
  color: #aa9b8b;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;

  @media (max-width: 1024px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;

const SubContainer3 = styled.div`
  width: 100%;
  position: relative;
  z-index: 2; /* 이미지 위에 표시되도록 설정 */
  padding-top: 10px;
  padding-bottom: 70px;
  @media (max-width: 1024px) {
    padding-top: 0px;
    padding-bottom: 25px;
  }
`;

const BackTitle = styled.h1`
  position: absolute;
  top: -20px;
  left: 50%; /* 부모 컨테이너 기준으로 수평 중앙 */
  transform: translateX(-50%); /* 자신의 너비의 절반만큼 왼쪽으로 이동 */
  font-size: 100px;
  font-style: normal;
  font-weight: 900;
  color: #fafafa;
  white-space: nowrap; /* 줄바꿈 방지 */

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ResponsiveImage1 = styled.img`
  width: 100%;
  @media (max-width: 1024px) {
    max-width: 600px;
    content: url(${artfleetserviceM}); /* 모바일용 이미지 */
    width: 100%; /* 모바일에서 너비 변경 */
  }
`;

const ResponsiveImage2 = styled.img`
  width: 80%;
  @media (max-width: 1024px) {
    margin-top: 30px;
    max-width: 600px;
    content: url(${artfleetservice2M}); /* 모바일용 이미지 */
    width: 100%; /* 모바일에서 너비 변경 */
  }
`;

const SubContainer4 = styled.div`
  width: 100%;
  position: relative;
  z-index: 2; /* 이미지 위에 표시되도록 설정 */
  padding-top: 100px;
  padding-bottom: 70px;
  @media (max-width: 1024px) {
    padding-top: 70px;
    padding-bottom: 25px;
  }
`;

const BackTitle2 = styled.h1`
  position: absolute;
  top: 80px;
  left: 50%; /* 부모 컨테이너 기준으로 수평 중앙 */
  transform: translateX(-50%); /* 자신의 너비의 절반만큼 왼쪽으로 이동 */
  font-size: 100px;
  font-style: normal;
  font-weight: 900;
  color: #fafafa;
  white-space: nowrap; /* 줄바꿈 방지 */

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ResponsiveImage3 = styled.img`
  width: 60%;
  @media (max-width: 1024px) {
    max-width: 600px;
    content: url(${artfleetservice3M}); /* 모바일용 이미지 */
    width: 100%; /* 모바일에서 너비 변경 */
  }
`;

function ArtfleetServicesPage() {
  return (
    <div>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <ServicesBanner />
      <ServicesTitleComponent title="ARTFLEET" />
      <Body>
        <SubContainer>
          <SubTitle>각 분야의 동영상 강의를 제공하는 온라인 플랫폼</SubTitle>
          <Box />
        </SubContainer>
        <SubContainer2>
          <VideoImg src={video} alt="video" />
          <DescBord>
            사용자가 원하는 분야를 선택하여 <br />
            강의를 시청할 수 있는
            <DescColor> 맞춤형 학습 공간</DescColor>을 제공합니다.
          </DescBord>
          <ArtfleetFeaturesSection />
        </SubContainer2>
        <SubContainer3>
          <BackTitle>KEY WORD</BackTitle>
          <DescBord>
            키워드로 검색하거나 대표 키워드를 클릭하여 <br />
            원하는 강의를 쉽게 찾을 수 있는 <br />
            <DescColor> 직관적인 인터페이스 </DescColor>구성
          </DescBord>
        </SubContainer3>
        <ResponsiveImage1 src={artfleetservice} alt="artfleetservice" />
        <ResponsiveImage2 src={artfleetservice2} alt="artfleetservice" />
        <SubContainer4>
          <BackTitle2>QUESTION</BackTitle2>
          <DescBord>
            간단하고 명확한 인터페이스로, 사용자 경험을 <br />
            높이고
            <DescColor> 강의 관련 상담 요청을 쉽게 </DescColor>할 수 있습니다.
          </DescBord>
        </SubContainer4>
        <ResponsiveImage3 src={artfleetservice3} alt="artfleetservice" />
      </Body>
    </div>
  );
}

export default ArtfleetServicesPage;
