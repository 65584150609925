import React from 'react';
import styled from 'styled-components';
import { IoMenu } from 'react-icons/io5';
import HeaderLogo from '../../../assets/images/logo.png';

const HeaderContainer = styled.header`
  position: relative;
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 10px 20px;
  background-color: #bfbfbf;
  @media (max-width: 968px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    width: 100%;
    padding: 20px;
    gap: 8px;
  }
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;
const MenuButton = styled(IoMenu)`
  display: none;
  font-size: 24px;
  cursor: pointer;
  @media (max-width: 968px) {
    display: block;
  }
`;
const Logo = styled.img`
  width: 150px;
  @media (max-width: 968px) {
    width: 150px;
  }
`;

const Header: React.FC<{ toggleSidebar: () => void }> = ({ toggleSidebar }) => {
  return (
    <HeaderContainer>
      <HeaderBox>
        <Logo src={HeaderLogo} alt="Logo" />
        <MenuButton onClick={toggleSidebar} />
      </HeaderBox>
    </HeaderContainer>
  );
};

export default Header;
