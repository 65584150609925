import { ImageResponse } from '../api/service/UtilsInterface';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// ✅ 스타일 컴포넌트 정의
const ImageContainer = styled.div<{ $aspectRatio: number }>`
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding-bottom: ${(props) =>
    `${100 / props.$aspectRatio}%`}; // 원본 비율에 따라 높이 설정
`;

const UploadedImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; // 이미지 비율 유지하며 컨테이너에 맞춤
`;

interface ImagePreviewProps {
  image: ImageResponse;
  alt: string;
}

// ✅ ImagePreview 컴포넌트 정의
const ImagePreview2: React.FC<ImagePreviewProps> = ({ image, alt }) => {
  const [aspectRatio, setAspectRatio] = useState<number>(
    image.resizedWidth / image.resizedHeight // 초기값으로 resized 이미지 비율 사용
  );
  const [isOriginalLoaded, setIsOriginalLoaded] = useState<boolean>(false); // 원본 이미지 로드 상태

  // 이미지 로드 완료 핸들러
  const handleImageLoad = () => {
    setIsOriginalLoaded(true);
    setAspectRatio(image.originalWidth / image.originalHeight); // 원본 비율로 업데이트
  };

  useEffect(() => {
    // 로드된 이미지가 없을 경우, resized 비율 유지
    if (!isOriginalLoaded) {
      setAspectRatio(image.resizedWidth / image.resizedHeight);
    }
  }, [image, isOriginalLoaded]);

  return (
    <ImageContainer $aspectRatio={aspectRatio}>
      {/* Resized 이미지 */}
      <UploadedImage
        src={image.resizedUrl}
        alt={alt}
        style={{ opacity: isOriginalLoaded ? 0 : 1 }} // 원본 로드되기 전 표시
      />
      {/* Original 이미지 */}
      <UploadedImage
        src={image.originalUrl}
        alt={alt}
        onLoad={handleImageLoad} // 원본 이미지 로드 시 실행
        style={{
          opacity: isOriginalLoaded ? 1 : 0,
          transition: 'opacity 0.3s',
        }} // 전환 효과 추가
      />
    </ImageContainer>
  );
};

export default ImagePreview2;
