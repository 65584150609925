export interface MarketingServiceDetailEntry {
  name?: string;
  on: 'all' | 'basic' | 'premium';
}

export const marketingServiceDetailEntries: MarketingServiceDetailEntry[][] = [
  [
    { name: '프로젝트 기획', on: 'all' },
    { name: '월별 타임라인 보고서', on: 'all' },
    { name: '월간 리포트(인사이트) 보고서', on: 'all' },
    { name: '브랜디드 컨텐츠 8회', on: 'basic' },
    { name: '브랜디드 컨텐츠 12회', on: 'premium' },
    { name: '이벤트 및 프로모션 컨텐츠 2회', on: 'basic' },
    { name: '이벤트 및 프로모션 컨텐츠 4회', on: 'premium' },
    { name: '일러스트 컨텐트 2회', on: 'basic' },
    { name: '일러스트 컨텐트 4회', on: 'premium' },
  ],
  [
    { name: 'PIXEL, SDK 설치로 마케팅 환경 구축 및 보완', on: 'all' },
    { name: 'KAKAOTALK / SLACK/ NOTION 협업툴 제공', on: 'all' },
    { name: '연 팔로워 5천명 이상 확보', on: 'basic' },
    { name: '연 팔로워 만명 이상 확보', on: 'premium' },
    { name: '컨텐츠 좋아요 관리', on: 'all' },
    { name: 'AI 컨텐츠 무상 제공', on: 'all' },
    {
      name: '백만원 미만 단순 프로모션 광고 계약시 무상 업무지원',
      on: 'basic',
    },
    {
      name: '삼백만원 미만 단순 프로모션 광고 계약시 무상 업무지원',
      on: 'premium',
    },
    { name: '해킹 및 각종 계정 오류 지원', on: 'all' },
    { name: '자사 보유 분석툴 실시간 모니터링', on: 'premium' },
    { name: '브랜드 서포터즈 모집 및 관리', on: 'premium' },
  ],
  [
    { name: '이미지촬영', on: 'all' },
    { name: '영상컨텐츠', on: 'all' },
    { name: '퍼포먼스 마케팅', on: 'all' },
    { name: '별도협의', on: 'all' },
  ],
];
