import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    margin-top: 30px;
  }
`;
const Title = styled.h1`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 66.667% */
  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 18px; /* 111.111% */
  }
`;
const SubTitle = styled.h1`
  word-break: keep-all;
  text-align: center;
  margin-top: 30px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
  @media (max-width: 900px) {
    margin-top: 10px;
    font-size: 13px;
    line-height: 13px; /* 153.846% */
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 70px 0px 40px;
  @media (max-width: 900px) {
    padding: 25px 0px 30px;
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled.div`
  border-radius: 15px;
  border: 5px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 25px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  flex: 1;
  @media (max-width: 900px) {
    padding: 20px;
    gap: 20px;
  }
`;

const CardTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 83.333% */
`;

const CardSubTitle = styled.p`
  word-break: keep-all;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 111.111% */
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.05);
`;
const DescContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Desc = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
`;

const DescTitle = styled.h1`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;

const DescText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
`;

const Price = styled.p`
  color: #f00;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;

const Button = styled.button`
  margin: 20px 0px;
  width: 100%;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  &:hover {
    background-color: #1f1f1f;
  }

  @media (max-width: 900px) {
    margin: 10px 0px;
  }
`;

const AdditionalServicesContainer = styled.div`
  width: 100%;
  display: grid;
  align-items: start;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  @media (max-width: 900px) {
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
`;
const AdditionalServicesBox = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 50px;
  border: 5px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 15px;
  @media (max-width: 900px) {
    padding: 30px 20px;
  }
`;

const AdditionalServicesTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 83.333% */
  padding-bottom: 50px;
  @media (max-width: 900px) {
    padding-bottom: 30px;
  }
`;

const AdditionalTitleContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const AdditionalLine = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const AdditionalLineTitle = styled.h1`
  white-space: pre-line;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;
const AdditionalLineDesc = styled.p`
  color: #f00;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
`;

const VAT = styled.p`
  width: 100%;
  text-align: right;
  margin-top: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 85.714% */
`;

function ServerHostingServicesPriceTab2() {
  const plans = [
    {
      name: '일반',
      description: '소형 사이트 및 커뮤니티 운영 추천',
      specs: {
        cpu: '2 vCore',
        memory: '10GB',
        disk: '20GB (SSD)',
        ip: '1개',
        traffic: '500GB',
      },
      price: '20,000원',
    },
    {
      name: '비지니스',
      description: '중형 사이트 및 커뮤니티 운영 추천',
      specs: {
        cpu: '4 vCore',
        memory: '14GB',
        disk: '40GB (HDD)',
        ip: '1개',
        traffic: '500GB',
      },
      price: '40,000원',
    },
    {
      name: '고급',
      description: '중대형 사이트 및 커뮤니티 운영 추천',
      specs: {
        cpu: '6 vCore',
        memory: '20GB',
        disk: '60GB (HDD)',
        ip: '1개 (1180포트)',
        traffic: '500GB',
      },
      price: '60,000원',
    },
  ];

  const bandwidthPricing = [
    { range: '1GB ~ 10TB 초과', price: '113.72원(GB당)' },
    { range: '10TB ~ 50TB 초과', price: '107.40원(GB당)' },
    { range: '50TB ~ 150TB 초과', price: '88.45원(GB당)' },
    { range: '150TB 이상 초과', price: '63.18원(GB당)' },
  ];

  const additionalServices = [
    { name: 'SATA(HDD) 500GB', price: '월 20,000원' },
    { name: 'SSD 500GB', price: '월 25,000원' },
    { name: 'RAM 8GB', price: '월 5,000원' },
    { name: '모니터링 서비스', price: '월 20,000원' },
    { name: `보안서비스\n(IPS, 단독, 공용 방화벽)`, price: '월 150,000원' },
    {
      name: `백업 서비스\n(기본 100GB, 추가 GB당 100원)`,
      price: '월 20,000원',
    },
    {
      name: `백업 서비스\n(기본 100GB, 추가 GB당 100원)`,
      price: '월 20,000원',
    },
  ];

  return (
    <Container>
      <Title>웹 호스팅</Title>
      <SubTitle>
        저렴한 비용과 전문 엔지니어의 관리로 안정적인 웹사이트 운영
      </SubTitle>
      <CardContainer>
        {plans.map((plan, index) => (
          <Card key={index}>
            <CardTitle>{plan.name}</CardTitle>
            <CardSubTitle>{plan.description}</CardSubTitle>
            <Line />
            <DescContainer>
              <Desc>
                <DescTitle>CPU: </DescTitle>
                <DescText>{plan.specs.cpu}</DescText>
              </Desc>
              <Desc>
                <DescTitle>Memory: </DescTitle>
                <DescText>{plan.specs.memory}</DescText>
              </Desc>
              <Desc>
                <DescTitle>Disk(HDD): </DescTitle>
                <DescText>{plan.specs.memory}</DescText>
              </Desc>
              <Desc>
                <DescTitle>공인 IP: </DescTitle>
                <DescText>{plan.specs.memory}</DescText>
              </Desc>
              <Desc>
                <DescTitle>트래픽(월): </DescTitle>
                <DescText>{plan.specs.memory}</DescText>
              </Desc>

              <Desc>
                <DescTitle>이용료:</DescTitle>
                <Price>{plan.price}</Price>
              </Desc>
            </DescContainer>
            <Button onClick={() => alert(`${plan.name} 문의하기 클릭!`)}>
              문의하기
            </Button>
          </Card>
        ))}
      </CardContainer>
      <AdditionalServicesContainer>
        <AdditionalServicesBox>
          <AdditionalServicesTitle style={{ color: '#ff0000' }}>
            추가 트래픽 과금
          </AdditionalServicesTitle>
          <Line />
          <AdditionalTitleContainer>
            {bandwidthPricing.map((bandwidth, index) => (
              <AdditionalLine key={index}>
                <AdditionalLineTitle>{bandwidth.range}</AdditionalLineTitle>
                <AdditionalLineDesc style={{ color: '#000' }}>
                  {bandwidth.price}
                </AdditionalLineDesc>
              </AdditionalLine>
            ))}
          </AdditionalTitleContainer>
        </AdditionalServicesBox>
        <AdditionalServicesBox>
          <AdditionalServicesTitle>
            추가 부가서비스 안내
          </AdditionalServicesTitle>
          <Line />
          <AdditionalTitleContainer>
            {additionalServices.map((service, index) => (
              <AdditionalLine key={index}>
                <AdditionalLineTitle>{service.name}</AdditionalLineTitle>
                <AdditionalLineDesc>{service.price}</AdditionalLineDesc>
              </AdditionalLine>
            ))}
          </AdditionalTitleContainer>
        </AdditionalServicesBox>
      </AdditionalServicesContainer>
      <VAT>*(VAT 별도)</VAT>
    </Container>
  );
}

export default ServerHostingServicesPriceTab2;
