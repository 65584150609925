import React from 'react';
import styled from 'styled-components';
import menu from '../../../assets/images/menu.svg';

interface MobileMenuIconProps {
  toggleMenu: () => void;
}

const Icon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  @media (max-width: 1024px) {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const MobileMenuIcon: React.FC<MobileMenuIconProps> = ({ toggleMenu }) => {
  return <Icon src={menu} alt="menu" onClick={toggleMenu} />;
};

export default MobileMenuIcon;
