import FloatingButtonGroup from '../../components/user/mainPage/FloatingButton';
import ContactUs from '../../components/user/mainPage/ContactUs';
import TopBar from '../../components/user/topBar/TopBar';
import styled from 'styled-components';

const Container = styled.div``;

function LocationPage() {
  return (
    <Container>
      <FloatingButtonGroup />
      <TopBar variant="dark" />
      <ContactUs />
    </Container>
  );
}

export default LocationPage;
