import Layout from '../../components/admin/navbar/Layout';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InquireService, {
  InquireListData,
} from '../../api/service/user/InquireService';
import Pagination from '../../components/Pagination'; // ✅ 페이지네이션 컴포넌트 추가

const TableContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  background-color: #fff;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;

const Th = styled.th`
  background-color: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const AdminPortfolioPage: React.FC = () => {
  const [inquireList, setInquireList] = useState<InquireListData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchInquireList(currentPage);
  }, [currentPage]);

  // ✅ 문의 리스트 API 호출
  const fetchInquireList = async (page: number) => {
    try {
      const response = await InquireService.getInquireList(page, 20);
      setInquireList(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('문의 리스트 조회 실패:', error);
    }
  };

  // ✅ 페이지 변경 핸들러
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <Layout>
      <h2>견적문의</h2>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <Th>NO</Th>
              <Th>이름</Th>
              <Th>전화번호</Th>
              <Th>이메일</Th>
              <Th>예상 출시일</Th>
              <Th>등록일</Th>
            </tr>
          </thead>
          <tbody>
            {inquireList.length > 0 ? (
              inquireList.map((inquire, index) => (
                <tr key={index}>
                  <Td>{index}</Td>
                  <Td>{inquire.inquireName || '없음'}</Td>
                  <Td>{inquire.phoneNumber}</Td>
                  <Td>{inquire.senderEmail}</Td>
                  <Td>{inquire.expectedLaunchDate || '미정'}</Td>
                  <Td>{new Date(inquire.regDate).toLocaleDateString()}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <Td colSpan={6} style={{ textAlign: 'center' }}>
                  데이터가 없습니다.
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableContainer>

      {/* ✅ 페이지네이션 추가 */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </Layout>
  );
};

export default AdminPortfolioPage;
