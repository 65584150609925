import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import styled from 'styled-components';
import 'swiper/css/bundle';
import ProjectSummaryCard from '../../../components/ProjectSummaryCard';
import SubCategoryGroup from './SubCategoryGroup';
import SearchBar from './SearchBar';
import PortfolioService from '../../../api/service/user/PortfolioService';
import { PortfolioListData } from '../../../api/service/user/PortfolioService';
import CategoryService from '../../../api/service/user/CategoryService';
import { CategoryData } from '../../../api/service/user/CategoryService';

const PcProjectContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  @media (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 568px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type PortfolioPageTabProps = {
  categoryId: number | null;
};

const PortfolioPageTab1: React.FC<PortfolioPageTabProps> = ({ categoryId }) => {
  const [activeCategory, setActiveCategory] = useState<number | null>(null);
  const [portfolioData, setPortfolioData] = useState<PortfolioListData[]>([]);
  const [subCategories, setSubCategories] = useState<
    { id: number | null; name: string }[]
  >([{ id: null, name: '전체' }]);
  const [searchTerm, setSearchTerm] = useState<string>(''); // ✅ 검색어 상태 추가
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const searchTimeout = useRef<NodeJS.Timeout | null>(null); // ✅ 디바운싱을 위한 `useRef`

  useEffect(() => {
    if (categoryId !== null) {
      fetchSubCategories();
      fetchPortfolioData(0); // ✅ 첫 페이지 불러오기
    }
  }, [categoryId]);

  useEffect(() => {
    setPage(0);
    setPortfolioData([]);
    setHasMore(true);
    fetchPortfolioData(0); // ✅ 첫 페이지 불러오기
  }, [categoryId, activeCategory]);

  useEffect(() => {
    if (page > 0 && hasMore && !isLoading) {
      fetchPortfolioData(page);
    }
  }, [page]);

  // ✅ 1️⃣ 하위 카테고리 데이터 불러오기
  const fetchSubCategories = async () => {
    try {
      if (categoryId === null || categoryId === undefined) return;
      const response = await CategoryService.getSubCategories(categoryId);
      if (response) {
        const formattedSubCategories = response.data.map(
          (category: CategoryData) => ({
            id: category.id,
            name: category.subCategory || '기타',
          })
        );
        setSubCategories([
          { id: null, name: '전체' },
          ...formattedSubCategories,
        ]);
      }
    } catch (error) {
      console.error('하위 카테고리 조회 실패:', error);
    }
  };

  // ✅ 2️⃣ 포트폴리오 데이터 불러오기
  const fetchPortfolioData = async (page: number, query?: string) => {
    if (isLoading) return;
    if (!categoryId) return;
    setIsLoading(true);

    try {
      const response = await PortfolioService.getPortfolioList({
        page,
        size: 12,
        mainCategoryId: categoryId ?? undefined,
        subCategoryId: activeCategory ?? undefined,
        searchString: query ?? (searchTerm.trim() || undefined), // ✅ 최신 검색어를 반영
      });

      if (response && response.data.content.length > 0) {
        setPortfolioData((prev) =>
          page === 0
            ? response.data.content
            : [...prev, ...response.data.content]
        );
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('포트폴리오 리스트 조회 실패:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // ✅ 3️⃣ 검색어 변경 시 디바운싱 적용 (검색 최적화)
  const handleSearch = (term: string) => {
    setSearchTerm(term); // ✅ 검색어 상태 업데이트
    setPortfolioData([]); // ✅ 기존 데이터 즉시 제거하여 화면 갱신
    setPage(0); // ✅ 첫 페이지로 초기화

    // 기존 `setTimeout` 제거 (이전 요청 취소)
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    // 300ms 후 API 호출
    searchTimeout.current = setTimeout(() => {
      fetchPortfolioData(0, term); // ✅ 최신 검색어를 매개변수로 전달
    }, 300);
  };

  // ✅ 3️⃣ 마지막 요소 감지 (중복 실행 방지)
  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading || !hasMore) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLoading) {
          setTimeout(() => setPage((prevPage) => prevPage + 1), 500); // ✅ 중복 실행 방지
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  const isMobile = useIsMobile();

  return (
    <div>
      {!isMobile && (
        <SubCategoryGroup
          categories={subCategories}
          activeCategory={activeCategory}
          setActiveCategory={(categoryId) => {
            setPage(0);
            setPortfolioData([]);
            setActiveCategory(
              categoryId === activeCategory ? null : categoryId
            );
          }}
        />
      )}
      <SearchBar
        subCategories={subCategories}
        activeCategory={activeCategory}
        setActiveCategory={(categoryId) => {
          setPage(0);
          setPortfolioData([]);
          setActiveCategory(categoryId === activeCategory ? null : categoryId);
        }}
        searchTerm={searchTerm} // ✅ 검색어 상태 전달
        setSearchTerm={handleSearch} // ✅ 검색어 업데이트 함수 전달
      />
      {portfolioData.length === 0 && (
        // ✅ 데이터가 없을 때 표시할 메시지
        <p
          style={{
            textAlign: 'center',
            width: '100%',
            fontSize: '18px',
            color: '#888',
          }}
        >
          데이터가 없습니다.
        </p>
      )}
      <PcProjectContainer>
        {portfolioData.map((portfolio, index) => {
          if ((index + 1) % 3 === 0) {
            // ✅ 3개의 카드 중 마지막 카드에서 감지하도록 설정
            return (
              <div ref={lastElementRef} key={portfolio.idPortfolio}>
                <ProjectSummaryCard portfolio={portfolio} />
              </div>
            );
          } else {
            return (
              <ProjectSummaryCard
                key={portfolio.idPortfolio}
                portfolio={portfolio}
              />
            );
          }
        })}
      </PcProjectContainer>
      {isLoading && <p>Loading...</p>}
    </div>
  );
};

export default PortfolioPageTab1;
