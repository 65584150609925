import React from 'react';
import styled from 'styled-components';
import Icon1 from '../../../../assets/images/servicesPage/serverHostingServicesPage/HostingServiceNotice1.svg';
import Icon2 from '../../../../assets/images/servicesPage/serverHostingServicesPage/HostingServiceNotice2.svg';
import Icon3 from '../../../../assets/images/servicesPage/serverHostingServicesPage/HostingServiceNotice3.svg';
import Icon4 from '../../../../assets/images/servicesPage/serverHostingServicesPage/HostingServiceNotice4.svg';

// 전체 컨테이너
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

// 제목 스타일
const Title = styled.h2`
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 50% */
`;

// 카드 컨테이너
const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 80px;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 0px;
  }
`;

// 개별 카드
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  width: 100%;
  padding: 40px 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 1024px) {
    max-width: 600px;
    margin: auto;
  }
`;

// 아이콘 스타일
const Icon = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
  @media (max-width: 1280px) {
    width: 80px;
    height: 80px;
  }
`;

// 텍스트 스타일
const Description = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

const HostingServiceNotice = () => {
  return (
    <Container>
      <Title>서비스 이용 및 주의사항</Title>
      <CardContainer>
        <Card>
          <Icon src={Icon1} alt="과다 트래픽 발생" />
          <Description>
            과다 트래픽 발생시 서비스가
            <br /> 제한 될 수 있습니다
          </Description>
        </Card>
        <Card>
          <Icon src={Icon2} alt="불법 용도 사용" />
          <Description>
            불법 용도로 사용시
            <br /> 서비스가 차단 될 수 있습니다
          </Description>
        </Card>
        <Card>
          <Icon src={Icon3} alt="선납제로 운영" />
          <Description>선납제로 운영됩니다</Description>
        </Card>
        <Card>
          <Icon src={Icon4} alt="3일 지나면 차단" />
          <Description>
            선납일 기준 미납이 3일 지나면
            <br /> 자동으로 서비스가 차단됩니다
          </Description>
        </Card>
      </CardContainer>
    </Container>
  );
};

export default HostingServiceNotice;
