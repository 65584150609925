import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  display: flex;
  border-bottom: 1px solid #fff;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 200px;
  border-right: 1px solid #fff;
  background-color: #d6d6d6;
  font-weight: 400;

  @media (max-width: 700px) {
    width: 100%; /* 작은 화면에서 전체 너비 사용 */
    border-right: none; /* 경계선 제거 */
    border-bottom: 1px solid #fff;
  }
`;

const SectionRight = styled.div`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  display: flex;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.whiteColor};
  flex-wrap: wrap;
`;

const PortfolioFormField = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <Section>
    <Label>{label}</Label>
    <SectionRight>{children}</SectionRight>
  </Section>
);

export default PortfolioFormField;
