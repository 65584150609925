import { useEffect, useState } from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import portfolio_title_background from '../../../assets/images/mainPage/portfolio_title_background.svg';
import ProjectSummaryCard from '../../ProjectSummaryCard';
import styled from 'styled-components';
import CustomDropdown from '../../CustomDropdown';
import CategoryService from '../../../api/service/user/CategoryService';
import { CategoryData } from '../../../api/service/user/CategoryService';
import PortfolioService from '../../../api/service/user/PortfolioService';
import { PortfolioListData } from '../../../api/service/user/PortfolioService';
import Pagination from '../../../components/Pagination';

const MainPortfolioContainer = styled.div<{ $isMobile: boolean }>`
  background-color: #fff;
  position: relative;
  padding-bottom: 96px;
  margin: ${({ $isMobile }) => ($isMobile ? '0 16px' : '0 32px')};

  @media (max-width: 1024px) {
    padding-bottom: 40px;
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  margin: 32px 0;
  @media (max-width: 1024px) {
    margin: 10px 0;
  }
`;

const HeaderBackgroundImage = styled.img`
  position: relative;
  width: 100px;
  left: 50%;
  transform: translateX(-50%);
`;

const HeaderTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const HeaderTitle = styled.p`
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 85px; /* 177.083% */
  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 85px; /* 354.167% */
  }
`;

const HeaderSubtitle = styled.p`
  word-break: keep-all;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 1024px) {
    font-size: 16px;
    font-weight: 400;
  }
`;

const CustomDropdownContainer = styled.div`
  padding: 0px 15% 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    padding: 0px 5% 10px;
  }
`;

const CustomDropdownBox = styled.div`
  width: 300px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const PcProjectContainer = styled.div`
  padding: 0 15%;
  max-width: 1920px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  @media (max-width: 1440px) {
    padding: 0 10%;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1024px) {
    padding: 0 5%;
  }
  @media (max-width: 568px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

function MainPortfolio() {
  const isMobile = useIsMobile();
  const [categories, setCategories] = useState<
    { key: string; value: string }[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [portfolioData, setPortfolioData] = useState<PortfolioListData[]>([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const paginationElement = document.querySelector('.custom-pagination');
    if (!paginationElement) {
      console.warn('Pagination element not found.');
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchPortfolioData(currentPage);
  }, [selectedCategory, currentPage]);

  const fetchCategories = async () => {
    try {
      const response = await CategoryService.getRootCategories();
      if (response) {
        // 📌 API에서 받아온 카테고리 데이터 변환
        const formattedCategories = response.data.map(
          (category: CategoryData) => ({
            key: category.id.toString(),
            value: category.mainCategory,
          })
        );

        // 📌 "전체(ALL)" 옵션 추가
        const allOption = { key: '', value: '전체' };
        const updatedCategories = [allOption, ...formattedCategories];

        setCategories(updatedCategories);

        // 📌 기본 선택 값 "" (전체 선택)
        setSelectedCategory('');
      }
    } catch (error) {
      console.error('카테고리 로드 실패:', error);
    }
  };

  // ✅ 2️⃣ 포트폴리오 데이터 불러오기 (API 연동)
  const fetchPortfolioData = async (page: number) => {
    try {
      const response = await PortfolioService.getPortfolioList({
        page: page, // ✅ API는 0-based index 사용 (React에서는 1-based)
        size: 6,
        mainCategoryId: selectedCategory ? Number(selectedCategory) : undefined,
      });

      if (response) {
        setPortfolioData(response.data.content);
        setTotalPages(response.data.totalPages); // ✅ 전체 페이지 수 업데이트
      }
    } catch (error) {
      console.error('포트폴리오 리스트 조회 실패:', error);
    }
  };
  // ✅ 페이지 변경 핸들러
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <MainPortfolioContainer $isMobile={isMobile}>
      <HeaderContainer>
        <HeaderBackgroundImage
          src={portfolio_title_background}
          alt="Portfolio"
        />
        <HeaderTextContainer>
          <HeaderTitle>PORTFOLIO</HeaderTitle>
          <HeaderSubtitle>
            로동과 함께 한 다양한 프로젝트를 소개합니다
          </HeaderSubtitle>
        </HeaderTextContainer>
      </HeaderContainer>
      <CustomDropdownContainer>
        <CustomDropdownBox>
          <CustomDropdown
            options={categories}
            selected={selectedCategory}
            onSelect={(value: string) => setSelectedCategory(value)}
          />
        </CustomDropdownBox>
      </CustomDropdownContainer>
      {portfolioData.length === 0 && (
        // ✅ 데이터가 없을 때 표시할 메시지
        <p
          style={{
            textAlign: 'center',
            width: '100%',
            fontSize: '18px',
            color: '#888',
          }}
        >
          데이터가 없습니다.
        </p>
      )}
      <PcProjectContainer>
        {portfolioData.map((portfolio) => (
          <ProjectSummaryCard
            key={portfolio.idPortfolio}
            portfolio={portfolio}
          />
        ))}
      </PcProjectContainer>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </MainPortfolioContainer>
  );
}

export default MainPortfolio;
